import { Component, OnInit, Input, HostListener} from '@angular/core';
import { Router } from '@angular/router';
import { DineEngineCMSService } from 'app/providers';
import { LoaderService } from '../../../providers/loader.service';
import { environment } from 'environments/environment';

const BASE_URL = environment.imageUrl;

@Component({
    selector: 'app-main-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
  // encapsulation: ViewEncapsulation.Emulated
})

export class FooterComponent implements OnInit {
    @Input('fullFooter') fullFooter;
    punchhMobile = window.localStorage.getItem('punchhMobile')
    isTiny = window.innerWidth <= 0;
    show = true;
    iPhone6 = window.innerWidth <= 375 && window.innerWidth >= 360;
    nutrition;

    constructor(
      private router: Router,
      private loader: LoaderService,
      private cms: DineEngineCMSService
) { }

    ngOnInit() {
        this.loader.loaderState.subscribe((res) => {
            this.show = !res.show;
        });
        this.cms.getNutrition().subscribe(res => {
          this.nutrition = res.data.nutrition_facts.data.full_url;
        })
    }

    get showFullFooter() {
        const matched = this.router.url.replace(/^\/([^\/]*).*$/, '$1');
        return matched === 'brochure';
    }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isTiny = window.innerWidth < 0;
  }

  openNutrition() {
    window.open(this.nutrition, '_blank');
  }
}
