import {Observable, MonoTypeOperatorFunction, combineLatest, throwError as observableThrowError} from 'rxjs';

import {EventEmitter, Injectable} from '@angular/core';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Basket, BasketChoice, BasketProduct} from '../models/basket';
import {OloService} from 'app/providers/olo.service';
import {LoaderService} from 'app/providers/loader.service';
import {environment} from '../../environments/environment';
import {OloBasketTransferResponse} from '../models/olo.basket-transfer-response';
import {OloLoyaltyReward, OloLoyaltyRewardResponse} from '../models/olo.loyalty-reward.interface';
import {catchError, finalize, map, switchMap, tap} from 'rxjs/operators';
import {OloGroupOrderBase} from '../models/olo.group-order-base.interface';
import {BillingAccount, BillingSchemeResponse} from '../models/olo.billing-scheme.interface';
import {OloLoyaltySchemeResponse} from '../models/olo.loyalty-scheme.interface';
import {OloError} from '../models/olo.error.interface';
import {throwError} from 'rxjs/internal/observable/throwError';
import {Order} from '../models/olo.order';

export interface BasketCount {
  count: Number;
}

@Injectable()
export class BasketService extends OloService {
  storage = window.localStorage.getItem('keepSigned')
    ? window.localStorage
    : window.sessionStorage;

  shouldSetNew = true;
  private basket: Basket = null;
  basketCount: BasketCount = { count: 0 };
  public onSetBasket: EventEmitter<any> = new EventEmitter();
  public onSetGroup: EventEmitter<any> = new EventEmitter();
  public isLoggedIn;
  private timeWanted = '';
  public showWelcome = true;
  protected cmsUrl = environment.cmsUrl;
  public contact_info = {
    first_name: '',
    last_name: '',
    email: '',
    phone: ''
  };

  unavailableModifiers: string[] = [];

  private isInvitedUser = false;
  private inviteToken = '';
  public onSetInvitedUser: EventEmitter<any> = new EventEmitter();

  constructor(
    protected http: HttpClient,
    private loaderService: LoaderService,
    private router: Router
  ) {
    super(http)
  }

  getIsInvitedUser() {
    return this.isInvitedUser;
  }

  setIsInvitedUser(isInvited) {
    this.isInvitedUser = isInvited;
    this.onSetInvitedUser.emit(isInvited);
  }

  setContactInfo(first_name, last_name, email, phone) {
    this.contact_info.first_name = first_name;
    this.contact_info.last_name = last_name;
    this.contact_info.email = email;
    this.contact_info.phone = phone;
  }

  setBasket(basket: Basket, ultimate?) {
    this.basket = basket;
    sessionStorage.setItem('basket_id', basket.id);
    this.basketCount.count = this.getBasketCount();
    this.onSetBasket.emit(basket);
    return this.basket;
  }

  getCachedBasket(): Basket {
    return this.basket;
  }

  getBasketCount(): Number {
    let basketCount = 0;
    for (const product of this.basket.products) {
      basketCount += product.quantity;
    }
    return basketCount;
  }

  getTimeWanted() {
    return this.timeWanted;
  }

  checkCreateBasket(restaurantID: string, returnObserver = false) {
    if (sessionStorage.getItem('basket_id') || !this.shouldSetNew) {
    } else {
      if (restaurantID == null) {
        this.router.navigate(['./locations']);
      } else {
        if (returnObserver) {
          return this.createBasket(restaurantID);
        }
        this.createBasket(restaurantID).subscribe(data => {
          this.setBasket(data);
        });
      }
    }
  }

  userLoggedIn() {
    this.isLoggedIn = this.storage.getItem('auth') ? true : false;
    return this.isLoggedIn;
  }


  createBasket(restaurantID: string): Observable<Basket> {
    const token = this.storage.getItem('auth');
    const body = {
      vendorid: restaurantID, // TODO: not restaurantID?
      authtoken: token // TODO: Logged in user's auth token
    };
    if (restaurantID == null) {
      this.router.navigate(['./locations']);
    } else {
      return this.http.post(this.getCmsUrl() + '/api/baskets/create', body, { headers: this.getHeaders2() }).pipe<Basket>(tap((res: Basket) => {
        this.setBasket(res)
          sessionStorage.setItem('basket_id', res.id);
        }));
    }
  }

  getBasket(basket_id: string = sessionStorage.getItem('basket_id')) {
    if (!basket_id) {
      return observableThrowError('no basket id');
    }
    return this.http
      .get<Basket>(this.getCmsUrl() + '/api/baskets/' + basket_id, { headers: this.getHeaders2() }).pipe(tap(res => {
        this.setBasket(res);
      }), catchError((error) => {
        this.onSetBasket.emit(null);
        return observableThrowError(error.error);
      }))
  }

  clearBasket() {
    this.storage.removeItem('basket_id');
    this.clearGroupOrder();
    this.basketCount.count = 0;
    this.onSetBasket.emit(null);
  }

  clearGroupOrder() {
    this.storage.removeItem('group_id');
  }

  transferBasketAsPromise(vendorId): Promise<OloBasketTransferResponse> {
    this.shouldSetNew = false;
    setTimeout(() => {
      this.shouldSetNew = true;
    }, 10000);
    const body = { vendorid: vendorId };
    return this.http
      .post<OloBasketTransferResponse>(this.getCmsUrl() +
        '/api/baskets/' + sessionStorage.getItem('basket_id') + '/transfer',
        body,
        {
          headers: this.getHeaders2()
        }
      ).pipe(tap(res => {
        this.setBasket(res.basket);
        this.onSetBasket.emit(res.basket);
      }), catchError(err => {
        if (err.error.num === 211) {
          this.checkCreateBasket(vendorId)
        } else {
          return observableThrowError(err.error as OloError);
        }
      })).toPromise();
  }

  transferBasket(vendorId): Observable<OloBasketTransferResponse> {
    this.shouldSetNew = false;
    setTimeout(() => {
      this.shouldSetNew = true;
    }, 10000);
    const body = { vendorid: vendorId };
    return this.http
      .post<OloBasketTransferResponse>(this.getCmsUrl() +
        '/api/baskets/' + sessionStorage.getItem('basket_id') + '/transfer',
        body,
        {
          headers: this.getHeaders2()
        }
      ).pipe(tap(res => {
        this.setBasket(res.basket);
        this.onSetBasket.emit(res.basket)
      }), catchError((err: HttpErrorResponse) => {
        if (err.error.num === 211) {
          this.checkCreateBasket(vendorId)
        } else {
          return observableThrowError(err.error as OloError);
        }
      }))
  }

  addProductToBasket(
    productID: string,
    quantity: number = 1,
    options: string = '',
    specialinstructions: string,
    recipient: string = '',
    customdata: string = ''
  ): Observable<Basket> {
    const body = { productid: productID, quantity: quantity, options: options, specialinstructions: specialinstructions, recipient: recipient, customdata: customdata }; // comma separated list of optionIDs
    return this.http
      .post<Basket>(this.getCmsUrl() +
        '/api/baskets/' + sessionStorage.getItem('basket_id') + '/products',
        body,
        { headers: this.getHeaders2() }
      ).pipe(tap(res => {
        this.setBasket(res);
        this.onSetBasket.emit(res)
      }), catchError((err: HttpErrorResponse) => {
        return observableThrowError(err.error as OloError)
      }))
  }

  editProductInBasket(
    productID: string,
    basket_product_id: string,
    quantity: number = 1,
    options: string = '',
    specialinstructions: string,
    recipient: string = '',
    customdata: string = ''
  ) {
    const body = {
      productid: productID,
      quantity: quantity,
      options: options, // comma separated list of optionIDs
      specialinstructions: specialinstructions,
      recipient: recipient,
      customdata: customdata
    };
    return this.http
      .put<Basket>(this.getCmsUrl() +
        '/api/baskets/' +
        sessionStorage.getItem('basket_id') +
        '/products/' +
        basket_product_id,
        body,
        {headers: this.getHeaders2()}
      ).pipe(
        tap(res => {
          this.setBasket(<Basket>res);
          this.onSetBasket.emit(res);
        }),
        catchError((error: any) => observableThrowError(error.error as OloError)),);
  }

  // /baskets/{basketId}/products/{basketProductId} Remove Product from Basket
  removeProductFromBasket(basket_product_id: string): Observable<Basket> {
    const basket_id: string = sessionStorage.getItem('basket_id');
    return this.http.delete<Basket>(this.getCmsUrl() + `/api/baskets/${basket_id}/products/${basket_product_id}`, {
      headers: this.getHeaders2(),  observe: 'body', responseType: 'json'
    }).pipe(tap(res => {
            this.setBasket(<Basket>res);
            this.onSetBasket.emit(<Basket>res);
    }), catchError(err => throwError(err.error as OloError)))
    // return this.https
    //   .delete<Basket>(this.getCmsUrl() + `/api/baskets/${basket_id}/products/${basket_product_id}`, {
    //     headers: this.getHeaders(), observe: 'body', responseType: 'json'
    //   }).pipe(
    //     map(res => {
    //       this.setBasket(<Basket>res);
    //       this.onSetBasket.emit(<Basket>res);
    //       return res
    //     }),
    //     catchError((error: any) => observableThrowError(error.error as OloError)),);
  }

  // PUT /baskets/{basketId}/products/{basketProductId}
  updateProductInBasket(product) {
    const basket_id: string = sessionStorage.getItem('basket_id');
    const basket_product_id: string = product.id;

    // Convert array of BasketChoices into a comma delimited list of optionIds.
    const options = [];
    for (const choice of product.choices) {
      options.push(choice.optionid);
    }

    // convert product into NewProduct
    const body = {
      productid: product.productId,
      options: options.join(','),
      quantity: product.quantity,
      specialinstructions: product.specialinstructions,
      recipient: product.recipient
    };

    // TODO: add support for Array of choicecustomfields
    return this.http
      .put<Basket>(this.getCmsUrl() + `/api/baskets/${basket_id}/products/${basket_product_id}`, body, {
        headers: this.getHeaders2()
      }).pipe(
        map(res => {
          this.setBasket(<Basket>res);
          this.onSetBasket.emit(<Basket>res);

          return res;
        }),
        catchError((error: any) => observableThrowError(error.error as OloError)),);
  }

  addMultipleProductsToBasket(products: any): Observable<{basket: Basket, errors: any[]}> {
    const body = {
      products: products
    }; // comma separated list of optionIDs
    return this.http
      .post<{ basket: Basket, errors: any[] }>(this.getCmsUrl() +
        '/api/baskets/' + sessionStorage.getItem('basket_id') + '/products/batch',
        body,
        {headers: this.getHeaders2()}
      ).pipe(
        tap(res => {
          const basket = res.basket;
          this.setBasket(<Basket>basket);
          this.onSetBasket.emit(<Basket>basket);
        }));
  }

  // date:'YYYYMMDD hh:mm'
  setTimeWanted(date: string) {
    const basket_id: string = sessionStorage.getItem('basket_id');
    if (!date) {
      // no date means asap
      return this.http
        .delete<Basket>(this.getCmsUrl() + '/api/baskets/' + basket_id + '/timewanted', {
          headers: this.getHeaders2()
        }).pipe(
        tap(res => this.setBasket(<Basket>res)),
        catchError((error: any) => observableThrowError(error.error as OloError)),);
    }
    const mmt = moment(date, 'YYYYMMDD hh:mm');
    const body = {
      ismanualfire: false,
      year: mmt.format('YYYY'),
      month: mmt.format('MM'),
      day: mmt.format('DD'),
      hour: mmt.format('HH'),
      minute: mmt.format('mm')
    };

    return this.http
      .put<Basket>(this.getCmsUrl() + '/api/baskets/' + basket_id + '/timewanted', body, {
        headers: this.getHeaders2()
      }).pipe(
        tap(res => this.setBasket(<Basket>res)),
        catchError((error: any) => observableThrowError(error.error as OloError)),);
  }

  applyCoupon(couponcode: string): Observable<Basket> {
    const body = {
      couponcode: couponcode
    };
    const basket_id: string = sessionStorage.getItem('basket_id');
    const result = this.http
      .put<Basket>(this.getCmsUrl() + '/api/baskets/' + basket_id + '/coupon', body, {
        headers: this.getHeaders2()
      }).pipe(
      tap(res => this.setBasket(<Basket>res)),
      catchError((error: any) => observableThrowError(error.error as OloError)),);
    return result;
  }

  removeCoupon(): Observable<Basket> {
    const basket_id: string = sessionStorage.getItem('basket_id');
    return this.http
      .delete<Basket>(this.getCmsUrl() + '/api/baskets/' + basket_id + '/coupon', {
        headers: this.getHeaders2()
      }).pipe(
        tap(res => this.setBasket(<Basket>res)),
        catchError((error: any) => observableThrowError(error.error as OloError)),);
  }

  checkoutGuest(cardnumber: string, exp: string, cvv: string, zip: string): Observable<Order> {
    const exparr = exp.split('/');
    const month = parseInt(exparr[0]);
    let yearstr = exparr[1].trim();
    if (yearstr.length < 4) {
      yearstr = '20' + yearstr;
    }
    const year = parseInt(yearstr);
    cardnumber = cardnumber.replace(/ /g, '');
    const body = {
      billingmethod: 'creditcard',
      usertype: 'guest',
      firstname: this.contact_info.first_name,
      lastname: this.contact_info.last_name,
      emailaddress: this.contact_info.email,
      contactnumber: this.contact_info.phone,
      cardnumber: cardnumber,
      expiryyear: year,
      expirymonth: month,
      cvv: cvv,
      zip: zip
    };
    const basket_id: string = sessionStorage.getItem('basket_id');

    if (this.basket) {
      this.timeWanted = this.basket.timewanted || this.basket.earliestreadytime;
    }
    this.showLoader();
    return this.http
      .post<Order>(this.getCmsUrl() + '/api/baskets/' + basket_id + '/submit', body, {
        headers: this.getHeaders2()
      }).pipe(
        // tap(
        //   (res: any) => {
        //     return this.setBasket(<Basket>res);
        //   }
        // ),
        catchError(
          (error: any) => {
            return observableThrowError(error.error as OloError);
          }
        ),
        finalize(() => {
          this.onEnd();
        }),);
  }

  getOrder(order_id: string): Observable<Order> {
    return this.http
      .get<Order>(this.cmsUrl + '/api/orders/' + order_id, {headers: this.getHeaders2()}).pipe(
        tap(res => res),
        catchError((error: any) => observableThrowError(error.error)),);
  }

  setDeliveryAddress(
    streetaddress: string,
    building: string,
    city: string,
    zipcode: string,
    specialinstructions: string,
    phonenumber: string
  ): Observable<Basket> {
    const body = {
      building: building,
      city: city,
      isdefault: false,
      specialinstructions: specialinstructions,
      streetaddress: streetaddress,
      zipcode: zipcode,
    };
    return this.http
      .put<Basket>(this.getCmsUrl() +
        '/api/baskets/' +
        sessionStorage.getItem('basket_id') +
        '/dispatchaddress',
        body,
        {headers: this.getHeaders2()}
      ).pipe(
        tap(res => this.setBasket(<Basket>res)),
        catchError((error: any) => observableThrowError(error.error as OloError)),);
  }

  setDeliveryMode(data): Observable<Basket> {
    if (!this.canTransferModifiers(data)) { return throwError(this.unavailableModifiers) };
    const body = {
      deliverymode: data
    };
    return this.http
      .put<Basket>(this.getCmsUrl() +
        '/api/baskets/' + sessionStorage.getItem('basket_id') + '/deliverymode',
        body,
        {headers: this.getHeaders2()}
      ).pipe(
        map((res: Basket) => {
          this.setBasket(<Basket>res);
          return res
        }),
        catchError((error: any) => {
          return observableThrowError(error.error as OloError);
        }));
  }

  getGroupOrder(order_id: string, token: string): Observable<OloGroupOrderBase> {
    return this.http
      .get<OloGroupOrderBase>('/api/grouporders/' + order_id + '?authtoken=' + token, {
        headers: this.getHeaders2()
      }).pipe(
        tap(order => {
          this.setBasket(order.basket);
          this.onSetGroup.emit(order);
        }),
        catchError((error: any) => observableThrowError(error.error)),);
  }

  createGroupOrder(deadline: string, note: string): Observable<OloGroupOrderBase> {
    // NEED AUTH TOKEN OR GUEST AUTH TOKEN TO PROCEED
    const token = this.storage.getItem('auth');
    const body = {
      restaurantid: String(this.storage.getItem('restaurant_id')),
      deadline: deadline,
      note: note
    };
    return this.http
      .put<OloGroupOrderBase>('/api/grouporders?authtoken=' + token, body, {
        headers: this.getHeaders2()
      }).pipe(
        tap(order => {
          this.setBasket(order.basket);
          this.onSetGroup.emit(order);
        }),
        catchError((error: any) => observableThrowError(error)),);
  }

  updateGroupOrder(group_id: string, deadline: string, note: string): Observable<OloGroupOrderBase> {
    // NEED AUTH TOKEN OR GUEST AUTH TOKEN TO PROCEED
    const token = this.storage.getItem('auth');

    const body = {
      grouporderid: group_id,
      deadline: deadline,
      note: note
    };
    return this.http
      .post<OloGroupOrderBase>('/api/grouporders?authtoken=' + token, body, {
        headers: this.getHeaders2()
      }).pipe(
        tap(order => {
          this.setBasket(order.basket);
          this.onSetGroup.emit(order);
        }),
        catchError((error: any) => observableThrowError(error.error as OloError)),);
  }

  addCustomField(body): Observable<Basket>{
    return this.http
      .put<Basket>(this.getCmsUrl() + '/api/baskets/' + sessionStorage.getItem('basket_id') + '/customfields', body, {
        headers: this.getHeaders2()
      }).pipe(
        tap(res => this.setBasket(<Basket>res)),
        catchError((error: any) => observableThrowError(error.error as OloError)),);
  }

  sendEmails(data) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http
      .post('https://freebirds.dineengine.com/group.php', data, {
        headers: headers
      }).pipe(
        catchError((error: any) => observableThrowError(error)),);
  }

  sendGroupOrderInvite(group_id: string, addresses: string[]) {
    const token = this.storage.getItem('auth');
    const body = {
      emailaddresses: addresses
    };
    return this.http
      .post(
        '/api/grouporders/' + group_id + '/invite?authtoken=' + token,
        body,
        {headers: this.getHeaders2()}
      ).pipe(
        catchError((error: any) => observableThrowError(error.error as OloError)),);
  }

  addTip(amount: number): Observable<Basket> {
    const body = {
      amount: amount
    };
    return this.http
      .put<Basket>(this.getCmsUrl() + '/api/baskets/' + sessionStorage.getItem('basket_id') + '/tip', body, {
        headers: this.getHeaders2()
      }).pipe(
        map((res) => {
          this.setBasket(res);
          return res
        }),
        catchError((error: any) => {
          return observableThrowError('Issue setting tip');
        }),);
  }

  getBillingschemes(): Observable<BillingSchemeResponse> {
    return this.http
      .get<BillingSchemeResponse>(this.getCmsUrl() +
        '/api/baskets/' + sessionStorage.getItem('basket_id') + '/billingschemes',
        {headers: this.getHeaders2()}
      ).pipe(
        catchError((error: any) => observableThrowError(error.error as OloError)),);
  }

  getLoyaltyid(): Observable<OloLoyaltySchemeResponse>{
    const head = this.getHeaders2();
    const auth = this.storage.getItem('auth');
    head.append('checkbalance', 'false');
    head.append('checkrewards', 'false');
    return this.http
      .get<OloLoyaltySchemeResponse>(this.getCmsUrl() +
        '/api/baskets/' +
        sessionStorage.getItem('basket_id') +
        '/loyaltyschemes?authtoken=' +
        auth,
        {headers: head}
      ).pipe(
        catchError((error: any) => observableThrowError(error.error as OloError)),);
  }

  getLoyaltyrewards(): Observable<{ rewards: OloLoyaltyReward[] }> {
    const head = this.getHeaders2();
    const auth = this.storage.getItem('auth');
    const membershipId = this.storage.getItem('membershipId');
    return this.http
      .get<{ rewards: OloLoyaltyReward[] }>(this.getCmsUrl() +
        '/api/baskets/' +
        sessionStorage.getItem('basket_id') +
        '/loyaltyrewards/qualifying?authtoken=' +
        auth +
        '&membershipId=' +
        membershipId,
        {headers: head}
      ).pipe(
        catchError((error: any) => observableThrowError(error.error as OloError)),);
  }

  applyRewards(references: string) {
    const body = {
      membershipid: this.storage.getItem('membershipId'),
      references: [references]
    };
    return this.http
      .put<Basket>(this.getCmsUrl() +
        '/api/baskets/' +
        sessionStorage.getItem('basket_id') +
        '/loyaltyrewards/byref',
        body,
        {headers: this.getHeaders2()}
      ).pipe(
        tap(res => this.setBasket(<Basket>res)),
        catchError((error: any) => observableThrowError(error.error as OloError)),);
  }

  appliedRewards(): Observable<OloLoyaltyRewardResponse> {
    const membershipId = this.storage.getItem('membershipId');
    return this.http
      .get<OloLoyaltyRewardResponse>(this.getCmsUrl() +
        '/api/baskets/' +
        sessionStorage.getItem('basket_id') +
        '/loyaltyrewards?membershipId=' +
        membershipId,
        {headers: this.getHeaders2()}
      ).pipe(
        catchError((error: any) => observableThrowError(error.error as OloError)),);
  }

  deleteRewards(rewardid: string) {
    return this.http
      .delete<Basket>(this.getCmsUrl() +
        '/api/baskets/' +
        sessionStorage.getItem('basket_id') +
        '/loyaltyrewards/' +
        rewardid,
        {headers: this.getHeaders2()}
      ).pipe(
        tap(res => this.setBasket(<Basket>res)),
        catchError((error: any) => observableThrowError(error.error as OloError)),);
  }

  validate() {
    return this.http
      .post(this.getCmsUrl() +
        '/api/baskets/' +
        sessionStorage.getItem('basket_id') +
        '/validate?checkupsell=true',
        {},
        {headers: this.getHeaders2()}
      ).pipe(
        catchError((error: any) => observableThrowError(error.error as OloError)),);
  }

  getUserHistory(): Observable<{orders: Order[]}> {
    const head = this.getHeaders2();
    const auth = this.storage.getItem('auth');
    return this.http
      .get<{orders: Order[]}>(this.getCmsUrl() + '/api/users/' + auth + '/recentorders', {headers: head}).pipe(
        catchError((error: any) => observableThrowError(error.error as OloError)),);
  }

  getUserPaymentHistory(basketid: string = ''): Observable<{billingaccounts: BillingAccount[]}> {
    const head = this.getHeaders2();
    const auth = this.storage.getItem('auth');
    let basketParams = '';
    if (basketid !== '') {
      basketParams = '?basket=' + basketid;
    }
    return this.http
      .get<{billingaccounts: BillingAccount[]}>(this.getCmsUrl() + '/api/users/' + auth + '/billingaccounts' + basketParams, {headers: head}).pipe(
        catchError((error: any) => observableThrowError(error.error as OloError)),);
  }

  getUpsellsApiData() {
    const basketId = sessionStorage.getItem('basket_id');
    return this.http
      .get(this.getCmsUrl() + '/api/baskets/' + basketId + '/upsell', {
        headers: this.getHeaders2()
      }).pipe(
        catchError((error: any) => observableThrowError(error.error)),);
  }

  addUpsell(id: string): Observable<Basket> {
    const body = {
      items: [
        {
          id: id,
          quantity: 1
        }
      ]
    };
    const basketId = sessionStorage.getItem('basket_id');
    return this.http
      .post<Basket>(this.getCmsUrl() + '/api/baskets/' + basketId + '/upsell', body, {
        headers: this.getHeaders2()
      }).pipe(
        map(res => {
          this.setBasket(<Basket>res);
          this.onSetBasket.emit(<Basket>res);
          return res;
        }),
        catchError((error: any) => observableThrowError(error.error)),);
  }


  addArrayUpsell(items: any): Observable<Basket> {
    const body = {
      items
    };
    const basketId = sessionStorage.getItem('basket_id');
    return this.http
      .post<Basket>(this.getCmsUrl() + '/api/baskets/' + basketId + '/upsell', body, {
        headers: this.getHeaders2()
      }).pipe(
        map(res => {
          this.setBasket(<Basket>res);
          this.onSetBasket.emit(<Basket>res);
          return res;
        }),
        catchError((error: any) => {
            return observableThrowError(error._body);
          }
        ),);
  }

  createFromOrder(order): Observable<Basket> {
    const body = {
      orderref: order.orderref ? order.orderref : null,
      id: order.oloid ? order.oloid : null
    };
    return this.http
      .post<Basket>(this.getCmsUrl() + '/api/baskets/createfromorder', body, {
        headers: this.getHeaders2()
      }).pipe(
        map(res => {
          sessionStorage.setItem('basket_id', res.id);
          this.setBasket(res);
          this.onSetBasket.emit(res);
          return res;
        }));
  }

  checkoutUser(
    cardnumber: string,
    exp: string,
    cvv: string,
    zip: string,
    saveonfile: string
  ): Observable<Order> {
    const exparr = exp.split('/');
    const month = parseInt(exparr[0]);
    let yearstr = exparr[1].trim();
    if (yearstr.length < 4) {
      yearstr = '20' + yearstr;
    }
    const year = parseInt(yearstr);
    const auth = this.storage.getItem('auth');
    cardnumber = cardnumber.replace(/ /g, '');
    const body = {
      authtoken: auth,
      billingmethod: 'creditcard',
      usertype: 'user',
      firstname: this.contact_info.first_name,
      lastname: this.contact_info.last_name,
      emailaddress: this.contact_info.email,
      contactnumber: this.contact_info.phone,
      cardnumber: cardnumber,
      expiryyear: year,
      expirymonth: month,
      cvv: cvv,
      zip: zip,
      saveonfile: saveonfile
    };
    const basket_id: string = sessionStorage.getItem('basket_id');

    if (this.basket) {
      this.timeWanted = this.basket.timewanted || this.basket.earliestreadytime;
    }
    this.showLoader();
    return this.http
      .post<Order>(this.getCmsUrl() + '/api/baskets/' + basket_id + '/submit', body, {
        headers: this.getHeaders2()
      }).pipe(
        // tap(res => this.setBasket(<Basket>res)),
        catchError((error: any) => observableThrowError(error.error as OloError)),
        finalize(() => {
          this.onEnd();
        }),);
  }

  checkoutUserwithCard(billingaccountId: string): Observable<Order> {
    const auth = this.storage.getItem('auth');
    const body = {
      authtoken: auth,
      billingmethod: 'billingaccount',
      billingaccountid: billingaccountId,
      usertype: 'user',
      // "reference": "string",
      saveonfile: 'false'
      // "orderref": "string",
    };
    const basket_id: string = sessionStorage.getItem('basket_id');

    if (this.basket) {
      this.timeWanted = this.basket.timewanted || this.basket.earliestreadytime;
    }
    this.showLoader();
    return this.http
      .post<Order>(this.getCmsUrl() + '/api/baskets/' + basket_id + '/submit', body, {
        headers: this.getHeaders2()
      }).pipe(
        // tap(res => this.setBasket(<Basket>res)),
        catchError((error: any) => observableThrowError(error.error as OloError)),
        finalize(() => {
          this.onEnd();
        }),);
  }

  deleteSavedCard(billingaccountId: string) {
    const auth = this.storage.getItem('auth');
    this.showLoader();
    return this.http
      .delete(this.getCmsUrl() + '/api/users/' + auth + '/billingaccounts/' + billingaccountId, {
        headers: this.getHeaders2()
      }).pipe(
        catchError((error: any) => observableThrowError(error.error as OloError)),
        finalize(() => {
          this.onEnd();
        }),);
  }

  onEnd(): void {
    this.hideLoader();
  }

  showLoader(): void {
    this.loaderService.show();
  }

  hideLoader(): void {
    this.loaderService.hide();
  }

  getDeliveryAddresses() {
    const auth = this.storage.getItem('auth');
    return this.http.get(`${this.getCmsUrl()}/api/users/${auth}/userdeliveryaddresses`, {headers: this.getHeaders2()});

  }

  setDefaultDeliveryAddress(addressid: number) {
    const auth = this.storage.getItem('auth');
    const body = {addressid: addressid};
    return this.http.put(`${this.getCmsUrl()}/api/users/${auth}/userdeliveryaddresses/default`, body, {headers: this.getHeaders2()});
  }

  deleteDeliveryAddress(addressid: number) {
    const auth = this.storage.getItem('auth');
    return this.http.delete(`${this.getCmsUrl()}/api/users/${auth}/userdeliveryaddresses/${addressid}`, {headers: this.getHeaders2()});
  }

  canTransferModifiers(handoff: string): boolean {
    this.unavailableModifiers = [];
    let canTransfer = true;
    const items = JSON.parse(this.storage.getItem('unavailableItems'));
      if (items && items.length) {
        this.basket.products.forEach(prod => {
          prod.choices.forEach(choice => {
            if (items.find(item => item.id === choice.optionid)) {
              const item = items.find(item => item.id === choice.optionid);
              const disabledHandoffs = item.metadata.find(op => op.key === 'unavailable').value
              if (disabledHandoffs.includes(handoff)) {
                canTransfer = false;
                this.unavailableModifiers.push(item.name)
              }
            }
          })
        })
      }
    return canTransfer;
  }

  removeUnavailableModifiers(handoff: string) {
    return this.getBasket().pipe(switchMap(basket => {
      const items = JSON.parse(this.storage.getItem('unavailableItems'));
      const editedProducts = [];
      basket.products.forEach(prod => {
        prod.choices = prod.choices.filter(choice => {
          const matchingItem = items.find(item => item.id === choice.optionid)
          if (matchingItem) {
            const disabledHandoffs = matchingItem.metadata.find(op => op.key === 'unavailable').value || null;
            return !(disabledHandoffs && disabledHandoffs.includes(handoff));
          } else {
            return true
          }
        })
        editedProducts.push(prod)
      })
      const body = {
        products: editedProducts.map(prod => this.oloBasketProductToBatchProduct(prod))
      }
      return this.http.put(`${this.getCmsUrl()}/api/baskets/${basket.id}/products/batch`, body, {headers: this.getHeaders2()}).pipe(tap((res: any) => this.setBasket(res.basket)))
    }))
  }
  oloBasketChoiceToBatchProductChoice(choice: BasketChoice) {
    return {
      choiceid: choice.optionid,
      quantity: choice.quantity,
      customfields: choice.customfields
    }
  }

  oloBasketProductToBatchProduct(product: BasketProduct) {
    return {
      productid: product.id,
      quantity: product.quantity,
      specialinstructions: product.specialinstructions,
      recipient: product.recipient,
      customdata: product.customdata,
      choices: product.choices.map(choice => this.oloBasketChoiceToBatchProductChoice(choice))
    }
  }


}
