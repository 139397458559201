import { Injectable } from '@angular/core';

import { Upsell } from '../models/upsell';
import { BasketService } from './basket.service';
import { MenuService } from './menu.service';

@Injectable()
export class UpsellService {
  public completedUpsell = false;
  private upsells = {};

  constructor(
    private BasketService: BasketService,
    private MenuService: MenuService
  ) {}

  async getUpsells() {
    const response = await this.BasketService.getUpsellsApiData().toPromise();
    return response;
  }
}
