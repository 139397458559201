<div class="subcategories">
    <img src="/assets/img/Question Mark Group.svg" class="line-art" alt="Question Marks Background">
    <div class="container-fluid container-main-background">
        <div class="row back-btn"><div class="col">
            <span class="text-uppercase">
                <i class="fa fa-angle-left"></i>&nbsp;
            </span>
            <a class="b-crumb back-btn" [routerLink]="['/order']">categories</a>
        </div></div>
        <div class="categories" *ngFor="let cat of categories">
            <div class="selected-category" *ngIf="MenuService.toParam(cat.name) === MenuService.toParam(this.categoryID)">
                <div class="row title"><div class="col">
                    <h1 class="text-uppercase text-center">{{ cat.name }}</h1>
                </div></div>
                <div class="main-wrapper">
                    <div class="row align-items-center align-content-center m-auto"><div class="col m-auto">
                        <a class="btn btn-outline-primary btn-block freebirds-btn btn-freebirds" role="button" *ngFor="let prod of cat.products" href="#">
                            {{ prod.name }}
                        </a>
                    </div></div>
                </div>
            </div>
        </div>
    </div>
</div>
