<div class="carousel-wrap">
  <ng-container *ngIf="slides && slides.length">

    <ngb-carousel class="h-100 d-none d-md-block" #carousel
                  [showNavigationArrows]="slides.length > 1" [showNavigationIndicators]="slides.length > 1"
                  [interval]="carouselInterval">
      <ng-container *ngFor="let slide of slides, index as i">
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper h-100 w-100">
            <img [defaultImage]="slide.main_slide_image.data.thumbnails[6].url" [alt]="slide.slide_image_alt_text"
                 [lazyLoad]="slide.main_slide_image.data.full_url" class="responsive-img">
            <div *ngIf="slide.show_overlay" class="overlay h-100 w-100"></div>
          </div>
          <div class="carousel-caption h-100">
            <div>
              <div class="text-center content-section" [innerHTML]="slide.slide_content ? slide.slide_content : ''"></div>
              <div>
                <div class="d-flex flex-column justify-content-start align-items-center">
                  <button
                    *ngIf="slide.call_to_action_btn_label"
                    (click)="changeFlow(slide?.call_to_action_link)"
                    class="freebirds-btn slider" [innerText]="slide.call_to_action_btn_label"></button>
                  <button
                    *ngIf="slide.secondary_cta_btn_label"
                    (click)="changeFlow(slide?.secondary_cta_btn_link)"
                    class="freebirds-btn slider" [innerText]="slide.secondary_cta_btn_label"></button>
                </div>
              </div>
            </div>

          </div>
        </ng-template>
      </ng-container>
    </ngb-carousel>

    <div class="picsum-img-wrapper h-100 w-100 d-md-none">
      <img [defaultImage]="slides[0].main_slide_image.data.thumbnails[6].url" [alt]="slides[0].slide_image_alt_text"
           [lazyLoad]="slides[0].main_slide_image.data.full_url" class="responsive-img">
      <div *ngIf="slides[0].show_overlay" class="overlay h-100 w-100"></div>
    </div>
    <div class="carousel-caption h-100 d-md-none">
      <div>
        <div class="text-center content-section" [innerHTML]="slides[0].slide_content ? slides[0].slide_content : ''"></div>
        <div>
          <div class="d-flex flex-column justify-content-start align-items-center">
            <button
              *ngIf="slides[0].call_to_action_btn_label"
              (click)="changeFlow(slides[0]?.call_to_action_link)"
              class="freebirds-btn slider" [innerText]="slides[0].call_to_action_btn_label"></button>
            <button
              *ngIf="slides[0].secondary_cta_btn_label"
              (click)="changeFlow(slides[0]?.secondary_cta_btn_link)"
              class="freebirds-btn slider" [innerText]="slides[0].secondary_cta_btn_label"></button>
          </div>
        </div>
      </div>

    </div>
  </ng-container>
</div>
