import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError, finalize, map, switchMap, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import * as CryptoJS from 'crypto-js';
import {OloService} from 'app/providers/olo.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LoaderService} from 'app/providers/loader.service';
import {PunchUserInfo} from 'app/models/punchUserInfo.interface';
import {PunchLoyaltyResponse} from 'app/models/punchLoyaltyResponse.interface';
import {OloSSOResponse} from '../models/olo.sso-response.interface';
import {AnalyticsService} from './analytics.service';

@Injectable()
export class PunchService extends OloService {
  storage = window.localStorage.getItem('keepSigned')
    ? window.localStorage
    : window.sessionStorage;
  constructor(protected http: HttpClient, private loaderService: LoaderService, private analytics: AnalyticsService) {
    super(http);
  }

  protected getHeaders1() {
    let headers = new HttpHeaders();

    headers = headers.set('Accept', 'application/json')
    .set('Content-Type', 'application/json');
    return headers;
  }

  getLocalDigest(payload) {
    const secret = environment.punchSecret;
    const hash = CryptoJS.HmacSHA1(payload, secret);
    return new Observable(observer => {
      observer.next(hash);
      observer.complete();
    });
  }

  getDigest(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    payload = btoa(payload);
    return this.http.post('/punchhdigest', 'payload=' + payload, { headers: headers, responseType: 'text' }).pipe(
      map((res: any) => res));
  }

  signin(body): Observable<PunchUserInfo> {
    const uri_path = '/api/auth/customers/sign_in.json';
    const payload = uri_path.concat(body);
    let head = this.getHeaders1();
    this.showLoader();
    return this.getDigest(payload).pipe(switchMap((digest: any) => {
      head = head.set('x-pch-digest', digest)
      .set('Cache-control', 'no-cache')
      .set('Cache-control', 'no-store')
      .set('Expires', '0')
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('X-MicrosoftAjax', 'Delta=true')
      .set('Pragma', 'no-cache')
      return this.http
        .post<PunchUserInfo>('/punchh/customers/sign_in.json', body, { headers: head }).pipe(
        catchError((error: any) => {
          return observableThrowError(error);
        }),
        finalize(() => {
          this.onEnd();
        }),);
    }));
  }

  signup(data): Observable<PunchUserInfo> {
    const uri_path = '/api/auth/customers.json';
    const payload = uri_path.concat(data);
    this.showLoader();
    let head = this.getHeaders1();
    return this.getDigest(payload).pipe(switchMap((hash: any) => {
      head = head.set('x-pch-digest', hash)
      .set('Cache-control', 'no-cache')
      .set('Cache-control', 'no-store')
      .set('Expires', '0')
      .set('Pragma', 'no-cache')
      return this.http
        .post<PunchUserInfo>('/punchh/customers.json', data, { headers: head }).pipe(
        catchError((error: any) => observableThrowError(error.errors)),
        finalize(() => {
          this.onEnd();
        }),);
    }));
  }

  createAccessToken(security_token) {
    const data = {
      security_token: security_token,
      client: environment.punchClientId
    };
    const uri_path = '/api/auth/sso';
    const payload = uri_path.concat(JSON.stringify(data));
    this.showLoader();
    return this.getDigest(payload).pipe(switchMap((digest: any) => {
      let head = this.getHeaders1();
      head = head.set('x-pch-digest', digest);

      return this.http
        .post('/punchh/sso', JSON.stringify(data), {
          headers: head
        }).pipe(
        catchError((error: any) => observableThrowError(error)),
        finalize(() => {
          this.onEnd();
        }),);
    }));
  }

  getOloToken(data): Observable<OloSSOResponse> {
    return this.http
      .post<OloSSOResponse>(`${environment.cmsUrl}/api/users/getorcreate`, data, { headers: this.getHeaders2() }).pipe(
      catchError((error: any) => observableThrowError(error)),);
  }

  forgotPassword(data) {
    const uri_path = '/api/auth/users/forgot_password';
    const payload = uri_path.concat(data);
    let head = this.getHeaders1();
    return this.getDigest(payload).pipe(switchMap((hash: any) => {
      head = head.set('x-pch-digest', hash);
      return this.http
        .post('/punchh/users/forgot_password', data, { headers: head }).pipe(
        catchError((error: any) => {
          return observableThrowError(error);
        }),);
    }));
  }

  UpdatePassword(data) {
    const uri_path = '/api/auth/users/change_password';
    const payload = uri_path.concat(data);

    return this.getDigest(payload).pipe(switchMap((hash: any) => {
      let head = this.getHeaders1();
      head = head.set('x-pch-digest', hash);

      return this.http
        .patch('/punchh/users/change_password', data, { headers: head }).pipe(
        catchError((error: any) => observableThrowError(error)),);
    }));
  }

  getUserInfo(): Observable<PunchUserInfo> {
    const auth1 = this.storage.getItem('PunchAuthToken');
    const client1 = environment.punchClientId;
    const uri_path = '/api/auth/users?authentication_token=' + auth1 + '&client=' + client1;
    return this.getDigest(uri_path).pipe(switchMap((hash: any) => {
      let head = this.getHeaders1();
      head = head.set('x-pch-digest', hash)
      .set('Accept-Language', 'en');

      return this.http
        .get<PunchUserInfo>(
          '/punchh/users?authentication_token=' + auth1 + '&client=' + client1,
          { headers: head }
        ).pipe(tap((res) => this.analytics.logIdentifyOnLogin(res)),
        catchError((error: any) => observableThrowError(error)),);
    }));
  }

  updateUserInfo(data) {
    const uri_path = '/api/auth/users';
    const payload = uri_path.concat(data);

    return this.getDigest(payload).pipe(switchMap((hash: any) => {
      let head = this.getHeaders1();
      head = head.set('x-pch-digest', hash);

      return this.http
        .put('/punchh/users', data, { headers: head }).pipe(
        catchError((error: any) => observableThrowError(error)),);
    }));
  }

  getAccountBalance(): Observable<PunchLoyaltyResponse> {
    const auth1 = this.storage.getItem('PunchAuthToken');
    const client1 = environment.punchClientId;
    const uri_path =
      '/api/auth/checkins/balance?authentication_token=' +
      auth1 +
      '&client=' +
      client1;
    const payload = uri_path;

    return this.getDigest(payload).pipe(switchMap((hash: any) => {
      let head = this.getHeaders1();
      head = head.set('x-pch-digest', hash);

      return this.http
        .get<PunchLoyaltyResponse>(
          '/punchh/checkins/balance?authentication_token=' +
          auth1 +
          '&client=' +
          client1,
          { headers: head }
        ).pipe(
        catchError((error: any) => observableThrowError(error)),);
    }));
  }

  sendToRestApiMethod(token) {
    return this.http
      .get('https://graph.facebook.com/me?access_token=' + token, {
        headers: this.getHeaders2()
      }).pipe(
      catchError((error: any) => observableThrowError(error)),);
  }

  facebookSignin(data): Observable<PunchUserInfo> {
    const uri_path = '/api/auth/users/connect_with_facebook';
    const payload = uri_path.concat(data);
    this.showLoader();
    return this.getDigest(payload).pipe(switchMap((hash: any) => {
      let head = this.getHeaders1();
      head = head.set('x-pch-digest', hash);

      return this.http
        .post<PunchUserInfo>('/punchh/users/connect_with_facebook', data, { headers: head }).pipe(
        catchError((error: any) => observableThrowError(error)),
        finalize(() => {
          this.onEnd();
        }),);
    }));
  }

  onEnd(): void {
    this.hideLoader();
  }

  showLoader(): void {
    this.loaderService.show();
  }

  hideLoader(): void {
    this.loaderService.hide();
  }
}
