import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { RestaurantService } from 'app/providers/restaurant.service';
import { BasketService} from '../../../providers';
import {OrderMethodService} from 'app/providers/order-method.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import {ToastService} from '../../../providers/toast.service';

@Component({
  selector: 'app-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.scss']
})
export class LocationDetailsComponent implements OnInit {
  storage = window.localStorage.getItem('keepSigned')
    ? window.localStorage
    : window.sessionStorage;
  showBackButton: boolean;
  backRoute: string;
  location;
  changeMode = '';
  changeModeTo = '';
  locloc;
  basket;
  auth;

  isMobile = false;

  @Input() display_last_order = false;
  @Input() isModal = false;
  @Output() closeModal = new EventEmitter();
  screenWidth = window.innerWidth;

  constructor(
    private restaurantService: RestaurantService,
    protected basketService: BasketService,
    private router: Router,
    private orderMethod: OrderMethodService,
    private toast: ToastService
  ) {
    this.showBackButton = this.router.url !== '/order';
    this.backRoute = this.router.url === '/order/review' ? '/order/details' : '/order';
  }

  ngOnInit() {
    this.auth = this.storage.getItem('auth');
    this.location = JSON.parse(this.storage.getItem('restaurant'));
    this.changeModeTo = (this.changeMode === 'delivery' ? 'pickup' : 'delivery');
    this.basketService.onSetBasket.subscribe((res: any) => {
      this.basket = res;
      this.location = JSON.parse(this.storage.getItem('restaurant'));
    });
    if (!this.display_last_order) {
      if (sessionStorage.getItem('basket_id')) {
        this.basketService.getBasket().subscribe((res: any) => {
          this.basket = res;
        });
      }
    } else {
      this.basket = JSON.parse(this.storage.getItem('lastorder'));
    }
  }

  toggleMobileView() {
    this.isMobile = !this.isMobile
  }

  friendlyDateTime(datetime) {
    return moment(datetime, 'YYYYMMDD hh:mm').format('hh:mm A, MM/DD/YY');
  }

  changeLocationClicked() {
    this.closeModal.emit();
  }

  addLocationToFavorites() {
    this.restaurantService.addFavoriteLocation(this.location.id, this.auth).subscribe(( res: any) => {
      if (res && res.ok) {
        const response = JSON.parse(res._body);
        this.toast.success('The ' + response.vendorname + ' has been added to your favorites')
        return;
      }
      this.toast.warning('There was a issue saving the current location to your favorites')
    });
    return;
  }

  changeFlow(data) {
    if (data === 'dispatch') {
      this.router.navigate(['/locations']);
    } else {
      this.orderMethod.openDelivery();
    }
    this.closeModal.emit();
  }

  changeTime(changeMode: string) {
    this.orderMethod.openTimeSelectionModal(JSON.parse(this.storage.getItem('restaurant')));
    this.closeModal.emit();
  }

}
