import {Injectable, TemplateRef} from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: any[] = [];

  /**
   * Show standard toast message
   */

  standard(textOrTpl: string | TemplateRef<any>) {
    this.show(textOrTpl);
  }

  success(textOrTpl: string | TemplateRef<any>) {
    this.show(textOrTpl, {classname: 'bg-success text-light'});
  }

  info(textOrTpl: string | TemplateRef<any>) {
    this.show(textOrTpl, {classname: 'bg-info text-light'});
  }

  warning(textOrTpl: string | TemplateRef<any>) {
    this.show(textOrTpl, {classname: 'bg-warning'});
  }

  danger(textOrTpl: string | TemplateRef<any>) {
    this.show(textOrTpl, {classname: 'bg-danger text-light'});
  }

  /**
   * @deprecated DO NOT USE
   * @param toast
   */

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  private show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({textOrTpl, ...options});
  }
}
