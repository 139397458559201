import { Injectable } from '@angular/core';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Category, Option, Product, RestaurantMenu} from '../models/olo.menu.interface';
import {GTMImpression, GTMItem, GTMProduct, GTMPromotion} from '../models/gtm.event';
import {Basket, BasketProduct} from '../models/basket';
import {OloLoyaltyReward} from '../models/olo.loyalty-reward.interface';
import {PunchUserInfo} from '../models/punchUserInfo.interface';
import {SegmentService} from 'ngx-segment-analytics';
import {SegmentIdentify} from '../models/segment.identify';
import {Restaurants} from '../models/restaurants';
import {SegmentCheckoutStarted} from '../models/segment.checkout-started';
import {SegmentEvents} from '../models/segment.events';
import {SegmentContactUs} from '../models/segment.contact-us';
import {SegmentCouponApplied} from '../models/segment.coupon-applied';
import {SegmentOrderCompleted, SegmentProduct} from '../models/segment.order-completed';
import {Order} from '../models/olo.order';
import {SegmentPaymentInfoEntered} from '../models/segment.payment-info-entered';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private previousURL = '';
  private currentURL = '';
  private timers: number[] = [];
  private useUA = true; // Switch this to false to use GA4 events instead

  constructor(
    private gtm: GoogleTagManagerService,
    private router: Router,
    private segment: SegmentService,
  ) {
    this.router.events.pipe(filter(e => (e instanceof NavigationEnd || e instanceof NavigationStart))).subscribe((event: NavigationEnd | NavigationStart) => {
      if (event instanceof NavigationStart) {
        this.timers.push(Date.now())
      } else {
        const start: number = this.timers.pop()
        const delta = (Date.now() - start) / 1000
        this.previousURL = this.currentURL
        this.currentURL = event.url
        this.logNavigationTime(this.currentURL, this.previousURL, delta)
      }
    })
  }

  logIdentifyOnLogin(user: PunchUserInfo): void {
    const traits: SegmentIdentify = {
      birthday: user.birthday,
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      name: user.first_name.concat(' ').concat(user.last_name),
      phone: user.phone ? '1'.concat(user.phone.replace(/[^0-9a-z]/gi, '')) : null,
      freebirds_customer_id: String(user.id),
      gender: this.punchhGenderToSegmentGender(user.gender),
    }
    // this.segment.identify(null, traits, {
    //   subscriptionStatus: 'inactive'
    // })
  }

  logContactUs(category: string, restaurant?: Restaurants) {
    const properties: SegmentContactUs = {
      category,
      venue_id: restaurant ? restaurant.extref : null,
      venue_name: restaurant ? restaurant.name : null
    }
    // this.segment.track(SegmentEvents.CONTACT_US, properties)
  }

  logNavigationTime(currentURL: string, previousURL: string, delta: number): void {
    this.gtm.pushTag({
      event: this.useUA ? 'pageNavigation' : 'page_navigation',
      previousURL,
      currentURL,
      time: delta
    })
  }

  logProductListView(products: Category[]): void {
    this.resetEcommerce()
    if (this.useUA) {
      this.gtm.pushTag({
        event: 'viewItemList',
        ecommerce: {
          currencyCode: 'USD',
          impressions: products.map((cat, i) => this.oloCategoryToGTMImpression(cat, i))
        }
      })
    } else {
      this.gtm.pushTag({
        event: 'view_item_list',
        ecommerce: {
          items: products.map((cat, index) => this.oloCategoryToGTMItem(cat, index))
        }
      })
    }

  }

  logProductClick(product: Category): void {
    this.resetEcommerce()
    if (this.useUA) {
      this.gtm.pushTag({
        event: 'productClick',
        ecommerce: {
          click: { list: 'Menu' },
          products: [this.oloCategoryToGTMImpression(product, 0)]
        }
      })
    } else {
      this.gtm.pushTag({
        event: 'select_item',
        ecommerce: {
          items: [this.oloCategoryToGTMItem(product, 0)]
        }
      })
    }

  }

  logProductDetailView(product: Product, category: Category ): void {
    this.resetEcommerce()
    if (this.useUA) {
      this.gtm.pushTag({
        event: 'viewItem',
        ecommerce: {
          detail: {
            actionField: { list: category.name},
            products: [this.oloMenuProductToGTMProduct(product, category, false)]
          }
        }
      })
    } else {
      this.gtm.pushTag({
        event: 'view_item',
        ecommerce: {
          items: [this.oloMenuProductToGTMItem(product, category)]
        }
      })
    }

  }

  logAddToCart(products: BasketProduct[], category: Category): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.gtm.pushTag({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'USD',
          add: {
            products: products.map(product => this.oloBasketProductToGTMProduct(product, category, false))
          }
        }
      })
    } else {
      this.gtm.pushTag({
        event: 'add_to_cart',
        ecommerce: {
          items: products.map(product => this.oloBasketProductToGTMItem(product, category))
        }
      })
    }

  }

  logRemoveFromCart(product: BasketProduct, menu: Category[]): void {

    this.resetEcommerce();
    if (this.useUA) {
      this.gtm.pushTag({
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            products: [this.oloBasketProductToGTMProduct(product, menu.find(cat => cat.products.find(prod => product.productId === prod.id)), false)]
          }
        }
      })
    } else {
      this.gtm.pushTag({
        event: 'remove_from_cart',
        ecommerce: {
          items: [this.oloRemoveBasketProductToGTMItem(product)]
        }
      })
    }

  }

  logPromotionListView(rewards: OloLoyaltyReward[]): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.gtm.pushTag({
        event: 'viewPromotion',
        ecommerce: {
          promoView: {
            promotions: rewards.map((reward, index) => this.oloRewardToGTMPromotion(reward, index))
          }
        }
      })
    } else {
      this.gtm.pushTag({
        event: 'view_promotion',
        ecommerce: {
          items: rewards.map((reward, index) => this.oloRewardToGTMItem(reward, index))
        }
      })
    }
  }

  logPromotionClick(reward: OloLoyaltyReward, index: number): void {
    this.resetEcommerce();
    const properties: SegmentCouponApplied = {
      promo_codes: reward.label
    }
    // this.segment.track(SegmentEvents.COUPON_APPLIED, properties)
    if (this.useUA) {
      this.gtm.pushTag({
        event: 'promotionClick',
        ecommerce: {
          promoClick: {
            promotions: [this.oloRewardToGTMPromotion(reward, index)]
          }
        }
      })
    } else {
      this.gtm.pushTag({
        event: 'select_promotion',
        ecommerce: {
          items: [this.oloRewardToGTMItem(reward, index)]
        }
      })
    }
  }

  logCheckoutDetail(products: BasketProduct[], menu: Category[], restaurant: Restaurants): void {
    const properties: SegmentCheckoutStarted = {
      venue_id: restaurant.extref,
      venue_name: restaurant.name
    }
    // this.segment.track(SegmentEvents.CHECKOUT_STARTED, properties)
    this.resetEcommerce();
    if (this.useUA) {
      this.gtm.pushTag({
        event: 'checkoutDetails',
        ecommerce: {
          checkout: {
            actionField: {step: 1, option: 'Checkout Details'},
            products: products.map(prod => this.oloBasketProductToGTMProduct(prod, menu.find(cat => cat.products.find(product => prod.productId === product.id)), false))
          }
        }
      })
    } else {
      this.gtm.pushTag({
        event: 'begin_checkout',
        ecommerce: {
          items: products.map(prod => this.oloRemoveBasketProductToGTMItem(prod))
        }
      })
    }
  }

  logCheckoutReview(products: BasketProduct[], menu: Category[], restaurant: Restaurants, basketID: string): void {
    const properties: SegmentPaymentInfoEntered = {
      checkout_id: basketID,
      step: 1,
      venue_id: restaurant.extref,
      venue_name: restaurant.name
    }
    // this.segment.track(SegmentEvents.PAYMENT_INFO_ENTERED, properties)
    this.resetEcommerce();
    if (this.useUA) {
      this.gtm.pushTag({
        event: 'checkoutReview',
        ecommerce: {
          checkout: {
            actionField: {step: 2, option: 'Checkout Review'},
            products: products.map(prod => this.oloBasketProductToGTMProduct(prod, menu.find(cat => cat.products.find(product => prod.productId === product.id)), false))
          }
        }
      })
    }
  }

  logPurchase(basket: Basket, menu: Category[], order: Order): void {
    const properties: SegmentOrderCompleted = {
      checkout_id: basket.id,
      currency: 'USD',
      order_id: order.oloid,
      order_revenue: basket.subtotal,
      order_total: basket.total,
      products: basket.products.map(prod => this.oloBasketProductToSegmentProduct(prod, menu.find(cat => cat.products.find(product => prod.productId === product.id)))),
      promo_codes: basket.appliedrewards && basket.appliedrewards[0] ? basket.appliedrewards[0].label : null,
      venue_id: order.vendorextref,
      venue_name: order.vendorname
    }
    // this.segment.track(SegmentEvents.ORDER_COMPLETED, properties)
    this.resetEcommerce();
    if (this.useUA) {
      this.gtm.pushTag({
        event: 'purchase',
        ecommerce: {
          purchase: {
            actionField: {
              id: order.oloid,
              affiliation: localStorage.getItem('punchhMobile') === 'true' ? 'Mobile App' : 'Online Ordering',
              revenue: basket.total,
              tax: basket.salestax,
              shipping: basket.customerhandoffcharge,
              coupon: basket.coupon ? basket.coupon.couponcode : ''
            },
            products: basket.products.map(prod => this.oloBasketProductToGTMProduct(prod, menu.find(cat => cat.products.find(product => prod.productId === product.id)), false))
          }
        }
      })
    } else {
      this.gtm.pushTag({
        event: 'purchase',
        ecommerce: {
          transaction_id: basket.id,
          affiliation: localStorage.getItem('punchhMobile') === 'true' ? 'Mobile App' : 'Online Ordering',
          value: basket.total,
          tax: basket.salestax,
          shipping: basket.customerhandoffcharge,
          coupon: basket.coupon ? basket.coupon.couponcode : '',
          currency: 'USD',
          items: basket.products.map(prod => this.oloRemoveBasketProductToGTMItem(prod))
        }
      })
    }
  }

  logSiteAbandoned(): void {
    this.gtm.pushTag({
      event: this.useUA ? 'siteAbandoned' : 'site_abandoned',
      currentURL: this.currentURL
    })
  }

  private resetEcommerce(): void {
    this.gtm.pushTag({ecommerce: null})
  }


  // GOOGLE ANALYTICS 4

  private oloCategoryToGTMItem(category: Category, index: number): GTMItem {
    return ({
      item_name: category.name,
      item_id: String(category.id),
      index: index,
      quantity: 1
    } as GTMItem)
  }



  private oloMenuProductToGTMItem(product: Product, category: Category): GTMItem {
    return ({
      item_name: `${category.name} - ${product.name}`,
      item_id: String(product.chainproductid),
      item_category: category.name,
      item_variant: product.name,
      quantity: 1
    } as GTMItem)
  }

  private oloMenuProductToGTMItemWithPrice(product: Product, category: Category, price: number): GTMItem {
    return ({
      item_name: `${category.name} - ${product.name}`,
      item_id: String(product.chainproductid),
      item_category: category.name,
      item_variant: product.name,
      price,
      quantity: 1
    } as GTMItem)
  }

  private oloMenuOptionToGTMItem(option: Option): GTMItem {
    return ({
      item_name: option.name,
      item_id: String(option.chainoptionid)
    } as GTMItem)
  }

  private oloBasketProductToGTMItem(product: BasketProduct, category: Category): GTMItem {
    return ({
      item_name: `${category.name} - ${product.name}`,
      item_id: String(product.productId),
      item_category: category.name,
      item_variant: product.name,
      price: product.totalcost,
      quantity: product.quantity
    } as GTMItem)
  }

  private oloRemoveBasketProductToGTMItem(product: BasketProduct): GTMItem {
    return ({
      item_name: product.name,
      item_id: String(product.productId),
      item_variant: product.choices[0].name ? product.choices[0].name : '',
      price: product.totalcost,
      quantity: product.quantity
    } as GTMItem)
  }

  private oloRewardToGTMItem(reward: OloLoyaltyReward, index: number): GTMItem {
    return ({
      item_name: reward.label,
      item_id: reward.reference,
      promotion_name: reward.label,
      promotion_id: reward.externalreference,
      creative_name: reward.label,
      creative_slot: String(index),
      location_id: 'checkout',
      index: index,
      quantity: reward.quantityavailable

    } as GTMItem)
  }

  // UNIVERSAL ANALYTICS

  private oloCategoryToGTMImpression(category: Category, index: number): GTMImpression {
    return ({
      id: String(category.id),
      name: category.name
    } as GTMImpression)
  }

  private oloMenuProductToGTMProduct(product: Product, category: Category, useCategory: boolean): GTMProduct {
    return ({
      id: String(category.id),
      name: `${category.name} - ${product.name}`,
      variant: product.name,
      position: category.products.indexOf(product)
    } as GTMProduct)
  }

  private oloMenuProductToGTMProductWithPrice(product: Product, category: Category, price: number, useCategory: boolean): GTMProduct {
    return ({
      id: String(product.id),
      name: `${category.name} - ${product.name}`,
      variant: product.name,
      price,
      position: category.products.indexOf(product)
    } as GTMProduct)
  }

  private oloBasketProductToGTMProduct(product: BasketProduct, category: Category, useCategory: boolean): GTMProduct {
    return ({
      id: String(product.productId),
      name: `${category.name} - ${product.name}`,
      variant: product.name,
      price: product.totalcost,
      quantity: product.quantity
    } as GTMProduct)
  }

  private oloRemoveBasketProductToGTMProduct(product: BasketProduct): GTMProduct {
    return ({
      id: String(product.productId),
      name: product.name,
      variant: product.choices[0].name ? product.choices[0].name : '',
      price: product.totalcost,
      quantity: product.quantity
    } as GTMProduct)
  }

  private oloRewardToGTMPromotion(reward: OloLoyaltyReward, index: number): GTMPromotion {
    return ({
      id: String(reward.externalreference),
      name: reward.label,
      position: 'checkout_'.concat(String(index))
    } as GTMPromotion)
  }

  private useCategory(category: Category): boolean {
    return category.name.toUpperCase() !== 'Sides, Drinks and Desserts'.toUpperCase();
  }

  // SEGMENT

  private oloBasketProductToSegmentProduct(product: BasketProduct, category: Category): SegmentProduct {
    return {
      brand: 'Freebirds',
      product_category: 'Food & Drink',
      product_id: String(product.productId),
      product_name: `${category.name} - ${product.name}`,
      product_subcategory: product.choices[0] ? product.choices[0].name : null,
      product_unit_value: product.totalcost,
      quantity: product.quantity
    }
  }

  private punchhGenderToSegmentGender(gender: string): string {
    switch (gender) {
      case 'Male':
        return 'm';
      case 'Female':
        return 'f';
      default:
        return null
    }
  }

}
