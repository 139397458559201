import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'handoffMode'
})
export class HandoffModePipe implements PipeTransform {

  transform(handoffType: string): string {
    switch (handoffType) {
      case 'dispatch':
        return 'delivery';
      case 'drivethru':
        return 'mobile pickup lane';
      case 'curbside':
        return 'curbside pickup';
      default:
        return handoffType;
    }
  }

}
