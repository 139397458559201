import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';


import { MenuService } from 'app/providers/menu.service';
import { BasketService } from 'app/providers/basket.service';

@Component({
  selector: 'app-subcategories',
  templateUrl: './subcategories.component.html',
  styleUrls: ['./subcategories.component.scss']
})
export class SubcategoriesComponent implements OnInit {
  restaurantID;
  categoryID;
  categories;

  storage = window.localStorage.getItem('keepSigned')
    ? window.localStorage
    : window.sessionStorage;
  constructor(
    private route: ActivatedRoute,
    public MenuService: MenuService,
    private BasketService: BasketService,
    private router: Router
  ) {}

  ngOnInit() {
    this.restaurantID = this.storage.getItem('restaurant_id');
    if (this.restaurantID == null) {
      this.router.navigate(['./locations']);
    }
    this.BasketService.checkCreateBasket(this.restaurantID);
    return this.route.params.subscribe(params => {
      this.categoryID = params['categoryID'];
      this.getMenu();
    });
  }

  getMenu() {
    this.MenuService.getMenuForRestaurant(this.restaurantID).subscribe(data => {
      this.categories = data;
    });
  }
}
