import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {AlertModule} from 'ngx-bootstrap/alert';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {PopoverModule} from 'ngx-bootstrap/popover';
// import {AlertModule, BsDropdownModule, CollapseModule, PopoverModule} from 'ngx-bootstrap';
// import {getAuthServiceConfigs} from './socialloginConfig';
import {CarouselModule} from 'ngx-bootstrap/carousel';

import {MainPipe} from './pipe/main.pipe';
import {Safe} from './pipe/safe.pipe';

import {CreditCardDirectivesModule} from './components/angular-cc-library';

import {RestaurantService} from './providers';
import {MenuService} from './providers';
import {OrderMethodService} from './providers/order-method.service';
// import { MetaService } from './providers/meta.service';
import {BasketService} from './providers/basket.service';
import {UpsellService} from './providers/upsell.service';
import {PunchService} from './providers/punch.service';
import {DineEngineCMSService} from './providers/dine-engine-cms.service';
import {RecaptchaService} from './providers/recaptcha.service';
import {LoaderService} from './providers/loader.service';

import {CareersComponent} from './pages/careers/careers.component';
import {HeaderComponent} from './pages/main/header/header.component';
import {FooterComponent} from './pages/main/footer/footer.component';
import {NewNewsComponent} from './pages/new-news/new-news.component';
import {PostDetailComponent} from './pages/new-news/post-detail/post-detail.component';
import {BasketComponent} from './pages/main/basket/basket.component';
import {OrderOnlineComponent} from './pages/order-online/order-online.component';
import {NotifyComponent} from './pages/main/notify/notify.component';
import {LoyaltyComponent} from './pages/brochure/loyalty/loyalty.component';
import {OrderTypeModalComponent} from './pages/modals/order-type-modal/order-type-modal.component';
import {DeliveryModalComponent} from './pages/modals/delivery-modal/delivery-modal.component';
import {TimeSelectionModalComponent} from './pages/modals/time-selection-modal/time-selection-modal.component';
import {SignInModalComponent} from './pages/modals/sign-in-modal/sign-in-modal.component';
import {ForgotPasswordModalComponent} from './pages/modals/forgot-password-modal/forgot-password-modal.component';
import {ReorderModalComponent} from './pages/modals/reorder-modal/reorder-modal.component';
import {LoaderComponent} from './components/loader/loader.component';
// import { SignInComponent } from './pages/account/sign-in/sign-in.component';
import {AppRoutingModule} from './app.routes';
import {AppComponent} from './app.component';
import {RatingComponent} from './pages/main/rating/rating.component';
import {RatingService} from './providers/rating.service';
import {ContrastService} from './providers/contrast.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DateSelectComponent} from './pages/main/header/date-select/date-select.component';
import {SigninComponent} from './pages/main/header/signin/signin.component';
import {DeliveryPickupComponent} from './pages/main/header/delivery-pickup/delivery-pickup.component';
import {ContainedPhotoDirective} from './directives/contained-photo.directive';
import {TwilioService} from './providers/twilio.service';
import {HttpClientModule} from '@angular/common/http';
import {OrderModule} from './pages/order/order.module';
import {NgpSortModule} from 'ngp-sort-pipe';
import {NgxMaskModule} from 'ngx-mask';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {MetaDataService} from './providers/meta-data.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {NgbCarouselModule, NgbToastModule} from '@ng-bootstrap/ng-bootstrap';
import {getAuthServiceConfigs} from './socialloginConfig';
import {SocialLoginModule} from 'angularx-social-login';
import { ToastComponent } from './components/toast/toast.component';
import {ToastService} from './providers/toast.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CategorySlugPipe } from './pipe/category-slug.pipe';
import {PipeModule} from './pipe/pipe.module';
import {GoogleTagManagerModule} from 'angular-google-tag-manager';
import {AnalyticsService} from './providers/analytics.service';
import {SegmentModule} from 'ngx-segment-analytics';

@NgModule({
  declarations: [
    AppComponent,
    LoyaltyComponent,
    OrderTypeModalComponent,
    DeliveryModalComponent,
    TimeSelectionModalComponent,
    ReorderModalComponent,
    SignInModalComponent,
    ForgotPasswordModalComponent,
    CareersComponent,
    BasketComponent,
    HeaderComponent,
    FooterComponent,
    NewNewsComponent,
    PostDetailComponent,
    OrderOnlineComponent,
    // ChoicesPipe,
    NotifyComponent,
    LoaderComponent,
    RatingComponent,
    DateSelectComponent,
    SigninComponent,
    DeliveryPickupComponent,
    // SignInComponent
    ContainedPhotoDirective,
    ToastComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    TabsModule.forRoot(),
    CreditCardDirectivesModule,
    BsDropdownModule.forRoot(),
    AlertModule.forRoot(),
    CollapseModule,
    PopoverModule.forRoot(),
    BrowserAnimationsModule, // required animations module
    CarouselModule.forRoot(),
    OrderModule,
    NgpSortModule,
    NgxMaskModule.forRoot(),
    LazyLoadImageModule,
    FontAwesomeModule,
    NgbCarouselModule,
    SocialLoginModule,
    NgbToastModule,
    NgbModule,
    PipeModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-MN3W3FN'
    }),
    SegmentModule.forRoot({
      apiKey: 'dQPJw8KvquvnkqXIlb0IQD726gLPc0XD',
      debug: false,
      loadOnInitialization: true
    })
  ],
  providers: [
    RestaurantService,
    OrderMethodService,
    MenuService,
    BasketService,
    UpsellService,
    PunchService,
    TwilioService,
    DineEngineCMSService,
    RecaptchaService,
    // MetaService,
    RatingService,
    LoaderService,
    ContrastService,
    ToastService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: getAuthServiceConfigs()
    },
    MetaDataService,
    AnalyticsService
  ],
  bootstrap: [
    AppComponent
  ],
  exports: [
    OrderTypeModalComponent,
    DeliveryModalComponent,
    TimeSelectionModalComponent,
    ReorderModalComponent,
    SignInModalComponent,
    ForgotPasswordModalComponent,
  ],
  entryComponents: [
    OrderTypeModalComponent,
    DeliveryModalComponent,
    TimeSelectionModalComponent,
    ReorderModalComponent,
    SignInModalComponent,
    ForgotPasswordModalComponent
  ]
})
export class AppModule {
}
