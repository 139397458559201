import { Injectable } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {DineEngineCMSService} from './dine-engine-cms.service';
import {Meta, MetaDefinition, Title} from '@angular/platform-browser';
import {filter} from 'rxjs/operators';
import {MetaData} from '../models/cms.collections';


export interface ManualMetaData {
  title?: string;
  description?: string;
  keywords?: string[];
}

@Injectable()
export class MetaDataService {

  pages: MetaData[] = [];

  constructor(private router: Router, private cms: DineEngineCMSService, private meta: Meta, private title: Title) {
    this.cms.getMetaData().subscribe((res) => {
      res.data.forEach(page => {
        this.pages[page.pagename] = page;
      });
      this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        this.loadSpecificPage(event.url);
      });
    });


  }

  loadSpecificPage(route: string) {
    if (this.pages[route]) {
      this.updatePage(this.pages[route]);
    } else {
      this.updatePage(this.pages['/']);
    }
  }

  manualUpdate(tagInfo: ManualMetaData) {
    if (tagInfo.title) {
      this.updateTitle(tagInfo.title);
    }
    if (tagInfo.description) {
      this.updateDescription(tagInfo.description);
    }
    if (tagInfo.keywords) {
      this.updateKeywords(tagInfo.keywords);
    }
    this.checkAndUpdate({property: 'og:url', content: window.location.href});
  }

  private updatePage(page: MetaData) {
    this.updateTitle(page.title);
    this.updateDescription(page.description);
    this.updateKeywords(page.keywords);
    this.checkAndUpdate({property: 'og:url', content: window.location.href});
  }

  private updateTitle(title: string): void {
    this.title.setTitle(title);
    this.checkAndUpdate({property: 'og:title', content: title});
    this.checkAndUpdate({name: 'twitter:title', content: title});
  }

  private updateDescription(description: string): void {
    this.checkAndUpdate({name: 'description', content: description, });
    this.checkAndUpdate({property: 'og:description', content: description});
    this.checkAndUpdate({name: 'twitter:description', content: description});
  }

  private updateKeywords(keywords: string[]): void {
    this.checkAndUpdate({name: 'keywords', content: keywords.join(', ')});
  }

  private checkAndUpdate(tag: MetaDefinition) {
    if (this.meta.getTag(tag.name ? `name='${tag.name}'` : `property='${tag.property}'`)) {
      this.meta.updateTag(tag, tag.name ? `name='${tag.name}'` : `property='${tag.property}'`);
    } else {
      this.meta.addTag(tag);
    }
  }
}
