<div>
  <div class="modal-exit">
    <img (click)="closeModalClicked()" class="modal-exit-img" src="assets/icon/exit-button.svg" alt="Close Button" />
  </div>
  <div class="bs-modal-content-shell" [ngClass]="{'isLoading': isLoading}">
    <div class="bs-modal-content">

  <div class="delivery-pickup-wrapper">
  <h1 class="bs-modal-header">Get Delivery</h1>
    <div *ngIf="deliveryAddresses && deliveryAddresses.length">
    <div class="bs-modal-subheader">Previous Addresses</div>
    <form [formGroup]="savedAddressForm">
    <div class="form-delivery-address px-3">
    <div class="form-row address-cont-cont">
      <div class="col p-0">
        <div class="input-header">Saved Addresses</div>
        <select formControlName="address" name="address" aria-label="address">
          <option value="" disabled selected hidden>Saved Addresses</option>
          <option *ngFor="let address of deliveryAddresses; index as i" [value]="i">{{address.streetaddress}} {{address.building}} {{address.city}}, {{address.zipcode}}</option>
        </select>
      </div>
    </div>
    </div>
    </form>
    <div class="delivery-options row m-0 mt-2">
      <div class="col p-2 m-0">
        <button class="freebirds-btn w-100" [ngClass]="{'is-loading' : isLoading}" [disabled]="!savedAddressForm.value.address" (click)="savedAddressSelected()">
          <div *ngIf="!isLoading || loaderType !== 'savedaddress'">Continue</div>
          <div *ngIf="isLoading && loaderType === 'savedaddress'" class="spin-loader"></div>
        </button>
      </div>
    </div>
    <div class="delivery-options row m-0">
      <div class="col p-2 d-flex justify-content-center">
        <div class="or-modal-divider mr-3"></div>
        <div class="bs-modal-subheader">Or</div>
        <div class="or-modal-divider ml-3"></div>
      </div>
    </div>
  </div>
    <div class="bs-modal-subheader">Enter Your Delivery Address Information</div>
    <div class="sanFran my-2 text-center w-100">
      <div class="delivery-body-copy">{{ deliveryBodyCopy }}</div>
    </div>
    <form [formGroup]="addressForm" class="pickup-delivery">
      <div class="form-delivery-address px-3">
        <div class="form-row">
          <div class="input-header">Delivery Address</div>
          <input type="text" name="address" formControlName="streetAddress" placeholder="Delivery Address" aria-label="Address"/>
        </div>
        <div class="form-row address-cont">
          <div class="input-header">Delivery Address Line 2</div>
          <input type="text" name="suite" formControlName="building" placeholder="Apt, Suite, Floor, Etc." aria-label="Apt, Suite, Floor, Etc."/>
        </div>
        <div class="form-row">
          <div class="input-header">Zip Code</div>
          <input type="text" inputmode="numeric" name="zip" formControlName="zipCode" placeholder="Zip" aria-label="Zip Code" maxlength="5"/>
        </div>
        <div class="form-row address-cont-cont">
          <div class="col p-0 mr-2">
            <div class="input-header">City</div>
          <input type="text" name="city" formControlName="city" placeholder="City" aria-label="City"/>
          </div>
          <div class="col p-0 ml-2">
            <div class="input-header">State</div>
            <select formControlName="state" name="state" aria-label="State">
              <option value="" disabled selected hidden>State</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="--">---</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="input-header">Special Instructions</div>
          <textarea class="form-control w-100 black" aria-label="Delivery Instructions" placeholder="Delivery Instructions" formControlName="specialInstructions"></textarea>
        </div>
      </div>
    </form>
  </div>
  <div class="delivery-options row m-0 mt-3">
    <div class="col p-2 m-0">
      <button class="freebirds-btn-secondary w-100" (click)="routeToLocations()" routerLink="/locations">Switch to Pickup</button>
    </div>
    <div class="col p-2 m-0">
      <button class="freebirds-btn w-100" (click)="newAddressSubmit()"
      [ngClass]="{'is-loading' : isLoading}"
      [disabled]="
      !addressForm.value.streetAddress ||
      !addressForm.value.city ||
      !addressForm.value.zipCode">
      <div *ngIf="!isLoading || loaderType !== 'newaddress'">Continue</div>
      <div *ngIf="isLoading && loaderType === 'newaddress'" class="spin-loader"></div></button>
    </div>
  </div>
    </div>
  </div>
</div>
