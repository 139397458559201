<div>
  <div class="modal-exit">
    <img (click)="closeModalClicked()" class="modal-exit-img" src="assets/icon/exit-button.svg" alt="close">
  </div>
  <div class="bs-modal-content-shell">
    <div class="bs-modal-content">

  <div class="delivery-pickup-wrapper">
  <h1 class="bs-modal-header">{{signInHeader}}</h1>

  <div class="delivery-method">
      <div class="row delivery-method mt-3 mb-3">
        <div class="col">
          <label class="radio-container sanFran text-left">Sign In
            <input #signupRadio type="radio" id="signinradio"
                    value="signin" (click)="signInSelect($event)" name="membermethod" checked>
            <span (click)="signInSelect($event)" class="checkmark"></span>
          </label>
        </div>
        <div class="col">
          <label class="radio-container sanFran text-left">Create An Account
            <input type="radio" value="createaccount" (click)="signInSelect($event)" name="membermethod" id="createAccount">
            <span (click)="signInSelect($event)" class="checkmark"></span>
          </label>
        </div>
      </div>
      <div>
        <ng-container *ngTemplateOutlet="signUpContent"></ng-container>
      </div>
    <ng-template #signInForm>
      <div class="sign-in-form">
        <form name="loginForm" class="inputs" [formGroup]="loginForm">
          <input (focusout)="inputScroll()" type="email" name="email" formControlName="email" placeholder="Email Address"/>
          <input (focusout)="inputScroll()" type="password" name="password" formControlName="password" placeholder="Password"/>
        </form>
        <div class="row forgot-password-keep-signed-in sanFran">
          <div class="col-12 keep-signed-in">
            <label class="checkbox-container" (click)="onClick($event)">
              <span class="keep-signed-in-text">Keep me signed in</span>
              <input type="checkbox" checked="checked" value="1" [(ngModel)]="keepSigned">
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="col-12 keep-signed-in-text text-center mt-3" (click)="openForgotPassword()">Forgot Password?</div>
        </div>
      </div>
      <hr/>
      <div class="sign-in-up-buttons">
        <button (click)="onSubmit()" [ngClass]="{'is-loading' : isLoading}" class="freebirds-btn garageGothic sign-in">
          <div *ngIf="!isLoading">Sign In</div>
          <div *ngIf="isLoading" class="spin-loader"></div>
        </button>
            <button class="freebirds-btn sign-in-facebook" (click)="facebookLogin()">Sign In With Facebook</button>
      </div>
    </ng-template>

    <ng-template #signUpForm>
      <form name="loginForm" class="inputs sanFran" [formGroup]="signUpFormController">
        <div class="form-group">
          <input (focusout)="inputScroll()" class="form-control form-control-lg" formControlName="first_name" name="first_name" type="text"
                  placeholder="First Name" aria-label="First Name" required>
        </div>
        <div class="form-group">
          <input (focusout)="inputScroll()" class="form-control form-control-lg" formControlName="last_name" type="text" placeholder="Last Name"
                  aria-label="Last Name" required>
        </div>
        <div class="form-group">
          <input (focusout)="inputScroll()" class="form-control form-control-lg" formControlName="email" type="email" placeholder="E-mail"
                  aria-label="Email" required>
        </div>
        <div class="form-group">
          <input (focusout)="inputScroll()" class="form-control form-control-lg" formControlName="phonenumber" type="tel" placeholder="Phone number"
                  aria-label="Phone Number" mask="000-000-0000" required>
        </div>
        <div class="form-group">
          <input (focusout)="inputScroll()" class="form-control form-control-lg" formControlName="password" type="password" placeholder="Password"
                  aria-label="Password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                  required>
        </div>
        <div class="form-group">
          <input (focusout)="inputScroll()" class="form-control form-control-lg" type="password" formControlName="password2"
                  placeholder="Re-enter Password" aria-label="Re-Enter Password"
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                  required>
        </div>
        <div class="form-group text-left">
          <label class="checkbox-container w-100">
            <span class="keep-signed-in-text">I agree to the
              <a (click)="termsLinkClicked()" target="_blank"> terms &amp; conditions</a> and <a
                (click)="termsLinkClicked()" target="_blank">PRIVACY POLICY </a></span>
            <input (click)="onClick($event)" type="checkbox" checked="" id="termsCheck" formControlName="terms" required>
            <span (click)="onClick($event)" class="checkmark"></span>
          </label>
        </div>

        <div class="form-group text-left">
          <label class="checkbox-container w-100">
            <span class="keep-signed-in-text text-center">Sign me up for news and offers</span>
            <input class="form-check-input" (click)="onClick($event)" type="checkbox" checked="" id="signUpCheck">
            <span (click)="onClick($event)" class="checkmark"></span>
          </label>
        </div>
        <div class="form-group text-left">
          <label class="checkbox-container w-100">
            <span class="keep-signed-in-text">Opt in to SMS messaging (SMS data rates may apply)</span>
            <input class="form-check-input" (click)="onClick($event)" type="checkbox" checked="" id="smsCheck"
                   formControlName="smsCheck">
            <span (click)="onClick($event)" class="checkmark"></span>
          </label>
        </div>

        <div class="form-group">
          <button (click)="signUpClicked()" [ngClass]="{'is-loading' : isLoading}" class="btn btn-block freebirds-btn btn-freebirds clickable" type="submit">
            <div *ngIf="!isLoading">Create Account</div>
            <div *ngIf="isLoading" class="spin-loader"></div>
          </button>
        </div>
      </form>
    </ng-template>
  </div>


  </div>
    </div>
  </div>
</div>
