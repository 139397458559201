import {
  Component,
  OnInit,
  ElementRef,
  AfterContentInit,
  ViewChild,
  HostListener,
  Output,
  EventEmitter,
  Renderer2
} from '@angular/core';
import { BasketService } from 'app/providers/basket.service';
import { RestaurantService } from 'app/providers/restaurant.service';
import { UpsellService } from 'app/providers/upsell.service';
import { Basket } from 'app/models/basket';
import { Router } from '@angular/router';
import { MenuService } from "app/providers/menu.service";
import { OrderMethodService } from 'app/providers/order-method.service';
import * as moment from 'moment';
import {AnalyticsService} from '../../../providers/analytics.service';

declare let fbq: Function;

@Component({
  selector: 'app-main-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss']
})
export class BasketComponent implements OnInit, AfterContentInit {
  @ViewChild('closeButton') closeButton;
  @ViewChild('cartArea') cartArea: ElementRef;
  @Output() closeModal = new EventEmitter();
  @Output() openLogin = new EventEmitter();
  basket: any = false;
  basketProducts = [];
  restaurantID;
  restaurant;
  punchhMobile = window.localStorage.getItem('punchhMobile');
  isLandscape =
    window.innerHeight < window.innerWidth && window.innerHeight < 660;
  userLoggedIn: boolean;
  storage: Storage;
  isInvitedUser = false;
  groupID: any;
  group_orderDeadline = '';
  location;
  menu;
  constructor(
    public basketService: BasketService,
    private element: ElementRef,
    private restaurantService: RestaurantService,
    private upsell: UpsellService,
    private router: Router,
    private menuService: MenuService,
    private renderer: Renderer2,
    private orderMethodService: OrderMethodService,
    private analytics: AnalyticsService
  ) {}

  @HostListener('window:click', ['$event'])
  exitOnOutsideClick(event: Event) {
    if (event.target === this.cartArea.nativeElement) {
      this.closeModalClicked()
    }
  }

  ngOnInit() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

    this.storage = window.localStorage.getItem('keepSigned')
      ? window.localStorage
      : window.sessionStorage;
    this.location = JSON.parse(this.storage.getItem('restaurant'));
    this.basketService.getBasket().subscribe(
      basket => {
        this.basket = basket;
        this.formatBasketProducts();
      },
      error => {
        this.basket = null;
        this.formatBasketProducts();
      }
    );

    this.basketService.onSetGroup.subscribe(result => {
      this.groupID = result.id;
      this.group_orderDeadline = result.deadline;
    });
    this.basketService.onSetBasket.subscribe(result => {
      this.basket = result;
      this.formatBasketProducts();
      this.restaurantID = this.storage.getItem('restaurant_id');
      this.getRestaurant(this.restaurantID);
    });
    this.restaurantID = this.storage.getItem('restaurant_id');
    this.getRestaurant(this.restaurantID);
    this.userLoggedIn = this.basketService.userLoggedIn();

    this.groupID = sessionStorage.getItem('group_id');
    this.basketService.onSetInvitedUser.subscribe(isInvited => {
      this.isInvitedUser = isInvited;
      this.basketService.getBasket().subscribe(
        basket => {
          this.basket = basket;
          this.formatBasketProducts();
        },
        error => {
          this.basket = null;
          this.formatBasketProducts();
        }
      );
      this.restaurantID = this.storage['restaurant_id'];
      this.groupID = sessionStorage.getItem('group_id');
      this.getRestaurant(this.restaurantID);
    });
    this.getGroup();
  }

  isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  friendlyDateTime(datetime) {
    return moment(datetime, 'YYYYMMDD hh:mm' ).format('hh:mm A, MM/DD/YY');
  }


  getGroup() {
      if (this.groupID && this.userLoggedIn) {
        this.basketService
          .getGroupOrder(this.groupID, this.storage.getItem('auth'))
          .subscribe(res => {
            if (res.isopen) {
              this.group_orderDeadline = res.deadline;
            } else {
              this.groupID = null;
              sessionStorage.removeItem('group_id');
              sessionStorage.removeItem('groupquantity');
            }
          });
      }
    setInterval(() => {
      if (this.groupID && this.userLoggedIn) {
        this.basketService
          .getGroupOrder(this.groupID, this.storage.getItem('auth'))
          .subscribe(res => {
            if (res.isopen) {
              this.group_orderDeadline = res.deadline;
              this.basketService.onSetBasket.emit(res.basket);
            } else {
              this.groupID = null;
              sessionStorage.removeItem('group_id');
              sessionStorage.removeItem('groupquantity');
            }
          });
      }
    }, 30000);
  }
  ngAfterContentInit() {
  }

  formatDateTime(datetime: string) {
    let formatDate =
      datetime.substr(4, 2) +
      '/' +
      datetime.substr(6, 2) +
      '/' +
      datetime.substr(2, 2) +
      ' ';
    const hour = parseInt(datetime.substr(9, 2));
    const minute = datetime.substr(12, 2);

    if (hour < 12) {
      formatDate += hour + ':' + minute + ' AM';
    } else if (hour === 12) {
      formatDate += hour + ':' + minute + ' PM';
    } else if (hour === 24) {
      formatDate += hour - 12 + ':' + minute + ' AM';
    } else {
      formatDate += hour - 12 + ':' + minute + ' PM';
    }
    return formatDate;
  }

  formatBasketProducts() {
    const id = this.storage.getItem('restaurant_id');
    if (id) {
    this.menuService.getMenuForRestaurant(id).subscribe(menu => {
      this.menu = menu;
      if (this.basket === null) {
        this.basketProducts = [];
      } else {
        this.basketProducts = [];
        const products = this.basket.products;
        for (let i = 0; i < products.length; i++) {
          this.menu.forEach(cat => {
            cat.products.forEach(prod => {
              if (prod.id === products[i].productId) {
                products[i].category = cat.name
              }
            })
          })
          const key = this.toTitleCase(products[i].recipient);
          if (this.basketProducts[key]) {
            this.basketProducts[key].push(products[i]);
          } else {
            this.basketProducts[key] = [products[i]];
          }
        }
      }
    })
  }
    }

  toTitleCase(str) {
    if (str && str !== null && str !== undefined) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
    return '';
  }

  removeProductFromBasket(basket_product_id: number, name) {
    const basket: Basket = this.basket;
    if (basket.products.find(prod => prod.id === basket_product_id)) {
      this.menuService.getMenuForRestaurant(this.basket.vendorid).subscribe(menu => {
        this.analytics.logRemoveFromCart(basket.products.find(prod => prod.id === basket_product_id), menu)
      })
    }
    this.basketService.removeProductFromBasket(String(basket_product_id)).subscribe((res) => {

      // window['ChepriPixelTag'].push({
      //   event: 'removeFromCart',
      //   basket: JSON.stringify(res),
      //   ecommerce: {
      //     currencyCode: 'USD',
      //     remove: {
      //       products: [
      //         {
      //           name: name
      //         }
      //       ]
      //     }
      //   }
      // });
      // fbq('trackCustom', 'RemoveFromCart', {
      //   basket: JSON.stringify(res),
      //   currencyCode: 'USD',
      //   name: name
      // });
    });
  }

  updateProductQuantityInBasket(basket_product: any) {
    if (basket_product.quantity < 1) {
      basket_product.quantity = 1;
    }
    this.basketService.updateProductInBasket(basket_product).subscribe();
  }

  getRestaurant(id) {
    if (id) {
      return this.restaurantService
        .getRestaurantById(id)
        .subscribe((restaurant) =>
          {
            this.location = restaurant;
            this.restaurant = restaurant;
            this.storage.setItem('restaurant', JSON.stringify(restaurant));
          }
        );
    }
  }

  tryCheckout(event) {
    event.preventDefault();
    if (
      this.basket &&
      this.basket.isupsellenabled &&
      !this.upsell.completedUpsell
    ) {
      this.upsell.completedUpsell = true;
      this.router.navigate(['/order/like-to-add']);
      this.closeModalClicked();
    } else {
      this.router.navigate(['/order/details']);
      this.closeModalClicked();
    }
  }

  loginmodal() {
    this.closeModalClicked();
    this.openLogin.emit();
  }

  markForEdit() {
    this.storage.setItem('markForEdit', 'true');
    this.closeModalClicked();
  }

  goToMenu() {
    this.router.navigate(['/order']);
    this.closeModalClicked();
  }

  closeModalClicked() {
    this.closeModal.emit();
  }

  orderNow() {
    this.closeModalClicked();
    this.orderMethodService.openOrderType();
  }

  @HostListener('window:resize', ['$event'])
  onResize(e) {
    this.isLandscape =
      window.innerHeight < window.innerWidth && window.innerHeight < 660;
  }
}
