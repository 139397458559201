import { NgModule } from '@angular/core';
import {ActivatedRouteSnapshot, RouterModule, Routes} from '@angular/router';
import { CareersComponent } from './pages/careers/careers.component';
import { NewNewsComponent } from './pages/new-news/new-news.component';
import { OrderOnlineComponent } from './pages/order-online/order-online.component';
import { LoyaltyComponent } from './pages/brochure/loyalty/loyalty.component';
import { PostDetailComponent } from './pages/new-news/post-detail/post-detail.component';
import { MobileGuard } from './providers/mobile.guard';

const routes: Routes = [
    {
        path: '',
        component: OrderOnlineComponent,
    },
    {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
        // component: SignInComponent ?????
    },
    {
        path: 'loyalty',
        component: LoyaltyComponent,
    },
    {
        path: 'careers',
        component: CareersComponent,
        resolve: {
            url: 'externalCareersRedirectResolver'
        },
        data: {
            externalUrl: 'https://careers.freebirds.com'
        }
    },
    {
        path: 'locations',
        loadChildren: () => import('./pages/locations/locations.module').then(m => m.LocationsModule),
        canActivate: [MobileGuard]
    },
    {
        path: 'blog',
        component: NewNewsComponent,
    },
    {
        path: 'blog/:postID',
        component: PostDetailComponent,
    },
    {
        path: 'order',
        loadChildren: () => import('./pages/order/order.module').then(m => m.OrderModule)
    },
    {
        path: 'basket',
        loadChildren: () => import('./pages/order/order.module').then(m => m.OrderModule)
    },
    {
        path: 'order-online',
        component: OrderOnlineComponent,
    },
    {
        path: 'brochure',
        loadChildren: () => import('./pages/brochure/brochure.module').then(m => m.BrochureModule)
    },
  {
    path: 'menu',
    loadChildren: () => import('./pages/static-menu/static-menu.module').then(m => m.StaticMenuModule)
  },
  {
    path: '404',
    loadChildren: () => import('./pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
  },
    {
        path: '**',
        redirectTo: '/404',
    }
];

@NgModule({
    imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
    exports: [ RouterModule ],
    providers: [
      MobileGuard,
      {
        provide: 'externalCareersRedirectResolver',
        useValue: (route: ActivatedRouteSnapshot) => {
          window.location.href = (route.data as any).externalUrl;
        }
      }
    ]
})
export class AppRoutingModule { }
