import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';


import {DomSanitizer} from '@angular/platform-browser';
import {MenuService} from 'app/providers/menu.service';
import {BasketService} from 'app/providers/basket.service';
import {RestaurantService} from 'app/providers/restaurant.service';
import {DineEngineCMSService} from '../../providers';
import {MetaDataService} from '../../providers/meta-data.service';
import {Restaurants} from '../../models/restaurants';
import {ToastService} from '../../providers/toast.service';
import {CategorySlugPipe} from '../../pipe/category-slug.pipe';
import {AnalyticsService} from '../../providers/analytics.service';
import {Category} from '../../models/olo.menu.interface';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  storage = window.localStorage.getItem('keepSigned')
    ? window.localStorage
    : window.sessionStorage;
  restaurantID;
  categoryID;
  categories;
  dispatchSelected: any = false;
  hidden: any = [];
  isInvitedUser = false;
  changeMode = '';
  changeModeTo = '';
  menuImages = [];
  basket: any;

  defaultImageUrl = 'https://cms.freebirds.chepri.com/fb/assets/7adeiksjznokcg4o';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private MenuService: MenuService,
    private BasketService: BasketService,
    private sanitizer: DomSanitizer,
    private RestaurantService: RestaurantService,
    private cms: DineEngineCMSService,
    private meta: MetaDataService,
    private toast: ToastService,
    private slugPipe: CategorySlugPipe,
    private analytics: AnalyticsService
  ) {
  }

  ngOnInit() {
    this.cms.getCategories().subscribe((res) => {
      this.populateCategoryImages(res.data);
    });
    this.BasketService.onSetBasket.subscribe(res => {
      this.basket = res;
    });
    this.storage.setItem('feedingagroup', 'false');
    this.changeMode = this.storage.getItem('changeMode');
    this.changeModeTo = (this.changeMode === 'delivery' ? 'pickup' : 'delivery');
    this.route.params.subscribe(params => {
      if (params['locationSlug']) {
          this.RestaurantService.getRestaurantBySlug(params['locationSlug']).subscribe(slugRes => {
            this.storage.setItem('restaurant', JSON.stringify(slugRes));
            this.storage.setItem('restaurant_id', slugRes.id);
            this.restaurantID = this.storage.getItem('restaurant_id');
            this.storage.setItem('dispatchSelected', 'false');
            this.storage.removeItem('basket_id');
            this.BasketService.checkCreateBasket(this.restaurantID);
              this.storage.setItem('from_location_page', 'true');
              // TODO: Show Order type modal
          this.getMenu();
          });
      } else if (params['basketID']) {
        sessionStorage.setItem('basket_id', params['basketID']);
        sessionStorage.setItem('group_id', params['basketID']);
        this.BasketService.getBasket().subscribe(
          res => {
            this.storage.setItem('restaurant_id', res.vendorid);
            this.storage.setItem('dispatchSelected', 'false');

            this.isInvitedUser = true;
            this.BasketService.setIsInvitedUser(true);

            this.restaurantID = res.vendorid;
            this.dispatchSelected = false;
            if (this.BasketService.showWelcome) {
              this.toast.info(
                'Welcome to the group order<br>Start adding items to the cart to get started.'
              );
              this.BasketService.showWelcome = false;
            }
            this.getMenu();
          }
        );
      } else if (params['groupID']) {
        sessionStorage.setItem('group_id', params['groupID']);
        this.storage.setItem('groupHost', 'true');
        if (!this.storage.getItem('auth')) {
          this.router.navigate(['/account/sign-in']);
        } else {
          this.BasketService.checkCreateBasket(this.restaurantID);
          this.getMenu();
          this.BasketService.getGroupOrder(
            params['groupID'],
            this.storage.getItem('auth')
          ).subscribe(res => {
          });
        }
      } else {
        this.restaurantID = this.storage.getItem('restaurant_id');
        if (this.restaurantID == null) {
          this.router.navigate(['./locations']);
        } else {
          this.BasketService.checkCreateBasket(this.restaurantID);
          this.getMenu();
        }

        this.dispatchSelected = this.storage.getItem('dispatchSelected');
        this.isInvitedUser = this.BasketService.getIsInvitedUser();
      }
    });
  }

  routeToCategory(category: Category) {
    this.analytics.logProductClick(category);
    this.router.navigate(['order', 'products', this.categorySlug(category.name)])
  }

  getMenu() {
    if (this.storage.getItem('restaurant')) {
      const restaurant: Restaurants = JSON.parse(this.storage.getItem('restaurant')) as Restaurants;
      this.meta.manualUpdate({
        title: `Freebirds World Burrito | ${restaurant.name}`
      });
    }
    this.MenuService.getMenuForRestaurant(this.restaurantID).subscribe(data => {
      const savedCategory = sessionStorage.getItem('savedCategory')
      const routeCategory = data.find(cat => savedCategory === this.slugPipe.transform(cat.name))
      if (savedCategory && routeCategory) {
        this.router.navigate(['order', 'products', this.slugPipe.transform(routeCategory.name)]).then(() => sessionStorage.removeItem('savedCategory'))
      }
      this.categories = data;
      this.analytics.logProductListView(this.categories)
      for (let i = 0; i < this.categories.length; i++) {
        if (
          this.dispatchSelected === 'true' &&
          this.MenuService.getMetadataByKey(
            'hidedispatch',
            this.categories[i].metadata
          ) === 'true'
        ) {
          this.hidden.push(this.categories[i].name);
          this.categories.splice(i, 1);
        }
        if (this.categories[i].name === 'Rev\'d Up') {
          this.categories.splice(i, 1);
        }
      }

      this.storage.setItem('hidden', this.hidden);
    });
  }

  categorySlug(categoryName) {
    return categoryName
      .toLowerCase()
      .trim()
      .replace(/[^a-z \-]/g, '')
      .replace(/ +/g, '-');
  }

  safeUrl(categoryName) {
    this.sanitizer.bypassSecurityTrustStyle(
      'url("assets/img/menu-' + categoryName.toLowerCase() + '@2x.png")'
    );
  }

  mapMenuImages() {
    this.menuImages.forEach(item => {
    });
  }

  changeFlow(data) {
    this.RestaurantService.changeFlow(data);
  }

  populateCategoryImages(data) {
    data.forEach(category => {
      if (category.image) {
        this.menuImages[category.name_slug] = category.image.data.thumbnails.find(thumb => thumb.key === 'category').url;
      } else {
        this.menuImages[category.name_slug] = this.defaultImageUrl;
      }
    });
  }
}
