<div class="menu-wrapper">
  <div class="menu-button-column">
    <i [ngClass]="{'open': showMobileMenu}" class="fa fa-bars ham-menu"  (click)="toggleMobileMenu()"></i>
  </div>

<ul class="nav left">
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/locations']">LOCATIONS</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" href="https://catering.freebirds.com/index.cfm?fuseaction=order&action=menu-categories&channel-id=2" target="_blank">CATERING</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="basket ? ['/order'] : ['/menu']">MENU</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" href="https://shop.freebirds.com/" target="_blank">SWAG</a>
  </li>
 </ul>

    <a (click)="logoclicked()" class="nav-item fb-logo">
      <h1 class="hidden-header">Freebirds World Burrito</h1>
      <img src="assets/img/Freebirds_Logos_Digital_PrimaryLogo_Carbon_RGB.svg" alt="Freebirds Logo"/>
    </a>

<ul class="nav right">
  <li class="nav-item" *ngIf="!isInvitedUser && !isTiny && !isLoggedIn()">
    <a class="nav-link" [routerLink]="['/careers']" target="_blank">CAREERS</a>
  </li>
  <li class="nav-item" *ngIf="!isInvitedUser && !isTiny && isLoggedIn()">
    <a class="nav-link" [routerLink]="['/careers']" target="_blank">CAREERS</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" href="https://shop.freebirds.com/merchandise/gift-cards.html" target="_blank">GIFT CARDS</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" *ngIf="!isLoggedIn() && !isInvitedUser && !isTiny" [routerLink]="['/loyalty']" data-dismiss="modal">REWARDS</a>
    <a class="nav-link" *ngIf="isLoggedIn() && !isInvitedUser && !isTiny" [routerLink]="['/account/loyalty']"
        data-dismiss="modal">MY ACCOUNT</a>
  </li>
  <li class="nav-item">
    <ng-container *ngIf="!punchhMobile">
      <a class="nav-link" (click)="openSignUp()" *ngIf="!isLoggedIn() && !isInvitedUser && !isTiny">SIGN IN</a>
      <a class="nav-link" *ngIf="isLoggedIn() && !isInvitedUser && !isTiny" (click)="logout()">SIGN
        OUT</a>
    </ng-container>
  </li>
  <li class="nav-item">
    <button *ngIf="!frontPage" class="btn-shopping-basket basket-image border-0" (click)="openBasket(basketModal)"
            type="button" data-target="#modalCart" data-toggle="modal" aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle Navigation" id="basketButton">
      <img src="/assets/img/shopping-bag.svg" alt="Filled Basket">
      <div *ngIf="basketCount.count > 0" class="notify-badge">{{basketCount.count}}</div>
    </button>
    <button *ngIf="frontPage" (click)="orderNowClicked()" class="freebirds-btn order-now">ORDER NOW</button>
  </li>
</ul>


</div>
<div *ngIf="showMobileMenu" [@simpleFadeAnimation]="'in'" class="mobile-menu-wrapper">
  <ng-container *ngIf="!punchhMobile">
    <a (click)="openSignUp()" *ngIf="!isLoggedIn() && !isInvitedUser && !isTiny" class="orange">SIGN IN</a>
    <a [routerLink]="['/account']" (click)="showMobileMenu = false" *ngIf="isLoggedIn() && !isInvitedUser && !isTiny" data-dismiss="modal"
      class="orange">ACCOUNT</a>
  </ng-container>
  <a [routerLink]="['/locations']" (click)="showMobileMenu = false">LOCATIONS</a>
  <a href="https://catering.freebirds.com/index.cfm?fuseaction=order&action=menu-categories&channel-id=2" target="_blank">CATERING</a>
  <a [routerLink]="basket ? ['/order'] : ['/menu']" (click)="showMobileMenu = false">MENU</a>
  <a [routerLink]="['/careers']" target=“_blank”>CAREERS</a>
  <a href="https://shop.freebirds.com/merchandise/gift-cards" target="_blank">GIFT CARDS</a>
  <a *ngIf="!isLoggedIn() && !isInvitedUser && !isTiny" [routerLink]="['/loyalty']" (click)="showMobileMenu = false" data-dismiss="modal">REWARDS</a>
  <a *ngIf="isLoggedIn() && !isInvitedUser && !isTiny" [routerLink]="['/account/loyalty']" (click)="showMobileMenu = false"
      data-dismiss="modal">REWARDS</a>
  <a href="https://shop.freebirds.com/" target="_blank">SWAG</a>
  <a class="clickable" *ngIf="isLoggedIn() && !isInvitedUser && !isTiny" (click)="logout()">SIGN OUT</a>
  <div class="fade-right"></div>
</div>
<ng-template #modalContent>
  <div class="p-3">
    <h1 class="sign-in text-center">{{signInHeader}}</h1>
    <div class="delivery-method">
      <div>
        <label class="radio-container sanFran">Sign In
          <input #signupRadio type="radio" id="signinradio"
                 value="signin" (click)="signInSelect($event)" name="membermethod">
          <span (click)="signInSelect($event)" class="checkmark"></span>
        </label>
      </div>
      <div>
        <label class="radio-container sanFran">Create An Account
          <input type="radio" value="createaccount" (click)="signInSelect($event)" name="membermethod">
          <span (click)="signInSelect($event)" class="checkmark"></span>
        </label>
      </div>
    </div>
    <div>
      <ng-container *ngTemplateOutlet="signUpContent"></ng-container>
    </div>
  </div>
</ng-template>
<ng-template #signInForm>
  <div class="sign-in-form">
    <form name="loginForm" class="inputs" [formGroup]="loginForm">
      <input (focusout)="inputScroll()" type="email" name="email" formControlName="email" placeholder="Email Address"/>
      <input (focusout)="inputScroll()" type="password" name="password" formControlName="password" placeholder="Password"/>
    </form>
    <div class="forgot-password-keep-signed-in sanFran">
      <div class="keep-signed-in">
        <label class="checkbox-container" (click)="onClick($event)">
          <span class="keep-signed-in-text">Keep me signed in</span>
          <input type="checkbox" checked="checked" value="1" [(ngModel)]="keepSigned">
          <span class="checkmark"></span>
        </label>
      </div>
      <a class="keep-signed-in-text">Forgot Password?</a>
    </div>
  </div>
  <hr/>
  <div class="sign-in-up-buttons">
    <button (click)="onSubmit()" class="freebirds-btn garageGothic sign-in">Sign In</button>
        <button class="freebirds-btn sign-in-facebook" (click)="facebookLogin()">Sign In With Facebook</button>
  </div>
</ng-template>

<ng-template #signUpForm>
  <form name="loginForm" class="inputs sanFran" [formGroup]="signUpFormController">
    <div class="form-group">
      <input (focusout)="inputScroll()" class="form-control form-control-lg" formControlName="first_name" name="first_name" type="text"
              placeholder="First Name" aria-label="First Name" required>
    </div>
    <div class="form-group">
      <input (focusout)="inputScroll()" class="form-control form-control-lg" formControlName="last_name" type="text" placeholder="Last Name"
              aria-label="Last Name" required>
    </div>
    <div class="form-group">
      <input (focusout)="inputScroll()" class="form-control form-control-lg" formControlName="email" type="email" placeholder="E-mail"
              aria-label="Email" required>
    </div>
    <div class="form-group">
      <input (focusout)="inputScroll()" class="form-control form-control-lg" formControlName="phonenumber" type="tel" placeholder="Phone number"
              aria-label="Phone Number" mask="000-000-0000" required>
    </div>
    <div class="form-group">
      <input (focusout)="inputScroll()" class="form-control form-control-lg" formControlName="password" type="password" placeholder="Password"
              aria-label="Password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
              required>
    </div>
    <div class="form-group">
      <input (focusout)="inputScroll()" class="form-control form-control-lg" type="password" formControlName="password2"
              placeholder="Re-enter Password" aria-label="Re-Enter Password"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
              required>
    </div>
    <div class="form-group">
      <label class="checkbox-container">
        <span class="keep-signed-in-text">I agree to the
          <a (click)="termsLinkClicked()" target="_blank"> terms &amp; conditions</a> and <a
            (click)="termsLinkClicked()" target="_blank">PRIVACY POLICY </a></span>
        <input (click)="onClick($event)" type="checkbox" checked="" id="termsCheck" formControlName="terms" required>
        <span (click)="onClick($event)" class="checkmark"></span>
      </label>
    </div>

    <div class="form-group">
      <label class="checkbox-container">
        <span class="keep-signed-in-text">Sign me up for news and offers</span>
        <input class="form-check-input" (click)="onClick($event)" type="checkbox" checked="" id="signUpCheck">
        <span (click)="onClick($event)" class="checkmark"></span>
      </label>
    </div>
    <div class="form-group">
      <label class="checkbox-container">
        <span class="keep-signed-in-text">Opt in to SMS messaging (SMS data rates may apply)</span>
        <input class="form-check-input" (click)="onClick($event)" type="checkbox" checked="" id="smsCheck"
               formControlName="smsCheck">
        <span (click)="onClick($event)" class="checkmark"></span>
      </label>
    </div>

    <div class="form-group">
      <button (click)="signUpClicked()" class="btn btn-block freebirds-btn btn-freebirds clickable" type="submit">CREATE
        ACCOUNT
      </button>
    </div>
  </form>
</ng-template>

<ng-template #errorMessageModal>
  <h2>Error</h2>
  <p class="create-account">{{errorMessage}}</p>
  <button (click)="switchFromError()" class="btn btn-block freebirds-btn garageGothic">Back</button>
</ng-template>

<ng-template #forgotPassSuccess>
  <h1 class="sign-in">RESET PASSWORD</h1>
  <p class="create-account">Please check your email to
    reset your password.</p>
  <div class="sign-in-up-buttons mt-20px">
    <button (click)="dismissModal()" class="freebirds-btn sign-in">Continue</button>
  </div>
</ng-template>

<ng-template #basketModal>
  <app-main-basket (closeModal)="closeCartModal()" (openLogin)="openSignUp()"></app-main-basket>
</ng-template>
