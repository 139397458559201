<div class="scrollable_view hands-background">
  <div>
    <div class="row">
      <div class="col mx-3 pt-3 back">
        <span class="text-uppercase">
          <i class="fa fa-angle-left"></i>&nbsp;

          <a class="b-crumb back" [routerLink]="['/blog']">BACK</a>
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h1 class="garageGothic text-center blog-title">Blog</h1>
      </div>
    </div>
  </div>

  <div>
    <div>
      <div class="d-flex flex-column align-items-center justify-content-between">
        <div class="main_wrapper_car" *ngIf="content">
          <div class="top_car">
            <img [src]="content.image.data.full_url" (load)="image1Load = true" [hidden]="!image1Load"
              class="" [alt]="content.title ? content.title : 'Slide Image'" #img1>
          </div>
          <div class="bottom_car">
            <h2 class="tan-card-txt garageGothic">
              {{ content.title }}
            </h2>
            <p>
              {{ content.published_date | date:'M/d/yy' }}
            </p>
            <p class="mt-10px text-left" [innerHTML]="content.body"></p>
            <p *ngIf="content.tags.length > 0">
              <strong>Tags:&nbsp;</strong>{{ content.tags.join(', ') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
