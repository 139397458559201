import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter,
} from '@angular/core';
import {PubSubService} from '../../providers/pub-sub.service';
import { MenuService } from 'app/providers/menu.service';
import { Subscription } from 'rxjs';
import {take} from 'rxjs/operators';
import { DineEngineCMSService } from 'app/providers/dine-engine-cms.service';
import { UnavailableItem } from 'app/models/unavailable-item.interface';

declare let fbq: Function;

@Component({
  selector: 'option-image-checkbox',
  templateUrl: './option-image-checkbox.component.html',
  styleUrls: ['./option-image-checkbox.component.scss'],
})
export class OptionImageCheckboxComponent implements OnInit {
  subs: Subscription[] = [];

  @Input() image?: string; // Image url
  @Input() lowest?: number; // Image url
  @Input() type = 'checkbox';
  @Input() description;
  @Input() choice: any;
  @Input() name: string; // Form input name
  @Input() groupName: string;
  @Input() group: string; // Input group name, for hide/show of sections
  @Input() title: string;
  @Input() subtitle: string;
  @Input() value: string; // Form input value
  @Input() checked = false; // Whether it is checked by default
  @Input() premium = false; // Whether or not to show premium tag
  @Input() heatLevel = 0;
  @Input() doubleOption: any;
  @Input() unavailableWarnings: UnavailableItem;
  @Input() basket: any;
  @Input() set option(val: any) {
    if (val.metadata) {
      let obj = val.metadata.find(o => o.key === 'unavailable');
      if (obj) {
        this.unavailableHandoffModes = obj.value.split(', ');
      }
    }
  };
  isTiny = window.innerWidth <= 340;
  menuEmoji;

  categories;
  restaurantID;

  defaultImageUrl = '/assets/img/Default Image.png';
  categoryID;
  showFullImage = false;

  @Output() itemChanged = new EventEmitter();
  @Output() doubleMeatClicked = new EventEmitter();

  @ViewChild('check') checkbox;

  @ViewChild('double') doubleMeat;

  // hover:boolean = false;

  unavailableHandoffModes: string[] = [];

  constructor(
    private pubsub: PubSubService,
    private MenuService: MenuService,
    private cms: DineEngineCMSService,
  ) {}

  ngOnInit() {
    const fullImageList = [
      'Freebird',
      'Monster',
      'Super Monster',
      'Half Bird'
    ];
    this.showFullImage = fullImageList.includes(this.title);
    this.getEmoji();

    if (this.image === null) {
      throw new Error(`OptionImageCheckbox: attribute 'image' is required`);
    }
    if (this.name === null) {
      throw new Error(`OptionImageCheckbox: attribute 'name' is required`);
    }
    if (this.value === null) {
      throw new Error(`OptionImageCheckbox: attribute 'value' is required`);
    }
    if (this.type === 'radio') {
      this.pubsub.$sub(this.name, (checked) => {
        this.checked = checked;
      });
    }
  }

  bgVisibile() {
    return this.checked; // || this.hover;
  }

  toggle() {
    this.checkbox.nativeElement.checked = !this.checkbox.nativeElement.checked;
    this.inputChanged();
  }

  inputChanged() {
    if (this.type === 'radio' && this.checkbox.nativeElement.checked) {
      // Have to do pubsub because ngModel binding breaks radio button exclusivity
      this.pubsub.$pub(this.name, false);
    }

    this.checked = this.checkbox.nativeElement.checked;
    if (this.checked) {
      // window["ChepriPixelTag"].push({
      //   event: "order_product_option_selected",
      //   name: this.title,
      // });
      // fbq("trackCustom", "OrderProductOptionSelected", {
      //   name: this.title,
      // });
    } else {
      // window["ChepriPixelTag"].push({
      //   event: "order_product_option_deselected",
      //   name: this.title,
      // });
      // fbq("trackCustom", "OrderProductOptionDeselected", {
      //   name: this.title,
      // });
    }

    this.itemChanged.emit({ target: this.checkbox.nativeElement });
  }

  toggleDouble() {
    this.doubleMeat.nativeElement.checked = !this.doubleMeat.nativeElement.checked;
    this.doubleMeatClicked.emit({target: this.doubleMeat.nativeElement});
  }

  getProduct() {
    this.MenuService.getMenuForRestaurant(this.restaurantID).subscribe(
      (data) => {
        this.categories = data;
      }
    );
  }

  getEmoji() {
    this.subs.push(this.cms.getBranding().pipe(take(1)).subscribe((res) => {
      if (res.data.menu_emoji) {
        this.menuEmoji = res.data.menu_emoji;
      }
    }));
  }
}
