import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { DineEngineCMSService } from '../../../providers/dine-engine-cms.service';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {NgbCarousel, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SignInModalComponent} from '../../modals/sign-in-modal/sign-in-modal.component';
import {HeaderComponent} from '../../main/header/header.component';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {BasketService, PunchService, RestaurantService} from '../../../providers';
import {FacebookLoginProvider, SocialAuthService} from 'angularx-social-login';
import {ToastService} from '../../../providers/toast.service';
import {OrderMethodService} from '../../../providers/order-method.service';
import {ContrastService} from '../../../providers/contrast.service';
import {ForgotPasswordModalComponent} from '../../modals/forgot-password-modal/forgot-password-modal.component';
import {LoyaltySlides} from '../../../models/cms.collections';

declare var  $: any;

@Component({
  selector: 'app-loyalty',
  templateUrl: './loyalty.component.html',
  styleUrls: ['./loyalty.component.scss']
})
export class LoyaltyComponent implements OnInit {

  @ViewChild('carousel') carousel: NgbCarousel;

  signInSignUpModal: NgbModalRef;

  keepSigned: boolean;

  presentEmailWarning = false;
  errorMessage: string;
  showMobileMenu = false;



  storage = window.localStorage.getItem('keepSigned')
    ? window.localStorage
    : window.sessionStorage;
  slides: LoyaltySlides[];
  BASE_URL = environment.imageUrl;

  constructor(
    private cms: DineEngineCMSService,
    private sanitizer: DomSanitizer,
    private modal: NgbModal,
  private basketService: BasketService,
  private router: Router,
  private contrast: ContrastService,
  private formBuilder: FormBuilder,
  private Punch: PunchService,
  protected restaurantService: RestaurantService,
  protected socialAuthService: SocialAuthService,
  protected toast: ToastService,
  private route: ActivatedRoute,
  private orderMethod: OrderMethodService,
  ) {}

  signUp(isSignUp: boolean) {
    this.storage.setItem('sign_in_url', '/account/loyalty');
    // TODO: Add Sign up Modal
    this.signInSignUpModal = this.modal.open(SignInModalComponent)
    if (isSignUp) {
      this.signInSignUpModal.componentInstance.signInHeader = 'Create an account & get rewards';
      this.signInSignUpModal.shown.subscribe(() => {
        this.signInSignUpModal.componentInstance.signUpContent = this.signInSignUpModal.componentInstance.signUpForm;
        document.getElementById('createAccount').click()
      })
    }
    this.signInSignUpModal.componentInstance.closeModal.subscribe(() => {
      this.signInSignUpModal.dismiss();
    })
    this.signInSignUpModal.componentInstance.signInEmit.subscribe(res => {
      this.keepSigned = this.signInSignUpModal.componentInstance.keepSigned
      this.submit(res);
    })
    this.signInSignUpModal.componentInstance.facebookEmit.subscribe(() => {
      this.facebookLogin();
    })
    this.signInSignUpModal.componentInstance.createAccountEmit.subscribe(res => {
      this.submitSignUp(res);
    })
    this.signInSignUpModal.componentInstance.forgotPasswordEmit.subscribe(() => {
      this.signInSignUpModal.dismiss();
      this.submitForgotPassword();
    })
  }

  ngOnInit() {
    this.getCmsData();
  }

  getCmsData() {
    this.cms.getLoyaltySlides().subscribe(
      (res) => {
        res.data.forEach(slide => {
          slide.slide_content = this.allowHTML(String(slide.slide_content))
          slide.before_sign_up_text = this.allowHTML(String(slide.before_sign_up_text))
        })
        this.slides = res.data;
      }
    );
  }

  allowHTML(content: string) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }


  // This is used to LOGIN
  submit(data) {
    const groupHost = this.storage.getItem('groupHost');
    window.sessionStorage.removeItem('PunchhToken');
    window.sessionStorage.removeItem('PunchAuthToken');
    window.localStorage.removeItem('PunchhToken');
    window.localStorage.removeItem('PunchAuthToken');
    let PunchhToken;
    let PunchUserId;
    const form = {
      client: environment.punchClientId,
      user: { email: data.email, password: data.password }
    };
    const form1 = JSON.stringify(form);
    this.Punch.signin(form1).subscribe(
      res => {
        PunchhToken = res.access_token;
        PunchUserId = res.user_id;
        const storage = this.keepSigned
          ? window.localStorage
          : window.sessionStorage;
        if (this.keepSigned) {
          window.localStorage.setItem('keepSigned', 'true');

          Object.keys(window.sessionStorage).forEach(key => {
            window.localStorage.setItem(key, sessionStorage.getItem(key));
          });
          window.sessionStorage.clear();
        }
        storage.setItem('PunchAuthToken', res.authentication_token);
        storage.setItem('PunchhToken', PunchhToken);

        this.doOlo(PunchhToken, PunchUserId, groupHost);
      },
      err => {
        if (err.error) {
          console.log(err)
          this.errorMessage = err.error.error;
          this.toast.danger(this.errorMessage);
          this.signInSignUpModal.componentInstance.isLoading = false;
        }
      }
    );
  }

  doOlo(PunchhToken, PunchUserId, groupHost?, newPage?) {
    const storage = this.keepSigned
      ? window.localStorage
      : window.sessionStorage;
    const oloBody = {
      provider: 'punchh',
      providertoken: PunchhToken,
      contactnumber: '',
      basketid: '',
      authtoken: '',
      provideruserid: PunchUserId
    };
    this.Punch.getOloToken(oloBody).subscribe(
      res => {
        if (res) {
          storage.setItem('auth', res.authtoken);
        }
        const sign_in_url = this.storage.getItem('sign_in_url');
        if (sign_in_url) {
          this.storage.setItem('sign_in_url', '');
          newPage = sign_in_url;
        }
        if (groupHost === 'true') {
          const groupid = sessionStorage.getItem('group_id');
          this.basketService.getGroupOrder(groupid, res.authtoken).subscribe(() => {
              this.router.navigate(['/order/details']);
            },
            () => {
              const sign_in_url = this.storage.getItem('sign_in_url');
              if (sign_in_url) {
                window.location.href = sign_in_url;
                return;
              }
              window.location.href = '/account/loyalty';
            }
          );
          return;
        }
        if (newPage) {
          window.location.href = newPage;
        } else {
          window.location.href = '/account/loyalty';
          this.showMobileMenu = false;
        }
        this.signInSignUpModal.componentInstance.isLoading = false;
      },
      error => {
        this.toast.danger(error.message)
        this.signInSignUpModal.componentInstance.isLoading = false;
      }
    );
  }

  facebookLogin() {
    const socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    this.socialAuthService.signIn(socialPlatformProvider).then(userData => {
      // this will return user data from facebook. What you need is a user token which you will send it to the server
      // this.sendToRestApiMethod(userData.token);
      const form = {
        client: environment.punchClientId,
        email: userData.email,
        fb_uid: userData.id,
        access_token: userData.authToken
      };
      const form1 = JSON.stringify(form);
      window.sessionStorage.removeItem('PunchhToken');
      window.sessionStorage.removeItem('PunchAuthToken');
      window.sessionStorage.removeItem('auth');
      window.localStorage.removeItem('PunchhToken');
      window.localStorage.removeItem('PunchAuthToken');
      window.localStorage.removeItem('auth');
      let PunchhToken;
      let PunchUserId;

      const storage = this.keepSigned
        ? window.localStorage
        : window.sessionStorage;
      this.Punch.facebookSignin(form1).subscribe(res => {
          PunchhToken = res.access_token;
          PunchUserId = res.user_id;

          if (this.keepSigned) {
            window.localStorage.setItem('keepSigned', 'true');

            Object.keys(window.sessionStorage).forEach(key => {
              window.localStorage.setItem(key, sessionStorage.getItem(key));
            });
            window.sessionStorage.clear();
          }

          storage.setItem('PunchAuthToken', res.authentication_token);
          storage.setItem('PunchhToken', PunchhToken);
          const oloBody = {
            provider: 'punchh',
            providertoken: PunchhToken,
            contactnumber: '',
            basketid: '',
            authtoken: '',
            provideruserid: PunchUserId
          };
          this.Punch.getOloToken(oloBody).subscribe(res => {
              if (res) {
                storage.setItem('auth', res.authtoken);
              }
              window.location.href = '/account';
              // this.router.navigate(['/account']);
            }
          );
        },
        error => {
          if (error.error) {
            this.toast.danger(error.error);
          } else {
            this.toast.danger(error.message);
          }
        }
      );
    });
  }

  submitSignUp(data) {
    let PunchhToken;
    let PunchUserId;
    window.sessionStorage.removeItem('PunchhToken');
    window.sessionStorage.removeItem('PunchAuthToken');
    window.sessionStorage.removeItem('auth');

    const form = {
      client: environment.punchClientId,
      user: {
        email: data.email,
        password: data.password,
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.phonenumber,
        send_compliance_sms: data.smsCheck
      }
    };
    const form1 = JSON.stringify(form);
    this.Punch.signup(form1).subscribe(
      res => {
        PunchhToken = res.access_token;
        PunchUserId = res.user_id;
        window.sessionStorage.setItem(
          'PunchAuthToken',
          res.authentication_token
        );
        window.sessionStorage.setItem('PunchhToken', PunchhToken);
        this.deleteAllCookies();
        window.sessionStorage.setItem('email', data.email);
      },
      err => {
        this.toast.danger('There was an issue creating your account. Please check your info and try again.');
        this.signInSignUpModal.componentInstance.isLoading = false;
      },
      () => {
        const oloBody = {
          provider: 'punchh',
          providertoken: PunchhToken,
          // contactnumber: data.phonenumber,
          basketid: '',
          authtoken: '',
          provideruserid: PunchUserId
        };
        this.Punch.getOloToken(oloBody).subscribe(
          res => {
            if (res) {
              window.sessionStorage.setItem('auth', res.authtoken);
            }
            window.location.href = '/account';
            this.signInSignUpModal.componentInstance.isLoading = false;
          },
          error => {
            this.toast.danger(error.message);
            this.signInSignUpModal.componentInstance.isLoading = false;
          }
        );
      }
    );
  }

  submitForgotPassword() {
    const modalRef = this.modal.open(ForgotPasswordModalComponent, { windowClass: 'bs-modal-class' });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    })
    modalRef.componentInstance.resetPasswordEmit.subscribe(res => {
      // tslint:disable-next-line:max-line-length
      if (/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(res)) {
        this.presentEmailWarning = false;
        const form = {
          client: environment.punchClientId,
          user: {
            email: res
          }
        };
        this.Punch.forgotPassword(JSON.stringify(form)).subscribe(
          () => {
            //this.switchView(nextPage);
            modalRef.close()
          },
          () => {
            // SHOW ERROR
            this.presentEmailWarning = true;
          }
        );
      } else {
        this.presentEmailWarning = true;
        // SHOW ERROR
      }
    })
  }

  deleteAllCookies() {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }
}
