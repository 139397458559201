import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError, map, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';


import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Cacheable} from 'ts-cacheable';
import {
  Blog, Branding,
  Categories, ContactUs,
  DirectusCollection,
  DirectusSingleCollection, FAQ, HomePageSlides, IngredientGroups,
  IngredientList,
  Locations, LoyaltySlides, MetaData, Nutrition,
  Products, Terms,
} from '../models/cms.collections';

@Injectable()

export class DineEngineCMSService {
  shownPopup = false;
  cmsData: any;
  domainAPI = 'https://cms.freebirds.chepri.com/fb/';
  constructor(private https: HttpClient) {}

  getProducts(): Observable<DirectusCollection<Products>> {
    return this.getCollection<Products>('products');
  }

  getCategories(): Observable<DirectusCollection<Categories>> {
    return this.getCollection<Categories>('categories');
  }

  getIngredientList(): Observable<DirectusCollection<IngredientList>> {
    return this.getCollection<IngredientList>('ingredient_list');
  }

  getLocations(): Observable<DirectusCollection<Locations>> {
    return this.getCollection<Locations>('locations');
  }

  getBlog(): Observable<DirectusCollection<Blog>> {
    return this.getCollection<Blog>('blog')
  }

  getBranding(): Observable<DirectusSingleCollection<Branding>> {
    return this.getSingleCollection<Branding>('branding')
  }

  getContactUs(): Observable<DirectusSingleCollection<ContactUs>> {
    return this.getSingleCollection<ContactUs>('contact_us')
  }

  getFAQ(): Observable<DirectusCollection<FAQ>> {
    return this.getCollection<FAQ>('faq')
  }

  getHomePageSlides(): Observable<DirectusCollection<HomePageSlides>> {
    return this.getCollection<HomePageSlides>('home_page_slides')
  }

  getIngredientGroups(): Observable<DirectusCollection<IngredientGroups>> {
    return this.getCollection<IngredientGroups>('ingredient_groups')
  }

  getLoyaltySlides(): Observable<DirectusCollection<LoyaltySlides>> {
    return this.getCollection<LoyaltySlides>('loyalty_slides')
  }

  getMetaData(): Observable<DirectusCollection<MetaData>> {
    return this.getCollection<MetaData>('meta_data')
  }

  getNutrition(): Observable<DirectusSingleCollection<Nutrition>> {
    return this.getSingleCollection<Nutrition>('nutrition')
  }

  getTerms(): Observable<DirectusCollection<Terms>> {
    return this.getCollection<Terms>('terms')
  }

  getBlogPost(id: number): Observable<DirectusSingleCollection<Blog>> {
    const params = new HttpParams({
      fromObject: {
        'filter[id][eq]': id,
        fields: '*.*',
        limit: -1
      }
    })
    return this.https.get<DirectusSingleCollection<Blog>>(this.domainAPI + 'items/blog', {params});
  }

  @Cacheable({
    maxCacheCount: 30
  })
  private getCollection<T>(collectionName: string): Observable<DirectusCollection<T>> {
    const params = new HttpParams({
      fromObject: {
        fields: '*.*',
        limit: -1
      }
    })
    return this.https.get<DirectusCollection<T>>(this.domainAPI + 'items/' + collectionName, {params});
  }

  private getSingleCollection<T>(collectionName: string): Observable<DirectusSingleCollection<T>> {
    const params = new HttpParams({
      fromObject: {
        fields: '*.*',
        single: 1
      }
    })
    return this.https.get<DirectusSingleCollection<T>>(this.domainAPI + 'items/' + collectionName, {params})
  }

  createItem(collection: string, data: any) {
    return this.https.post(`${this.domainAPI}items/${collection}`, data, {});
  }

  // Old CMS Functions. Do not use as this CMS no longer functions

  /**
   * @deprecated
   * @protected
   */
  protected getCmsUrl() {
    if (!environment.production && environment.cmsUrl) {
      return environment.cmsUrl;
    }
    return '';
  }

  /**
   * @deprecated
   * @protected
   */
  protected getHeaders() {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');

    // If we have an Key defined use it, if not assume nginx proxy will handle it.
    if (environment.CMSToken) {
      headers.append('Authorization', `Bearer ${environment.CMSToken}`);
    }

    return headers;
  }

  /**
   * @deprecated
   */
  getTableSingleRow(page_name: string) {
    return this.https
      .get<any>(this.getCmsUrl() + '/items/' + page_name, {
        headers: this.getHeaders()
      }).pipe(
        map(res => res.data[0]),
        catchError((error: any) => observableThrowError(error.error)),);
  }

  /**
   * @deprecated
   */
  getPageSlides(slides: any) {
    const rr = slides.map(({ id }) => id);
    return this.getTable('home_page_slides').pipe(
      map(
        res => {
          return res.filter(slide => rr.includes(slide.id));
        }
      ),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  /**
   * @deprecated
   */
  getTable(page_name: string) {
    return this.https
      .get<any>(this.getCmsUrl() + '/items/' + page_name, {
        headers: this.getHeaders()
      }).pipe(
        tap(res => {
        }),
        map(res => res.json().data),
        catchError((error: any) => observableThrowError(error.error)),);
  }

  /**
   * @deprecated
   */
  getPopups() {
    return this.https
      .get<any>(this.getCmsUrl() + '/items/popups', {
        headers: this.getHeaders()
      }).pipe(
        map(res => res.data),
        catchError((error: any) => observableThrowError(error.error)),);
  }

  /**
   * @deprecated
   */
  addMenuIngredients(data) {
    return this.https
      .post<any>(this.getCmsUrl() + '/cms/ingredients/rows', {
        active: 1,
        name: data.name
      }, {
        headers: this.getHeaders()
      }).pipe(
        map(res => {
          return res.data;
        }),
        catchError((error: any) => observableThrowError(error.error)),);
  }

  /**
   * @deprecated
   */
  sendEmail(page_name, data) {
    return this.createItem(page_name, data);
  }

  /**
   * @deprecated
   */
  getCulture() {
    return this.https.get(this.getCmsUrl() + '/cms/culture/rows', {headers: this.getHeaders()});
  }

  /**
   * @deprecated
   */
  // getNutrition(): Observable<any> {
  //   return this.getCollection('nutrition');
  // }
}
