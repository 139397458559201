<div class="row align-items-center align-content-center m-auto box-lunch-container">
  <div class="col-4 mb-auto clickable" *ngIf="evenItem" (click)="selectItem()">
    <img [src]="imageUrl" onerror="this.src='/assets/img/Default Image.png';" class="round-circle d-flex justify-content-center align-items-center m-auto" width="75px" height="75px" *ngIf="!selected" alt="Box Lunch">
    <img src="/assets/img/Selected Item.png"
    onerror="this.src='/assets/img/Default Image.png';"
    class="round-circle d-flex justify-content-center align-items-center m-auto"
    width="75px" height="75px" *ngIf="selected" alt="Box Lunch">
  </div>

  <div class="col">
    <div class="row">
      <div class="col">
        <h5 class="box-title">{{option.name}}</h5>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p class="text-light" [innerHTML]="description"></p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p class="text-light">{{formatCost(option.cost)}}</p>

      </div>
    </div>
  </div>
  <div class="col-4 mb-auto clickable" *ngIf="!evenItem" (click)="selectItem()">
    <img [src]="imageUrl" onerror="this.src='/assets/img/Default Image.png';" class="round-circle d-flex justify-content-center align-items-center m-auto" width="75px" height="75px" *ngIf="!selected" alt="Box Lunch">
    <img src="/assets/img/Selected Item.png"
    onerror="this.src='/assets/img/Default Image.png';"
    class="round-circle d-flex justify-content-center align-items-center m-auto"
    width="75px" height="75px" *ngIf="selected" alt="Box Lunch">
  </div>

</div>
