<app-location-details #locationDetails [display_last_order]="true"></app-location-details>
<div class="scrollable_view hands-background confirmation-wrapper">
  <h1 class="order-placed">
    <span *ngIf="order?.deliverymode === 'dispatch' || order?.deliverymode === 'delivery'">
      DELIVERY
    </span>
    <span *ngIf="order?.deliverymode === 'pickup'">
      PICKUP
    </span>
    <span *ngIf="order?.deliverymode === 'curbside'">
      CURBSIDE
    </span>
    <span *ngIf="order?.deliverymode === 'drivethru'">
      MOBILE PICKUP LANE
    </span>
    ORDER PLACED!
  </h1>

  <div class="order-instructions">
    <span *ngIf="order?.deliverymode === 'dispatch'">
      {{ restaurant.labels[10].value }}
    </span>
    <span *ngIf="order?.deliverymode === 'delivery'">
      {{ restaurant.labels[9].value }}
    </span>
    <span *ngIf="order?.deliverymode === 'pickup'">
      {{ restaurant.labels[13].value }}
    </span>
    <span *ngIf="order?.deliverymode === 'curbside'">
      {{ restaurant.labels[7].value }}
    </span>
    <span *ngIf="order?.deliverymode === 'drivethru'">
      {{ restaurant.labels[11].value }}
    </span>
  </div>
  <div class="order-confirm-details">
    <h3>WHAT YOU ORDERED</h3>
    <div class="order sanFran">
      <div *ngFor="let prod of order?.products" class="order-item">
        <div class="order-row">
          <div class="order-name sanFran">
            {{prod?.name}}
          </div>
          <div class="order-qty">{{prod.quantity}}</div>
          <div class="order-price">
            {{prod?.totalcost | currency : 'USD' : true : '.2-2' }}
          </div>
        </div>
        <div class="order-row sanFran w-50">
          <div>
            Additional Items: <br />
            <span *ngFor="let choice of prod?.choices">{{choice.name}}, </span><br />
          </div>
        </div>
      </div>
    </div>

    <div class="total-content sanFran">
      <div class="total-row">
        <div>Subtotal</div>
        <div>{{order?.subtotal | currency : 'USD' : true : '.2-2'}}</div>
      </div>
      <div *ngFor="let tax of order?.taxes" class="total-row">
        <div>{{tax.label}}</div>
        <div>{{tax.tax | currency : 'USD' : true : '.2-2'}}</div>
      </div>
      <div class="total-row" *ngIf="order?.tip">
        <div>Tip</div>
        <div>{{order.tip | currency : 'USD' : true : '.2-2'}}</div>
      </div>
      <div class="total-row" *ngIf="order?.discount">
        <div>Discount</div>
        <div>- {{order.discount | currency : 'USD' : true : '.2-2'}}</div>
      </div>

      <ng-container *ngFor="let fee of order?.fees">
        <div *ngIf="fee">
          <div class="total-row">
            <div>Service Charge</div>
            <div>{{ fee.amount | currency : 'USD' : true : '.2-2' }}</div>
          </div>
        </div>
      </ng-container>

      <div class="total-row" *ngIf="order?.customerhandoffcharge">
        <div>Delivery Fee</div>
        <div>{{order.customerhandoffcharge | currency : 'USD' : true : '.2-2'}}</div>
      </div>
      <div class="total-row total">
        <div>Total</div>
        <div>{{order?.total | currency : 'USD' : true : '.2-2'}}</div>
      </div>
    </div>
  </div>

</div>
