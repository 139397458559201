import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError, map, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {OloService} from 'app/providers/olo.service';
import {Category, Optiongroup, ProductModifiersResponse, RestaurantMenu} from '../models/olo.menu.interface';
import {OloError} from '../models/olo.error.interface';
import {Cacheable} from 'ts-cacheable';

@Injectable()
export class MenuService extends OloService {
  private categories: any;

  setCategories(categories) {
    this.categories = categories;
    for (let cat of categories) {
      cat.totalCost = 0;
      for (const product of cat.products) {
        cat.totalCost += product.cost;
      }
    }
    return this.categories;
  }

  getCachedCategories() {
    return this.categories;
  }

  @Cacheable()
  getMenuForRestaurant(restaurantID: string): Observable<Category[]> {
    return this.http
      .get<RestaurantMenu>(this.getCmsUrl() + '/api/restaurants/' + restaurantID + '/menu', {
        headers: this.getHeaders2()
      }).pipe(
        tap(res => this.setCategories(res.categories)),
        map(res => res.categories),
        catchError((error: any) => observableThrowError(error.error as OloError)),);
  }

  @Cacheable({
    maxCacheCount: 50
  })
  getProductModifiers(productID: string): Observable<Optiongroup[]> {
    return this.http
      .get<ProductModifiersResponse>(this.getCmsUrl() + '/api/products/' + productID + '/modifiers', {
        headers: this.getHeaders2()
      }).pipe(
        map(res => res.optiongroups),
        catchError((error: any) => observableThrowError(error.error as OloError)),);
  }

  getCategoryUrl(category) {
    return `menu/${this.toParam(category.name.trim())}`;
  }

  getProductUrl(category, product) {
    return `${this.getCategoryUrl(category)}/${this.toParam(
      product.name.trim()
    )}/${product.id}`;
  }

  getCustomProductUrl(category, product) {
    return this.getProductUrl(category, product) + '/customize';
  }

  toParam(thing: string): String {
    return thing
      .toLowerCase()
      .replace(/[^a-z0-9 \-]/g, '')
      .replace(/ +/g, '-');
  }

  getMetadataByKey(key: string, options: any[]) {
    if (options && options.length) {
      for (const option of options) {
        if (key === option.key) {
          return option.value;
        }
      }
    }
    return false;
  }
}
