<div class="header">
  <h1 class="garageGothic">REWARDS</h1>
</div>
<div class="carousel-wrap">
  <ngb-carousel *ngIf="slides && slides.length > 1 else singleSlide" class="h-100" #carousel [showNavigationArrows]="slides.length > 1" [showNavigationIndicators]="slides.length > 1">
    <ng-container *ngFor="let slide of slides, index as i">
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper h-100 w-100">
          <img [defaultImage]="slide.slide_image.data.thumbnails[6].url" [alt]="slide.before_sign_up_text"
               [lazyLoad]="slide.slide_image.data.full_url" class="responsive-img">
          <div class="overlay h-100 w-100"></div>
        </div>
        <div class="carousel-caption h-100">
          <div>
            <div class="text-center content-section" [innerHTML]="slide.slide_content"></div>
            <div [innerHTML]="slide.before_sign_up_text"></div>
            <div class="d-flex justify-content-center">
              <div class="btn-grid">
                <button (click)="signUp(true)" class="freebirds-btn slider">Create an account</button>
                <button (click)="signUp(false)" class="freebirds-btn slider">Sign In</button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ngb-carousel>
  <ng-template #singleSlide>
    <ng-container *ngIf="slides && slides.length">
      <div class="h-100 w-100">
        <img [defaultImage]="slides[0].slide_image.data.thumbnails[6].url" [alt]="slides[0].before_sign_up_text"
             [lazyLoad]="slides[0].slide_image.data.full_url" class="responsive-img">
      </div>
      <div class="overlay h-100 w-100"></div>
      <div class="h-100 w-100 single-slide-grid">
        <div class="d-grid">
          <div class="text-center content-section" [innerHTML]="slides[0].slide_content"></div>
          <div [innerHTML]="slides[0].before_sign_up_text" class="text-white"></div>
          <div class="d-flex justify-content-center">
            <div class="btn-grid">
              <button (click)="signUp(true)" class="freebirds-btn slider">Create an account</button>
              <button (click)="signUp(false)" class="freebirds-btn slider">Sign In</button>
            </div>
          </div>
          <div class="d-md-none mt-3 app-grid">
            <h2 class="text-uppercase text-center garageGothic m-0 text-white">Download Our App</h2>
            <a href="https://apps.apple.com/us/app/freebirds-restaurant/id1079328303#?platform=ipad" target="_blank" class="ml-auto"><img src="assets/img/app-store-btn.svg" alt="Download on the App Store"></a>
            <a href="https://play.google.com/store/apps/details?id=com.punchh.freebirds&hl=en_US&gl=US" target="_blank" class="mr-auto"><img src="assets/img/google-play-btn.png" alt="Get it on Google Play"></a>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>
