import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import {HttpClient} from '@angular/common/http';
import {ToastService} from './toast.service';

declare var grecaptcha;

@Injectable()
export class RecaptchaService {
  captchaChecked = false;
  captchaTries = 0;

  constructor(private http: HttpClient, private toast: ToastService) {
  }

  get captchaCompleted() {
    if (this.captchaChecked) {
      return true;
    } else {
      this.toast.warning('Please complete the captcha.');
      return false;
    }
  }

  initCaptcha(captchaid = 'g-recaptcha', rendername = 'recaptcha') {
    this.captchaChecked = false;
    this.captchaTries = 0;
    this.renderCaptcha(captchaid, rendername);
  }

  renderCaptcha(captchaid = 'g-recaptcha', rendername = 'recaptcha') {
    this.captchaTries++;

    if (this.captchaTries > 9) {
      return;
    }

    const captchaElement = document.getElementsByClassName(captchaid);
    if (captchaElement.length > 0) {
      grecaptcha.render(rendername, {
        sitekey: '6LcvmlQUAAAAABBe9NjbaCDSaAMs55DwsKgagykI',
        callback: () => {
          this.captchaCalled();
        }
      });
      return;
    }
    setTimeout(() => {
      this.renderCaptcha(captchaid, rendername);
    }, 1000);
  }

  captchaCalled() {
    this.captchaChecked = true;
  }

  validateRecaptcha() {
    const response = grecaptcha.getResponse();
    return response !== 0;
  }
}
