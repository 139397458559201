<!-- //MOBILE LOC DETAILS -->

<div class="mobile-loc-container">
  <div class="menu-button-column" (click)="toggleMobileView()">
    <div class="row loc-det">
      <div class="my-auto col-1">
        <img [ngClass]="{'open': !isMobile}" src="assets/img/menubutton.png" alt="menu toggle"/>
      </div>
      <div class="my-auto col-10 text-center text-uppercase fs-14">
        <span class="sanFran-bold" *ngIf="basket?.deliverymode === 'dispatch'">
          Delivering to:
          <br>
          <span class="sanFran">
            {{basket?.deliveryaddress?.streetaddress | truncate: 28}}
          </span>
        </span>
        <span class="sanFran-bold" *ngIf="basket?.deliverymode === 'pickup'">
          Picking up from:
          <br>
          <span class="sanFran">
            {{location?.streetaddress}}
          </span>
        </span>
        <span class="sanFran-bold" *ngIf="basket?.deliverymode === 'curbside'">
          Curbside pickup from:
          <br>
          <span class="sanFran">
            {{location?.streetaddress}}
          </span>
        </span>
        <span class="sanFran-bold" *ngIf="basket?.deliverymode === 'drivethru'">
          Mobile Pickup Lane from:
          <br>
          <span class="sanFran">
            {{location?.streetaddress}}
          </span>
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="isMobile" [@simpleFadeAnimation]="'in'" class="mobile-menu-wrapper">
    <div class="default-background current-location-container p-2 position-relative m-0 padding">
      <div class="position-top-left p-1 black" *ngIf="showBackButton && !isModal">
        <span class="text-uppercase fs-14">
          <i class="fa fa-angle-left"></i>&nbsp;
        </span>
        <a class="b-crumb black sanFran-bold fs-12" [routerLink]="backRoute">BACK</a>
      </div>
      <div class="w-100 d-flex flex-column align-items-center justify-content-center m-0 p-0 fs-14">
        <p class="m-0 p-0">{{locloc}}</p>
        <ng-container *ngIf="basket?.deliverymode === 'pickup' || basket?.deliverymode === 'curbside' || basket?.deliverymode === 'drivethru'">
          <ng-container *ngIf="location">
            <p class="m-0 p-0 fs-14 sanFran"> {{location.city}}, {{location.state}} {{location.zip}} </p>
            <span class="fs-14 sanFran-bold" *ngIf="basket?.readytime; else pickupInprogress">Pickup Time: {{friendlyDateTime(basket?.readytime)}}</span>
            <ng-template #pickupInprogress>
                <span class="fs-14 sanFran-bold">Pickup Time: {{basket?.timewanted ? friendlyDateTime(basket?.timewanted) : 'ASAP'}}</span>
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="basket?.deliverymode === 'dispatch' && basket.deliveryaddress">
          <p class="m-0 p-0">
            {{basket?.deliveryaddress?.city}}, {{basket?.deliveryaddress?.state}} {{basket?.deliveryaddress?.zipcode}}
          </p>
          <span class="fs-14 sanFran-bold" *ngIf="basket?.readytime; else deliveryInprogress">Delivery Time: {{friendlyDateTime(basket?.readytime)}}</span>
          <ng-template #deliveryInprogress>
            <span class="fs-14 sanFran-bold">Delivery Time: {{basket?.timewanted ? friendlyDateTime(basket?.timewanted) : friendlyDateTime(basket?.earliestreadytime)}}</span>
          </ng-template>
          <p class="m-0 p-0">Delivering From: {{location?.name}}</p>
        </ng-container>
      </div>
      <div *ngIf="!display_last_order" class="fs-14 sanFran-bold m-0 p-0 mt-2 text-center">
        <span>
          <ng-container *ngIf="basket?.deliverymode !== 'dispatch'">
            <a class="cursor-pointer text-underline text-nowrap" [routerLink]="['/locations']" (click)="changeLocationClicked()">Change Location</a>
            <span class="mr-1 ml-1">|</span>
          </ng-container>
          <a class="cursor-pointer text-nowrap text-underline" (click)="changeFlow(basket?.deliverymode)">
            {{basket?.deliverymode === 'dispatch'? 'Change To Pickup': 'Change To Delivery'}}
          </a>
          <br>
          <a class="cursor-pointer text-nowrap text-underline" (click)="changeTime(changeModeTo)">
            {{basket?.deliverymode === 'dispatch'? 'Change Delivery Time': 'Change Pickup Time'}}
          </a>
          <ng-container *ngIf="auth && !isModal">
                <span class="mr-1 ml-1" *ngIf="screenWidth > 365">|</span>
            <br *ngIf="screenWidth < 365" />
            <a class="cursor-pointer text-nowrap text-underline" (click)="addLocationToFavorites()">
              Add Location to Favorites
            </a>
          </ng-container>
        </span>
      </div>
    </div>
  </div>
</div>

<!-- //DESKTOP LOC DETAILS -->

<div class="desktop-loc-container">
  <div class="default-background current-location-container position-relative m-0 padding">
    <div class="position-top-left p-1 black" *ngIf="showBackButton && !isModal">
      <span class="text-uppercase">
        <i class="fa fa-angle-left"></i>&nbsp;</span>
      <a class="b-crumb black sanFran-bold" [routerLink]="backRoute">BACK</a>
    </div>
    <div class="w-100 d-flex flex-column align-items-center justify-content-center m-0 p-0 fs-14">
      <div class="type-heading text-uppercase sanFran-bold fs-20" *ngIf="basket?.deliverymode === 'dispatch'">
        Delivering To
      </div>
      <div class="type-heading text-uppercase sanFran-bold fs-20" *ngIf="basket?.deliverymode === 'pickup'">
        Picking Up From
      </div>
      <div class="type-heading text-uppercase sanFran-bold fs-20" *ngIf="basket?.deliverymode === 'curbside'">
        Curbside Pick Up From
      </div>
      <div class="type-heading text-uppercase sanFran-bold fs-20" *ngIf="basket?.deliverymode === 'drivethru'">
        Mobile Pickup Lane From
      </div>
      <p class="m-0 p-0">{{locloc}}</p>
      <ng-container *ngIf="basket?.deliverymode === 'pickup' || basket?.deliverymode === 'curbside' || basket?.deliverymode === 'drivethru'">
        <ng-container *ngIf="location">
          <p class="m-0 p-0 fs-14 sanFran"> {{location.streetaddress}} </p>
          <p class="m-0 p-0 fs-14 sanFran"> {{location.city}}, {{location.state}} {{location.zip}} </p>
          <span class="fs-14 sanFran-bold" *ngIf="basket?.readytime; else pickupInprogress">Pickup Time: {{friendlyDateTime(basket?.readytime)}}</span>
          <ng-template #pickupInprogress>
              <span class="fs-14 sanFran-bold">Pickup Time: {{basket?.timewanted ? friendlyDateTime(basket?.timewanted) : 'ASAP'}}</span>
          </ng-template>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="basket?.deliverymode === 'dispatch' && basket.deliveryaddress">
        <p class="m-0 p-0"> {{basket?.deliveryaddress?.streetaddress}}, {{basket?.deliveryaddress?.zipcode}}</p>

        <span class="fs-14 sanFran-bold" *ngIf="basket?.readytime; else deliveryInprogress">Delivery Time: {{friendlyDateTime(basket?.readytime)}}</span>
        <ng-template #deliveryInprogress>
            <span>Delivery Time: {{basket?.timewanted ? friendlyDateTime(basket?.timewanted) : friendlyDateTime(basket?.earliestreadytime)}}</span>
        </ng-template>
        <p class="fs-14 m-0 p-0">Delivering From: {{location.name}}</p>
      </ng-container>
    </div>
    <div *ngIf="!display_last_order" class="fs-14 sanFran-bold m-0 p-0 mt-1 text-center">
      <ng-container *ngIf="basket?.deliverymode !== 'dispatch'">      
      <span>
        <a class="cursor-pointer text-underline text-nowrap" [routerLink]="['/locations']"
           (click)="changeLocationClicked()">Change Location</a>
      </span>
        <span class="mr-1 ml-1">|</span>
      </ng-container>
      <span class="text-underline">
        <a class="cursor-pointer text-nowrap" (click)="changeFlow(basket?.deliverymode)">
          {{basket?.deliverymode === 'dispatch'? 'Change To Pickup': 'Change To Delivery'}}
        </a>
        <span class="mr-1 ml-1">|</span>
        <a class="cursor-pointer text-nowrap" (click)="changeTime(changeModeTo)">
          {{basket?.deliverymode === 'dispatch'? 'Change Delivery Time': 'Change Pickup Time'}}
        </a>
        <ng-container *ngIf="auth && !isModal">
          <span class="mr-1 ml-1" *ngIf="screenWidth > 414">|</span>
          <br *ngIf="screenWidth < 420" />
          <a class="cursor-pointer mx-auto mx-sm-0" (click)="addLocationToFavorites()">
            Add Location to Favorites
          </a>
        </ng-container>
      </span>
    </div>
  </div>
</div>
