import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  Validators,
  FormGroup,
  FormArray,
  FormBuilder,
  FormControl,
  ReactiveFormsModule
} from '@angular/forms';
import * as moment from 'moment';

import { Basket } from 'app/models/basket';
import { User } from 'app/models/user';
import { PaymentMethod } from 'app/models/paymentmethod';

import { CreditCardValidator } from 'app/components/angular-cc-library/index';
import { BasketService } from 'app/providers/basket.service';
import { RestaurantService } from 'app/providers/restaurant.service';
import { TwilioService } from 'app/providers/twilio.service';
import {MenuService, PunchService} from 'app/providers';
import {OloLoyaltyReward} from '../../../models/olo.loyalty-reward.interface';
import {ToastService} from '../../../providers/toast.service';
import {AnalyticsService} from '../../../providers/analytics.service';
declare const fbq: Function;

@Component({
  selector: 'app-order-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {
  basket: Basket;
  restaurant: any;
  dispatchSelected: any = false;
  userLoggedIn = false;
  auth: any;

  whenType = 'asap';
  whenType_date = '';
  whenType_dateArray = [];
  whenType_time: any = '';
  whenType_timeArray = {};

  checkoutForm: FormGroup;

  dispatch_street: any;
  dispatch_building: any;
  dispatch_city: any;
  dispatch_zip: any;
  dispatch_phone: any;

  saved_account = [];
  saved_card_desc: any;
  saved_card_exp: any;
  saved_accountid: any;

  storage = window.localStorage.getItem('keepSigned')
    ? window.localStorage
    : window.sessionStorage;

  isGroupOrder = false;
  groupOrder_deadline = '';

  checkoutClicked = false;

  rewardsPresent = false;
  QualifyingRewards: OloLoyaltyReward[] = [];
  AppliedReward: OloLoyaltyReward;

  // @ViewChild('map') map;

  constructor(
    private basketService: BasketService,
    private restaurantService: RestaurantService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private twilio: TwilioService,
    private punch: PunchService,
    private toast: ToastService,
    private analytics: AnalyticsService,
    private menu: MenuService
  ) { }

  ngOnInit() {
    this.basketService.getBasket().subscribe(
      basket => {
        this.basket = basket;
        this.menu.getMenuForRestaurant(basket.vendorid).subscribe(menu => {
          this.restaurantService.getRestaurantById(basket.vendorid).subscribe(rest => {
            this.analytics.logCheckoutReview(basket.products, menu, rest, basket.id)
          })
        })
        // window['ChepriPixelTag'].push({
        //   event: 'order_reached_review_page',
        //   basket: JSON.stringify(this.basket)
        // });
        // fbq('trackCustom', 'OrderReachedReviewPage', {
        //   basket: JSON.stringify(this.basket)
        // });
        this.getHours();
        this.basketUpdated();
      }
    );
    console.log('basketservice', this.basketService.contact_info);

    this.restaurant = JSON.parse(this.storage.getItem('restaurant'));
    this.dispatchSelected = this.storage.getItem('dispatchSelected');
    this.dispatch_street = this.storage.getItem('dispatch_streetAddress');
    this.dispatch_building = this.storage.getItem('dispatch_building');
    this.dispatch_city = this.storage.getItem('dispatch_city');
    this.dispatch_zip = this.storage.getItem('dispatch_zipCode');
    this.dispatch_phone = this.storage.getItem('dispatch_phone');
    this.checkoutForm = this.formBuilder.group({
      ccnumber: ['', [CreditCardValidator.validateCCNumber]],
      exp: ['', [CreditCardValidator.validateExpDate]],
      cvv: [
        '',
        [Validators.required, Validators.minLength(3), Validators.maxLength(4)]
      ],
      zip: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(5)]
      ],

      keepMeCheck: ['true', []],
      loyalty: ['', []],
      create_account: [false, []]
    });

    if (this.storage.getItem('auth')) {
      this.userLoggedIn = true;
      this.auth = this.storage.getItem('auth');
      this.getBillingId();
      this.loadRewards()

    }

    if (sessionStorage.getItem('group_id')) {
      this.isGroupOrder = true;

      this.basketService
        .getGroupOrder(
          sessionStorage.getItem('group_id'),
          this.storage.getItem('auth')
        )
        .subscribe(
          res => {
            this.groupOrder_deadline = this.formatOrderDeadlineDateTime(
              res.deadline
            );
          }
        );
    }
  }

  formatOrderDeadlineDateTime(datetime: string) {
    let formatDate =
      datetime.substr(4, 2) +
      '/' +
      datetime.substr(6, 2) +
      '/' +
      datetime.substr(2, 2) +
      ' ';
    const hour = parseInt(datetime.substr(9, 2), 10);
    const minute = datetime.substr(12, 2);

    if (hour < 12) {
      formatDate += hour + ':' + minute + ' AM';
    } else if (hour === 12) {
      formatDate += hour + ':' + minute + ' PM';
    } else if (hour === 24) {
      formatDate += hour - 12 + ':' + minute + ' AM';
    } else {
      formatDate += hour - 12 + ':' + minute + ' PM';
    }
    return formatDate;
  }

  basketUpdated() {
    if (this.basket.timewanted) {
      const split = this.basket.timewanted.split(' ');
      this.whenType_date = split[0];
      this.whenType_time = {
        label: this.formatTime(this.basket.timewanted),
        value: split[1]
      };
      this.whenType = 'date';
    } else {
      this.whenType_date = '';
      this.whenType_time = '';
    }
  }

  loadRewards() {
    this.basketService.getLoyaltyrewards().subscribe(res => {
      this.rewardsPresent = !!res.rewards.length
      this.QualifyingRewards = res.rewards;
      this.QualifyingRewards.forEach(reward => {
        if (reward.applied) {
          this.AppliedReward = reward
        }
      })
    })
  }

  checkout() {
    this.checkoutClicked = true;
    if (this.checkoutForm.invalid && !this.saved_accountid) {
      this.checkoutClicked = false;
      if (!this.checkoutForm.controls.ccnumber.valid) {
        this.toast.warning(
          `Please enter a valid credit card number in the checkout form.`
        );
      } else if (!this.checkoutForm.controls.exp.valid) {
        this.toast.warning(
          `Please enter a valid expiration date in the checkout form.`
        );
      } else if (!this.checkoutForm.controls.cvv.valid) {
        this.toast.warning(
          `Please fill in a valid CVV number in the checkout form.`
        );
      } else if (!this.checkoutForm.controls.zip.valid) {
        this.toast.warning(
          `Please fill in a valid ZIP code before continuing.`
        );
      }
      return;
    }
    const values = this.checkoutForm.value;
    if (!this.checkoutForm.invalid || this.saved_accountid) {
      this.basketService.validate().subscribe(
        res => {
          if (this.userLoggedIn === false) {
            this.basketService
              .checkoutGuest(
                values.ccnumber,
                values.exp,
                values.cvv,
                values.zip
              )
              .subscribe(
                data => {
                  this.storage.removeItem('AppliedReward');
                  this.storage.removeItem('appliedRewardId');

                  const customer = values; // guest checkout make "customer" from form values
                  delete customer.ccnumber;
                  delete customer.exp;
                  delete customer.cvv;

                  this.storage.setItem('customer', JSON.stringify(customer));
                  this.storage.setItem('lastorder', JSON.stringify(data));
                  this.storage.removeItem('group_id');
                  this.menu.getMenuForRestaurant(this.basket.vendorid).subscribe(menu => {
                    this.analytics.logPurchase(this.basket, menu, data);
                  })
                  this.sendOrderConfirmationSMS();
                  this.router.navigate(['/order/confirm']);
                },
                error => {
                  this.checkoutClicked = false;
                  let message = '. Please try again!';
                  if (error.message) {
                    message = ': ' + error.message;
                  }
                  this.toast.danger(
                    `There was an error submitting your order` + message
                  );
                }
              );
          } else if (this.userLoggedIn === true) {
            if (!this.saved_accountid) {
              this.basketService
                .checkoutUser(
                  values.ccnumber,
                  values.exp,
                  values.cvv,
                  values.zip,
                  values.keepMeCheck
                )
                .subscribe(
                  data => {
                    this.basketService.clearGroupOrder();
                    this.storage.removeItem('AppliedReward');
                    this.storage.removeItem('appliedRewardId');
                    const customer = values; // guest checkout make "customer" from form values
                    delete customer.ccnumber;
                    delete customer.exp;
                    delete customer.cvv;
                    this.storage.setItem('customer', JSON.stringify(customer));
                    this.storage.setItem('lastorder', JSON.stringify(data));
                    this.menu.getMenuForRestaurant(this.basket.vendorid).subscribe(menu => {
                      this.analytics.logPurchase(this.basket, menu, data);
                    })
                    this.sendOrderConfirmationSMS();
                    this.router.navigate(['/order/confirm']);
                  },
                  error => {
                    // window['ChepriPixelTag'].push({
                    //   event: 'order_submit_failed',
                    //   error: JSON.stringify(error),
                    //   basket: JSON.stringify(this.basket)
                    // });
                    // fbq('trackCustom', 'OrderSubmitFailed', {
                    //   error: JSON.stringify(error),
                    //   basket: JSON.stringify(this.basket)
                    // });
                    this.checkoutClicked = false;
                    let message = '. Please try again!';
                    if (error.message) {
                      message = ': ' + error.message;
                    }
                    this.toast.danger(
                      `There was an error submitting your order` + message
                    );
                  }
                );
            } else {
              this.basketService
                .checkoutUserwithCard(this.saved_accountid)
                .subscribe(
                  data => {
                    this.basketService.clearGroupOrder();
                    this.storage.removeItem('AppliedReward');
                    this.storage.removeItem('appliedRewardId');
                    const customer = values; // guest checkout make "customer" from form values
                    delete customer.ccnumber;
                    delete customer.exp;
                    delete customer.cvv;
                    this.storage.setItem('customer', JSON.stringify(customer));
                    this.storage.setItem('lastorder', JSON.stringify(data));
                    this.menu.getMenuForRestaurant(this.basket.vendorid).subscribe(menu => {
                      this.analytics.logPurchase(this.basket, menu, data);
                    })
                    this.sendOrderConfirmationSMS();
                    this.router.navigate(['/order/confirm']);
                  },
                  error => {
                    this.checkoutClicked = false;
                    let message = '. Please try again!';
                    if (error.message) {
                      message = ': ' + error.message;
                    }
                    this.toast.warning(
                      `There was an error submitting your order` + message
                    );
                  }
                );
            }
          }
        },
        err => {
          this.checkoutClicked = false;
          let message = '. Please try again!';
          if (err.message) {
            if (err.message.includes('is currently closed, please select a time, during business hours, from the drop down menu.')) {
              message = ': <br>' + 'This location is currently closed, please choose another time to place your order.';
            } else {
              message = ': ' + err.message;
            }
          }
          this.toast.danger('Something is wrong' + message);
        }
      );
    }
  }

  changeLocation() {
    this.storage.setItem('checkoutEditLocation', 'true');
    this.router.navigate(['/locations']);
  }

  formatDate(date, format: string = 'MM/DD - dddd') {
    return moment(date).format(format);
  }

  formatTime(datetime: string, format = 'h:mm a') {
    return moment(datetime, 'YYYYMMDD HH:mm').format(format);
  }

  intervals(startString, endString) {
    const start = moment(startString, 'YYYYMMDD hh:mm');
    const end = moment(endString, 'YYYYMMDD hh:mm');

    // Round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
    // Note that 59 will round up to 60, and moment.js handles that correctly
    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    const result = [];
    const current = moment(start);
    while (current <= end) {
      result.push({
        value: current.format('HH:mm'),
        label: current.format('h:mm a')
      });
      current.add(15, 'minutes');
    }

    return result;
  }

  inputScroll() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  getHours() {
    this.restaurantService.getRestaurantHours(this.restaurant.id).subscribe(
      calendars => {
        this.whenType_dateArray = [];
        const ranges = calendars[0].ranges;
        const makeHours = {};
        ranges.forEach(obj => {
          let start = obj.start;
          const end = obj.end;
          const date = start.split(' ')[0];
          this.whenType_dateArray.push(date);
          const earlist = moment(
            this.basket.earliestreadytime,
            'YYYYMMDD HH:mm'
          );
          if (moment(start, 'YYYYMMDD hh:mm') < earlist) {
            start = earlist;
          }
          makeHours[date] = [];
          makeHours[date] = this.intervals(start, end);
        });
        this.whenType_timeArray = makeHours;
      }
    );
  }

  // Set the Basket TimeWanted Value
  setBasketTime() {
    if (this.whenType === 'asap') {
      this.basketService.setTimeWanted(null).subscribe((data: Basket) => {
        this.basket = data;
        this.basketUpdated();
      });
    } else {
      const dateTime = this.whenType_date + ' ' + this.whenType_time['value'];
      this.basketService.setTimeWanted(dateTime).subscribe(
        (data: Basket) => {
          this.basket = data;
          this.basketUpdated();
        },
        error => {
          this.whenType_time = '';
          this.getHours();
        }
      );
    }
  }

  // Set the whentype value
  setWhenType(value) {
    this.whenType = value;
    this.whenType_date = '';
    this.whenType_time = '';

    this.setBasketTime();
  }

  // Set the whenType Selected Date value
  setWhenTypeDate(date) {
    this.whenType_date = date;
  }

  // Set the whenType Selected Time value
  setWhenTypeTime(time) {
    this.whenType_time = time;
    this.setBasketTime();
  }

  getBillingId() {
    this.basketService.getBillingschemes().subscribe(
      res => {
        for (let i = 0; i < res.billingschemes.length; i++) {
          if (res.billingschemes[i].type === 'creditcard') {
            this.saved_account.push(res.billingschemes[i].accounts);
          }
        }

        if (this.saved_account.length && this.saved_account[0].length) {
          this.saved_card_desc = this.saved_account[0][0].description;
        } else {
          this.saved_card_desc = 'N/A';
        }
      }
    );
  }
  selectAccount(event) {
    const accountid = event.target.value;
    if (this.saved_account[0]) {
      this.saved_accountid = accountid;
    }
  }
  removeAccount() {
    this.saved_accountid = null;
  }

  applyRewards(ref: string) {
    this.basketService.applyRewards(ref).subscribe(res => {
      this.basket = res
      this.loadRewards();
    })
  }

  deleteReward(id: number) {
    this.basketService.deleteRewards(String(id)).subscribe((basket: Basket) => {
      this.basket = basket;
      this.loadRewards();
    })
  }

  private sendOrderConfirmationSMS() {
    this.punch.getUserInfo().subscribe(res => {
      if (res.sms_subscription && res.phone) {
        this.basketService.getBasket().subscribe(basket => {
          this.twilio.sendSMS(res.phone, this.getOrderConfirmationMessage(basket)).subscribe(() => { });
        });
      }
    });
  }

  private getOrderConfirmationMessage(basket: any): string {
    return `Thanks for your order! It will be ready at ${String(basket.earliestreadytime).split(' ')[1]}`;
  }
}
