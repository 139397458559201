<div class="container-fluid container-main-background">
    <div class="row title">
        <div class="col">
            <h1 class="text-uppercase text-center">Would you<br>like to add?</h1>
        </div>
    </div>
    <div class="main-wrapper">
        <div class="d-flex flex-wrap m-auto cat-items" *ngIf="upsellItems">
            <div class="cat-item" *ngFor="let item of upsellItems">
                <div class="cat-image" (click)="select(item)">
                    <div class="col">
                        <button class="btn btn-primary food-button">
                        <img [src]="menu_images[item.name]?.img" onerror="this.src='/assets/img/Default Image.png';" class="round-circle d-flex justify-content-center align-items-center m-auto"
                            width="75px" height="75px" *ngIf="!selected[item.name]" [alt]="item.name">
                        <img src="/assets/img/Selected Item.png"
                            onerror="this.src='/assets/img/Default Image.png';"
                            class="round-circle d-flex justify-content-center align-items-center m-auto"
                            width="75px" height="75px" *ngIf="selected[item.name]" [alt]="item.name">
                        </button>
                    </div>
                </div>
                <h5 class="cat-title text-uppercase d-flex justify-content-center">{{item.name}}</h5>
                <h5 class="cat-price text-uppercase">{{item.cost}}</h5>
            </div>
        </div>
        <div class="no-thanks" (click)="goToCheckout()">
            <h4>NO THANKS</h4>
            <i class="fa fa-angle-right arrow-style"></i>
        </div>
    </div>
</div>
<img [src]="globalImages?.drinks_img" class="line_art line_art1" alt="Drinks"><img [src]="globalImages?.chips_n_dip_img" class="line_art line_art2" alt="Chips and Dip">
