
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router, Route } from '@angular/router';
import * as moment from 'moment';

import { Basket } from 'app/models/basket';
import { BasketService } from 'app/providers/basket.service';
import { PunchService } from 'app/providers/punch.service';
import { RatingService } from '../../../providers/rating.service';
import {ToastService} from '../../../providers/toast.service';
import {Order} from '../../../models/olo.order';
import {MessageHandler, MessageHandlerData} from '../../../models/message-handler-data';
import {RestaurantService} from '../../../providers';
import {data} from 'jquery';

declare let fbq: Function;

declare let ReactNativeWebView: MessageHandler;

@Component({
  selector: 'app-order-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  basket: Basket;
  restaurant: any;
  order: Order;
  orderID: string;
  customer: any;
  dispatchSelected: any = false;
  auth: any = false;
  first_name: any;
  dispatch_street: any;
  dispatch_building: any;
  dispatch_city: any;
  dispatch_zip: any;
  dispatch_phone: any;
  punchhMobile = window.localStorage.getItem('punchhMobile')

  storage = window.localStorage.getItem('keepSigned')
    ? window.localStorage
    : window.sessionStorage;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private basketService: BasketService,
    private Punch: PunchService,
    private rating: RatingService,
  ) {}

  ngOnInit() {
    // setTimeout(() => {

    window.scrollTo(0, 0);
    // this.showRating();
    // }, 1500);
    this.basketService.clearBasket();
    this.restaurant = JSON.parse(this.storage.getItem('restaurant'));
    this.order = JSON.parse(this.storage.getItem('lastorder'));
    // window['ChepriPixelTag'].push({
    //   'event': 'order_complete_success',
    //   'basket': JSON.stringify(this.order)
    // });
    // fbq('trackCustom', 'OrderCompleteSuccess', {
    //   'basket': JSON.stringify(this.order)
    // });
    // fbq('track', 'Purchase', {
    //   value: this.order.total,
    //   currency: 'USD'
    // });
    this.customer = JSON.parse(this.storage.getItem('customer'));
    this.dispatchSelected = this.storage.getItem('dispatchSelected');
    this.dispatch_street = this.storage.getItem('dispatch_streetAddress');
    this.dispatch_building = this.storage.getItem('dispatch_building');
    this.dispatch_city = this.storage.getItem('dispatch_city');
    this.dispatch_zip = this.storage.getItem('dispatch_zipCode');
    this.dispatch_phone = this.storage.getItem('dispatch_phone');
    this.basketService.getOrder(this.order.id).subscribe((res: Order) => {
      this.order = res

      // populate message handler data
      const orderData: MessageHandlerData = {
        body: {
          storeNumber: this.order.vendorextref,
          orderID: this.order.oloid,
          orderData: this.order,
          vehicleInformation: {
            make: this.order.customfields.find(cf => cf.label.toLowerCase() === 'make')?.value ? this.order.customfields.find(cf => cf.label.toLowerCase() === 'make').value : null,
            model: this.order.customfields.find(cf => cf.label.toLowerCase() === 'model')?.value ? this.order.customfields.find(cf => cf.label.toLowerCase() === 'model').value : null,
            color: this.order.customfields.find(cf => cf.label.toLowerCase() === 'color')?.value ? this.order.customfields.find(cf => cf.label.toLowerCase() === 'color').value : null,
          }
        }
      }
      // get message handler
      if ((window as any).ReactNativeWebView && !!(window as any).ReactNativeWebView.postMessage) {
        (window as any).ReactNativeWebView.postMessage(JSON.stringify(orderData));
      }
    });

    if (this.storage.getItem('auth')) {
      this.auth = this.storage.getItem('auth');
      this.getUserData();
    } else {
      this.first_name = this.storage.getItem('first_name');
    }
  }

  showRating() {

    this.rating.set('Your order has been placed.<br>How was your experience?', {
      text: 'Submit',
      cb: () => {
      }
    });
  }

  formatTime(datetime: string, format = 'h:mm a') {
    return moment(datetime, 'YYYYMMDD HH:mm').format(format);
  }

  getUserData() {
    this.Punch.getUserInfo().subscribe(
      res => (this.first_name = res.first_name),
    );
  }
}
