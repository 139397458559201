import {Injectable} from '@angular/core';
import {BasketService} from './basket.service';
import {MenuService} from './menu.service';
import {RestaurantService} from './restaurant.service';
import {OrderTypeModalComponent} from '../pages/modals/order-type-modal/order-type-modal.component';
import {DeliveryModalComponent} from '../pages/modals/delivery-modal/delivery-modal.component';
import {TimeSelectionModalComponent} from '../pages/modals/time-selection-modal/time-selection-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {DineEngineCMSService} from './dine-engine-cms.service';
import {OloAddresses, OloDeliveryAddress} from '../models/olo.deliveryaddress';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {OloBasketTransferResponse} from '../models/olo.basket-transfer-response';
import {Basket} from '../models/basket';
import {ToastService} from './toast.service';
import {Restaurants} from '../models/restaurants';

@Injectable()
export class OrderMethodService {

  deliveryBodyCopy = 'Please note that Freebirds partners with 3rd Party Delivery companies for all delivery orders.\n' +
    'If you wish to have Contactless Delivery, please indicate it below in "Special Instructions"';
  isLoggedIn = false;
  geocoder: any;
  isDeliveryLoading = false;
  storage = window.localStorage.getItem('keepSigned')
    ? window.localStorage
    : window.sessionStorage;
  deliveryModal;
  restaurant;
  basket;

  constructor(
    private basketService: BasketService,
    private menuService: MenuService,
    private restaurantService: RestaurantService,
    private modalService: NgbModal,
    private router: Router,
    private cms: DineEngineCMSService,
    private toast: ToastService
  ) {
    this.geocoder = new google.maps.Geocoder();
    this.isLoggedIn = this.basketService.userLoggedIn();
    this.cms.getBranding().subscribe((res) => {
      this.deliveryBodyCopy = res.data.delivery_popup_copy;
    });
    this.basketService.getBasket().subscribe(
      basket => {
        this.basket = basket;
      },
      () => {
      }
    );
    this.basketService.onSetBasket.subscribe((basket: Basket) => {
      this.basket = basket;
    })

  }

  openOrderType(routeToCheckout?: boolean) {
    const modalRef = this.modalService.open(OrderTypeModalComponent, {windowClass: 'bs-modal-class'});
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    })
    modalRef.componentInstance.emitMethod.subscribe((method: string) => {
      switch (method) {
        case 'pickup': {
          routeToCheckout ? this.openTimeSelectionModal(JSON.parse(this.storage.getItem('restaurant')), true) : this.router.navigate(['/locations'], {queryParams: {pickupType: 'pickup'}});
          break
        }
          ;
        case 'curbside': {
          this.router.navigate(['/locations'], {queryParams: {pickupType: 'curbside'}});
          break
        }
          ;
        case 'delivery': {
          this.openDelivery(routeToCheckout);
          break
        }
          ;
        case 'drivethru': {
          this.router.navigate(['/locations'], {queryParams: {pickupType: 'drivethru'}});
          break
        }
          ;
        case 'orderonline': {
          window.open('https://catering.freebirds.com/index.cfm?fuseaction=order&action=menu-categories&channel-id=2', '_blank');
          break
        }
          ;
        case 'callus': {
          this.router.navigate(['/brochure/contact/catering']);
          break
        }
          ;
        case 'emailus': {
          this.router.navigate(['/brochure/contact/catering']);
          break
        }
          ;
      }
      modalRef.close();
    })
  }

  openDelivery(routeToCheckout?: boolean) {
    this.deliveryModal = this.modalService.open(DeliveryModalComponent, {windowClass: 'bs-modal-class'});
    this.deliveryModal.componentInstance.deliveryBodyCopy = this.deliveryBodyCopy;
    if (this.isLoggedIn) {
      this.basketService.getDeliveryAddresses().subscribe((addresses: OloAddresses) => {
        this.deliveryModal.componentInstance.deliveryAddresses = this.sortAddresses(addresses.deliveryaddresses);
      });
    }
    this.deliveryModal.componentInstance.isLoading = this.isDeliveryLoading;
    this.deliveryModal.componentInstance.emitDeliveryAddress.subscribe((selectedAddress: any) => {
      this.deliveryModal.componentInstance.isLoading = true;
      this.submitDeliveryAddress(selectedAddress, routeToCheckout)
    })
    this.deliveryModal.componentInstance.pickupEmit.subscribe(() => {
      this.router.navigate(['/locations'], {queryParams: {pickupType: 'pickup'}});
      this.deliveryModal.close();
    });
    this.deliveryModal.componentInstance.closeModal.subscribe(() => {
      this.deliveryModal.close();
    })
  }

  openTimeSelectionModal(restaurant: Restaurants, routeToCheckout?: boolean): Promise<void> {
    try {
      this.restaurant = restaurant;
      this.modalService.dismissAll()
      const modalRef = this.modalService.open(TimeSelectionModalComponent, {windowClass: 'bs-modal-class'});
      // const restaurant = JSON.parse(this.storage.getItem('restaurant'));
      modalRef.componentInstance.restaurant = restaurant;
      modalRef.componentInstance.timeMode = restaurant.iscurrentlyopen ? 'asap' : 'selecttime';
      modalRef.componentInstance.basket = this.basket;
      this.getHours(JSON.parse(this.storage.getItem('restaurant'))).subscribe(res => {
        modalRef.componentInstance.whenType_dateArray = res.whenType_dateArray;
        modalRef.componentInstance.whenType_timeArray = res.whenType_timeArray;
        modalRef.componentInstance.whenType_date = res.whenType_date;
      });
      modalRef.componentInstance.closeModal.subscribe(() => {
        modalRef.close();
      });
      modalRef.componentInstance.switchMethodEmit.subscribe(() => {
        modalRef.close();
        this.openOrderType();
      });
      modalRef.componentInstance.timeSelectEmit.subscribe((selectedTime) => {
        const timeWanted = selectedTime === 'asap' ? null : selectedTime.whenType_date + ' ' + selectedTime.whenType_time;
        this.basketService.setTimeWanted(timeWanted).subscribe(
          data => {
            modalRef.componentInstance.isLoading = false;
            this.basketService.setBasket(data);
            routeToCheckout ? this.router.navigate(['/order/details']) : this.router.navigate(['/order']);
            modalRef.close();
          },
          error => {
            this.toast.danger(error.message);
            modalRef.componentInstance.isLoading = false;
            console.log('was error', error);
          },
        );
      });
    } catch (e) {
      return Promise.reject(e)
    }
    return Promise.resolve()
  }

  submitDeliveryAddress(address: any, routeToCheckout?: boolean) {
    const fullAddress = `${address.streetaddress} ${address.building} ${address.city} ${address.zipcode}`;
    this.geocoder.geocode(
      {address: fullAddress},
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const result = results[0];
          this.restaurantService.getNearRestaurants(
            result.geometry.location.lat(),
            result.geometry.location.lng(), 50, 3
          ).subscribe(res => {
            let restaurant = null;
            res.forEach((rest: any) => {
              if (!restaurant && rest.supportsdispatch && rest.isavailable) {
                restaurant = rest;
              }
            });
            if (!restaurant) {
              this.toast.warning('We could not find any locations near you offering delivery')
              console.log('no locations');
              this.deliveryModal.componentInstance.isLoading = false;
              return;
            }
            this.restaurant = restaurant;
            this.storage.setItem('dispatch_streetAddress', address.streetaddress ? address.streetaddress : '');
            this.storage.setItem('dispatch_building', address.building ? address.building : '');
            this.storage.setItem('dispatch_city', address.city ? address.city : '');
            this.storage.setItem('dispatch_zipCode', address.zipcode ? address.zipcode : '');
            this.storage.setItem('dispatch_phoneNumber', address.phonenumber ? address.phonenumber : '');
            this.storage.setItem('dispatch_instructions', address.specialinstructions ? address.specialinstructions : '');
            const basket = sessionStorage.getItem('basket_id');
            if (!basket) {
              return this.basketService.checkCreateBasket(restaurant.id, true).subscribe(
                (basket: Basket) => {
                  this.basketService.setBasket(basket, true);
                  this.storage.setItem('restaurant_id', restaurant.id.toString());
                  this.storage.setItem('restaurant', JSON.stringify(restaurant));
                  this.setDeliveryAddress(address, routeToCheckout);
                }
              );
            } else if (restaurant) {
              const canTransferAll = this.basketService.canTransferModifiers('dispatch')
              if (canTransferAll) {
                this.basketService.transferBasket(restaurant.id).subscribe((basket: OloBasketTransferResponse) => {
                    this.basketService.setBasket(basket.basket, true);
                    this.storage.setItem('restaurant_id', restaurant.id.toString());
                    this.storage.setItem('restaurant', JSON.stringify(restaurant));
                    this.setDeliveryAddress(address, routeToCheckout)
                })
              } else {
                this.toast.danger(`The following items cannot be transferred and will be removed from your basket: ${this.basketService.unavailableModifiers.join(', ')}`)
                this.basketService.removeUnavailableModifiers('dispatch').subscribe((res => {
                  this.basketService.transferBasket(restaurant.id).subscribe((basket: OloBasketTransferResponse) => {
                      this.basketService.setBasket(basket.basket, true);
                      this.storage.setItem('restaurant_id', restaurant.id.toString());
                      this.storage.setItem('restaurant', JSON.stringify(restaurant));
                      this.setDeliveryAddress(address, routeToCheckout)
                  }, error => {
                    this.basketService.createBasket(restaurant.id).subscribe(
                      (basket: Basket) => {
                          this.basketService.setBasket(basket, true);
                          this.storage.setItem('restaurant_id', restaurant.id.toString());
                          this.storage.setItem('restaurant', JSON.stringify(restaurant));
                          this.setDeliveryAddress(address, routeToCheckout)
                      }, error => {
                        if (error && error.error.message) {
                          this.toast.danger(error.error.message);
                        } else if (error && error.message) {
                          this.toast.danger(error.message);
                        } else {
                          this.toast.danger('Error creating basket');
                        }
                      }
                    );
                  })
                }))
              }
            }
          });
        }
      }
    );
  }

  setDeliveryAddress(address: any, routeToCheckout?: boolean) {
    this.storage.setItem('last_address_saved', JSON.stringify({
      address: address.streetaddress,
      state: address.state,
      city: address.city,
      zip: address.zipcode,
      phone: address.phonenumber,
      specialinstructions: address.specialinstructions
    }));
    this.basketService.setDeliveryAddress(
      address.streetaddress,
      address.building,
      address.city,
      address.zipcode,
      address.specialinstructions,
      address.phonenumber
    ).subscribe(
      basket => {
        this.basketService.setBasket(basket);
        this.storage.setItem('dispatchSelected', 'true');
        this.deliveryModal.componentInstance.isLoading = false;
        this.deliveryModal.close();
        this.openTimeSelectionModal(this.restaurant, routeToCheckout);
      },
      (error: any) => {
        this.toast.danger(error.message)
        this.deliveryModal.componentInstance.isLoading = false;

      }
    );
  }

  getHours(restaurant = null): Observable<any> {
    if (restaurant) {
      this.restaurant = restaurant;
    }
    if (!this.restaurant) {
      return;
    }
    return this.restaurantService.getRestaurantHours(this.restaurant.id).pipe(map(
      calendars => {
        const whenType_dateArray = [];
        const ranges = calendars[0].ranges;
        const makeHours = {};
        ranges.forEach(obj => {
          let start = obj.start;
          const end = obj.end;
          const date = start.split(' ')[0];
          whenType_dateArray.push(date);
          const earlist = moment(
            this.basket.earliestreadytime,
            'YYYYMMDD HH:mm'
          );
          if (moment(start, 'YYYYMMDD hh:mm') < earlist) {
            start = earlist;
          }
          makeHours[date] = [];
          makeHours[date] = this.intervals(start, end);
        });
        const response = {
          whenType_timeArray: makeHours,
          whenType_dateArray: whenType_dateArray,
          whenType_date: this.basket.earliestreadytime.split(' ')[0]
        }
        return response;
      },
      error => {
        this.toast.danger(error.message)
        return error;
      }
    ));
  }

  intervals(startString, endString) {
    const start = moment(startString, 'YYYYMMDD hh:mm');
    const end = moment(endString, 'YYYYMMDD hh:mm');

    // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
    // note that 59 will round up to 60, and moment.js handles that correctly
    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    const result = [];
    const current = moment(start);

    while (current <= end) {
      result.push({
        value: current.format('HH:mm'),
        label: current.format('h:mm a')
      });
      current.add(15, 'minutes');
    }
    return result;
  }

  sortAddresses(addresses: OloDeliveryAddress[]) {
    const sorted = [];
    const sortedRound2 = [];
    const slugArray = [];
    addresses.forEach(address => {
      if (address.isdefault) {
        sorted.unshift(address);
      } else {
        sorted.push(address);
      }
    });
    sorted.forEach(address => {
      if (!slugArray.includes(this.slug(address.streetaddress))) {
        if (address.isdefault) {
          sortedRound2.unshift(address);
          slugArray.push(this.slug(address.streetaddress));
        } else {
          sortedRound2.push(address);
          slugArray.push(this.slug(address.streetaddress));
        }
      } else {
        const existing = sortedRound2.find(add => this.slug(add.streetaddress) === this.slug(address.streetaddress))
        if (existing && !existing.specialinstructions && address.specialinstructions) {
          sortedRound2[sortedRound2.indexOf(existing)] = address
        }
      }
    });
    return sortedRound2;
  }

  slug(name: string) {
    return name
      .toLowerCase()
      .trim()
      .replace(/[^a-z \-]/g, '')
      .replace(/ +/g, '-');
  }
}
