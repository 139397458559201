import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { BasketService } from './basket.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private basketService: BasketService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.isAuthed()) {
      return true;
    }
    this.redirect();
    return false;
  }

  isAuthed() {
    return this.basketService.userLoggedIn();
  }

  redirect() {
    this.router.navigate(['/account/sign-in'], { skipLocationChange: true });
  }
}
