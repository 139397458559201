import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { OloAddresses, OloDeliveryAddress } from '../../../models/olo.deliveryaddress';

@Component({
  selector: 'app-delivery-modal',
  templateUrl: './delivery-modal.component.html',
  styleUrls: ['./delivery-modal.component.scss']
})
export class DeliveryModalComponent {
  @Input() error;
  @Input() deliveryBodyCopy;
  @Input() deliveryAddresses = [];
  @Input() isLoading = false;
  @Output() closeModal = new EventEmitter();
  @Output() pickupEmit = new EventEmitter();
  @Output() emitDeliveryAddress = new EventEmitter();

  loaderType = 'newaddress';

  savedAddressForm: FormGroup = new FormGroup({
    address: new FormControl('')
  });

  addressForm: FormGroup = new FormGroup({
    streetAddress: new FormControl(''),
    building: new FormControl(''),
    city: new FormControl(''),
    zipCode: new FormControl(''),
    state: new FormControl(''),
    phonenumber: new FormControl(''),
    specialInstructions: new FormControl('')
  });

  constructor(public activeModal: NgbActiveModal) { }

  closeModalClicked() {
    this.closeModal.emit();
  }

  routeToLocations() {
    this.pickupEmit.emit();
  }

  newAddressSubmit() {
    this.loaderType = 'newaddress';
    let address = {
      streetaddress: this.addressForm.value.streetAddress,
      building: this.addressForm.value.building,
      city: this.addressForm.value.city,
      zipcode: this.addressForm.value.zipCode,
      phonenumber: this.addressForm.value.phonenumber,
      specialinstructions: this.addressForm.value.specialInstructions
    }
    this.emitDeliveryAddress.emit(address);
  }

  savedAddressSelected() {
    this.loaderType = 'savedaddress';
    const selectedAddress: OloDeliveryAddress = this.deliveryAddresses[Number(this.savedAddressForm.value.address)];
    this.emitDeliveryAddress.emit(selectedAddress);
  }

}
