import {Injectable} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';

const ServiceName = 'PubSub Service'

// tslint:disable:unified-signatures

@Injectable({
  providedIn: 'root'
})
/**
 * Don't use, this is for legacy purposes only. We are not sure why this was needed, but the original package is no longer maintained
 */
export class PubSubService implements IPubSubService {

  private events: { [key: string]: ReplaySubject<any> } = {};

  constructor() {
  }

  public $sub(event: string): Observable<any>;
  public $sub(event: string, callback: (value: any) => void): Subscription;
  public $sub(event: string, callback: (value: any) => void, error: (error: any) => void): Subscription;
  public $sub(event: string, callback: (value: any) => void, error: (error: any) => void, complete: () => void): Subscription;
  public $sub(event: string, callback?: (value: any) => void, error?: (error: any) => void, complete?: () => void) {
    if (!event) {
      throw new Error(`[${ServiceName}] => Subscription method must get event name.`)
    }
    if (this.events[event] === undefined) {
      this.events[event] = new ReplaySubject<any>();
    }
    if (typeof callback !== 'function') {
      return this.events[event].asObservable();
    } else {
      return this.events[event].asObservable().subscribe(callback, error, complete)
    }
  }

  public $pub(event: string, eventObject?: any) {
    if (!event) {
      throw new Error(`[${ServiceName}] => Publish method must get event name.`);
    } else if (!this.events[event]) {
      return;
    }
    this.events[event].next(eventObject);
  }
}

export interface IPubSubService {
  $pub(event: string, eventObject?: any): void;
  $sub(event: string): Observable<any>;
  $sub(event: string, callback: (value: any) => void): Subscription;
  $sub(event: string, callback: (value: any) => void, error: (error: any) => void): Subscription;
  $sub(event: string, callback: (value: any) => void, error: (error: any) => void, complete: () => void): Subscription;
}

interface I$sub {
  (event: string): Observable<any>;
  (event: string, callback: (value: any) => void): Subscription;
  (event: string, callback: (value: any) => void, error: (error: any) => void): Subscription;
  (event: string, callback: (value: any) => void, error: (error: any) => void, complete: () => void): Subscription;
}
