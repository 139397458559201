import {AfterContentInit, AfterViewInit, Component, ContentChild, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BasketService} from 'app/providers/basket.service';
import {ContrastService} from 'app/providers/contrast.service';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DineEngineCMSService, PunchService, RestaurantService} from '../../../providers';
import {environment} from '../../../../environments/environment';
import {animate, state, style, transition, trigger} from '@angular/animations';
import * as moment from 'moment';
import {Restaurants} from '../../../models/restaurants';
import {SocialAuthService, FacebookLoginProvider} from 'angularx-social-login';
import {OloAddresses, OloDeliveryAddress} from '../../../models/olo.deliveryaddress';
import {OrderMethodService} from 'app/providers/order-method.service';
import {SignInModalComponent} from 'app/pages/modals/sign-in-modal/sign-in-modal.component';
import {ForgotPasswordModalComponent} from 'app/pages/modals/forgot-password-modal/forgot-password-modal.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ToastService} from '../../../providers/toast.service';

declare var google;
declare var fbq: Function;
const MILES_PER_METER = 0.000621371;

@Component({
  selector: 'app-main-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({opacity: 0}),
        animate(800 )
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(5, style({opacity: 0})))
    ])
  ],
})
export class HeaderComponent implements OnInit, AfterContentInit {
  userLoggedIn;
  isInvitedUser = false;
  highContrast = new FormControl(false);
  isHighContrast: boolean;
  punchhMobile = window.localStorage.getItem('punchhMobile');
  isTiny = window.innerWidth <= 359;
  basketCount: any;
  basket;
  loginForm: FormGroup;
  forgotPasswordForm;
  geocoder: any;
  timemode;
  resetPasswordEmail;
  whenType_date;
  whenType;
  whenType_time;
  whenType_dateArray = [];
  whenType_timeArray = {};
  restaurant: Restaurants;
  selectedLat = 39.8333333;
  selectedLong = -98.585522;
  selectedZoom = 5;
  presentEmailWarning = false;
  selectedBounds = null;
  addressForm: FormGroup = new FormGroup({
    streetAddress: new FormControl(''),
    building: new FormControl(''),
    city: new FormControl(''),
    zipCode: new FormControl(''),
    state: new FormControl(''),
    phonenumber: new FormControl(''),
    specialInstructions: new FormControl('')
  });
  curbside = false;
  userInfo: any;
  keepSigned = true;
  frontPage = false;
  restaurants;
  nearRestaurants;
  nextStep;
  errorMessage: any;
  errorModalSwitch: string;
  nearStored = [];
  basketModal: NgbModalRef;
  signInSignUpModal: NgbModalRef;

  isPickup = false;
  isPickupTime: null;
  dispatchSelected = false;

  loader: boolean = false;

  deliveryAddresses: OloDeliveryAddress[];

  @ViewChild('modalContent', { static: true })
  modalContent: TemplateRef<any>;

  @ViewChild('errorMessageModal', { static: true })
  errorMessageModal: TemplateRef<any>;

  @ViewChild('signInForm', { static: true })
  signInForm: TemplateRef<any>;

  // @ViewChild('forgotPassword')
  // forgotPassword: TemplateRef<any>;

  @ViewChild('signUpForm', { static: true })
  signUpForm: TemplateRef<any>;

  @ContentChild('signUpForm', /* TODO: add static flag */ {})
  signupRadio: ElementRef;

  @ViewChild('asapRadioButton')
  asapRadioButton: ElementRef;

  signInHeader = 'Sign in & Earn Rewards';

  signUpFormController;

  signUpContent: any;

  showMobileMenu = false;

  screenWidth = window.innerWidth;

  storage = window.localStorage.getItem('keepSigned')
    ? window.localStorage
    : window.sessionStorage;

  // tslint:disable-next-line:max-line-length
  deliveryBodyCopy = 'Please note that Freebirds partners with 3rd Party Delivery companies for all delivery orders.\n' +
    'If you wish to have Contactless Delivery, please indicate it below in "Special Instructions"';

  constructor(
    private basketService: BasketService,
    private router: Router,
    private contrast: ContrastService,
    private formBuilder: FormBuilder,
    private Punch: PunchService,
    protected restaurantService: RestaurantService,
    protected socialAuthService: SocialAuthService,
    protected toast: ToastService,
    private cms: DineEngineCMSService,
    private route: ActivatedRoute,
    private orderMethod: OrderMethodService,
    private modalService: NgbModal,
    ) {
    this.basketCount = this.basketService.basketCount;
    router.events.subscribe((val: any) => {
      if (val && val.url !== undefined) {
        this.frontPage = val.url === '/' || val.url === '' || val.url === '/order-online';
      }
    });
    this.route.queryParams.subscribe((queryParams:any) => {
      if (queryParams['signin'] && queryParams['signin'] === 'true') {
        this.openSignUp();
      }
    });
  }

  toggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
  }

  inputScroll() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  ngAfterContentInit(): void {
    this.updateRestaurant();
  }

  ngOnInit() {
    this.signUpContent = this.signInForm;
    this.basketService.getBasket().subscribe(
      basket => {
        this.isPickup = basket.deliverymode === 'pickup' ? true : false;
        this.basket = basket;
        const restaurant = JSON.parse(this.storage.getItem('restaurant'));
        this.basketService.setBasket(this.basket);
        this.basketUpdated();
      },
      () => {}
    );
    this.initialContrast();
    this.userLoggedIn = this.basketService.userLoggedIn();
    this.contrastToggle();
    this.loginForm = this.validateFields();
    this.validateSignUpFields();
      this.geocoder = new google.maps.Geocoder();
    this.basketService.onSetInvitedUser.subscribe(
      isInvited => (this.isInvitedUser = isInvited)
    );
    this.getDeliveryCopy();
    this.basketService.onSetBasket.subscribe(res => {
      this.basket = res
      this.isPickup = res.deliverymode === 'pickup';
    })
  }

  openBasket(basketModal) {
    this.basketModal = this.modalService.open(basketModal, { windowClass: 'bs-basket-modal' });
  }

  orderNowClicked() {
    // this.orderMethod.openOrderType();
    this.menuClick()
  }

  updateRestaurant() {
    setInterval(() => {
      this.restaurant = JSON.parse(this.storage.getItem('restaurant'));
    }, 500);
  }

  validateFields() {
    const form = this.formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
          )
        ])
      ],
      phonenumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/
          )
        ])
      ],
      password: [
        '',
        Validators.compose([Validators.required, Validators.pattern(/^(.*)$/)])
      ]
    });
    return form;
  }

  validateSignUpFields() {
    this.signUpFormController = this.formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.pattern(
            /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
          )
        ])
      ],
      phonenumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/
          )
        ])
      ],
      password: ['', Validators.compose([Validators.pattern(/^(.*)$/)])],
      password2: ['', Validators.compose([Validators.pattern(/^(.*)$/)])],
      first_name: ['', Validators.compose([Validators.pattern(/^(.*)$/)])],
      last_name: ['', Validators.compose([Validators.pattern(/^(.*)$/)])],
      terms: ['false', Validators.required],
      privacy: ['true', Validators.required],
      smsCheck: ['true']
    });
  }


  contrastToggle() {
    this.highContrast.valueChanges.subscribe(value => {
      if (value) {
        this.contrast.toggleHighContrast();
      } else {
        this.contrast.toggleStandardContrast();
      }
    });
  }

  initialContrast() {
    if (window.localStorage.getItem('highContrast') === 'true') {
      this.contrast.toggleHighContrast();
      this.isHighContrast = true;
    } else {
      this.contrast.toggleStandardContrast();
    }
  }

  logout() {
    this.userLoggedIn = false;
    this.deleteAllCookies();
    window.localStorage.clear();
    window.sessionStorage.clear();
    this.basketService.userLoggedIn();
    this.deleteAllCookies();
    window.location.href = '/';
    // this.router.navigate(['/']);
  }

  deleteAllCookies() {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

  closeCartModal() {
    this.basketModal.close();
  }

  logoclicked() {
    if (!this.isInvitedUser) {
      this.router.navigate(['/']);
    }
  }

  cateringClicked() {
    this.storage.setItem('cateringClicked', 'true');
    this.router.navigate(['/order-line']);
  }

  openSignUp() {
    this.signInSignUpModal = this.modalService.open(SignInModalComponent, { windowClass: 'bs-modal-class' });
    this.signInSignUpModal.componentInstance.closeModal.subscribe(() => {
      this.signInSignUpModal.close();
    })
    this.signInSignUpModal.componentInstance.signInEmit.subscribe(res => {
      this.keepSigned = this.signInSignUpModal.componentInstance.keepSigned
      this.submit(res);
    })
    this.signInSignUpModal.componentInstance.facebookEmit.subscribe(() => {
      this.facebookLogin();
    })
    this.signInSignUpModal.componentInstance.createAccountEmit.subscribe(res => {
      this.submitSignUp(res);
    })
    this.signInSignUpModal.componentInstance.forgotPasswordEmit.subscribe(() => {
      this.signInSignUpModal.close();
      this.submitForgotPassword();
    })
  }

  openForgotPassword(modal) {
    this.switchView(modal);
  }

  onClick(event) {
    event.stopImmediatePropagation();
    const checked = (!event.currentTarget.checked);
    event.currentTarget.checked = (checked) ? false : checked.toString();
  }

  signInSelect(event) {
    event.stopImmediatePropagation();
    const checked = (!event.currentTarget.checked);
    event.currentTarget.checked = (checked) ? false : checked.toString();
    const value = event.currentTarget.value;
    this.signUpContent = null;
    this.storage.setItem('sign_in_url', '');
    if (value === 'signin') {
      this.signInHeader = 'SIGN IN & EARN REWARDS';
      this.signUpContent = this.signInForm;
    } else {
      this.signInHeader = 'Create an account & get rewards';
      this.signUpContent = this.signUpForm;
    }
  }

  signUpClicked() {
    this.userInfo = this.signUpFormController.value;
    this.userInfo.privacy = this.userInfo.terms;
    const control = this.signUpFormController.controls;
    this.errorModalSwitch = 'modalContent';
    if (control.email.status === 'INVALID') {
      this.errorMessage = 'Please input valid email address.';
      this.switchView(this.errorMessageModal);
      return;
    }
    if (control.phonenumber.status === 'INVALID') {
      this.errorMessage = 'Please input valid phone number.';
      this.switchView(this.errorMessageModal);
      return;
    }

    if (this.userInfo.terms === false) {
      this.errorMessage = 'Please accept terms and conditions.';
      this.switchView(this.errorMessageModal);
      return;
    }
    if (this.userInfo.privacy === false) {
      this.errorMessage = 'Please accept our privacy policy.';
      this.switchView(this.errorMessageModal);
      return;
    }
    if (this.userInfo.password === this.userInfo.password2) {
      this.submitSignUp(this.userInfo);
    } else {
      this.errorMessage = 'Passwords do not match.';
      this.switchView(this.errorMessageModal);
    }
  }

  menuClick() {
    if (this.basket) {
      this.router.navigate(['order'])
    } else {
      this.router.navigate(['menu'])
    }
  }

  switchView(modalContent) {
  }

  onSubmit() {
    this.signInClicked();
  }

  signInClicked() {
    this.userInfo = this.loginForm.value;
    this.submit(this.userInfo);
  }

  submitForgotPassword() {
    const modalRef = this.modalService.open(ForgotPasswordModalComponent, { windowClass: 'bs-modal-class' });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    })
    modalRef.componentInstance.resetPasswordEmit.subscribe(res => {
      // tslint:disable-next-line:max-line-length
    if (/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(res)) {
      this.presentEmailWarning = false;
      const form = {
        client: environment.punchClientId,
        user: {
          email: res
        }
      };
      this.Punch.forgotPassword(JSON.stringify(form)).subscribe(
        () => {
          //this.switchView(nextPage);
          modalRef.close()
        },
        () => {
          // SHOW ERROR
          this.presentEmailWarning = true;
        }
      );
    } else {
      this.presentEmailWarning = true;
      // SHOW ERROR
    }
    })
  }

  dismissModal() {
  }

  basketUpdated() {
    if (this.basket.timewanted) {
      const split = this.basket.timewanted.split(' ');
      this.whenType_date = split[0];
      this.whenType_time = {
        label: this.formatTime(this.basket.timewanted),
        value: split[1]
      };
      this.whenType = 'date';
    } else {
      this.whenType_date = '';
      this.whenType_time = '';
    }
  }

  formatTime(datetime: string, format = 'h:mm a') {
    return moment(datetime, 'YYYYMMDD HH:mm').format(format);
  }

  formatDate(date, format: string = 'MM/DD - dddd') {
    return moment(date).format(format);
  }

  getErrorMessage() {
    return this.errorMessage;
  }

  submitSignUp(data) {
    let PunchhToken;
    let PunchUserId;
    window.sessionStorage.removeItem('PunchhToken');
    window.sessionStorage.removeItem('PunchAuthToken');
    window.sessionStorage.removeItem('auth');

    const form = {
      client: environment.punchClientId,
      user: {
        email: data.email,
        password: data.password,
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.phonenumber,
        send_compliance_sms: data.smsCheck
      }
    };
    const form1 = JSON.stringify(form);
    this.Punch.signup(form1).subscribe(
      res => {
        PunchhToken = res.access_token;
        PunchUserId = res.user_id;
        window.sessionStorage.setItem(
          'PunchAuthToken',
          res.authentication_token
        );
        window.sessionStorage.setItem('PunchhToken', PunchhToken);
        this.deleteAllCookies();
        window.sessionStorage.setItem('email', data.email);
      },
      err => {
        this.toast.danger('There was an issue creating your account. Please check your info and try again.');
        this.signInSignUpModal.componentInstance.isLoading = false;
      },
      () => {
        const oloBody = {
          provider: 'punchh',
          providertoken: PunchhToken,
          // contactnumber: data.phonenumber,
          basketid: '',
          authtoken: '',
          provideruserid: PunchUserId
        };
        this.Punch.getOloToken(oloBody).subscribe(
          res => {
            if (res) {
              window.sessionStorage.setItem('auth', res.authtoken);
            }
            window.location.href = '/account';
            this.signInSignUpModal.componentInstance.isLoading = false;
          },
          error => {
            this.toast.danger(error.message);
            this.signInSignUpModal.componentInstance.isLoading = false;
          }
        );
      }
    );
  }

  // This is used to LOGIN
  submit(data) {
    const groupHost = this.storage.getItem('groupHost');
    window.sessionStorage.removeItem('PunchhToken');
    window.sessionStorage.removeItem('PunchAuthToken');
    window.localStorage.removeItem('PunchhToken');
    window.localStorage.removeItem('PunchAuthToken');
    let PunchhToken;
    let PunchUserId;
    const form = {
      client: environment.punchClientId,
      user: { email: data.email, password: data.password }
    };
    const form1 = JSON.stringify(form);
    this.Punch.signin(form1).subscribe(
      res => {
        PunchhToken = res.access_token;
        PunchUserId = res.user_id;
        const storage = this.keepSigned
          ? window.localStorage
          : window.sessionStorage;
        if (this.keepSigned) {
          window.localStorage.setItem('keepSigned', 'true');

          Object.keys(window.sessionStorage).forEach(key => {
            window.localStorage.setItem(key, sessionStorage.getItem(key));
          });
          window.sessionStorage.clear();
        }
        storage.setItem('PunchAuthToken', res.authentication_token);
        storage.setItem('PunchhToken', PunchhToken);

        this.doOlo(PunchhToken, PunchUserId, groupHost);
      },
      err => {
        if (err.error) {
          console.log(err)
          this.errorMessage = err.error.error;
          this.toast.danger(this.errorMessage);
          this.signInSignUpModal.componentInstance.isLoading = false;
        }
      }
    );
  }

  switchFromError() {
    this.switchView(this.modalContent)
  }

  doOlo(PunchhToken, PunchUserId, groupHost?, newPage?) {
    const storage = this.keepSigned
      ? window.localStorage
      : window.sessionStorage;
    const oloBody = {
      provider: 'punchh',
      providertoken: PunchhToken,
      contactnumber: '',
      basketid: '',
      authtoken: '',
      provideruserid: PunchUserId
    };
    this.Punch.getOloToken(oloBody).subscribe(
      res => {
        if (res) {
          storage.setItem('auth', res.authtoken);
        }
        const sign_in_url = this.storage.getItem('sign_in_url');
        if (sign_in_url) {
          this.storage.setItem('sign_in_url', '');
          newPage = sign_in_url;
        }
        if (groupHost === 'true') {
          const groupid = sessionStorage.getItem('group_id');
          this.basketService.getGroupOrder(groupid, res.authtoken).subscribe(() => {
              this.router.navigate(['/order/details']);
            },
            () => {
              const sign_in_url = this.storage.getItem('sign_in_url');
              if (sign_in_url) {
                window.location.href = sign_in_url;
                return;
              }
              window.location.href = '/account/loyalty';
            }
          );
          return;
        }
        if (newPage) {
          window.location.href = newPage;
        } else {
          window.location.href = '/account/loyalty';
          this.showMobileMenu = false;
        }
        this.signInSignUpModal.componentInstance.isLoading = false;
      },
      error => {
        this.toast.danger(error.message)
        this.signInSignUpModal.componentInstance.isLoading = false;
      }
    );
  }

  facebookLogin() {
    const socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    this.socialAuthService.signIn(socialPlatformProvider).then(userData => {
      // this will return user data from facebook. What you need is a user token which you will send it to the server
      // this.sendToRestApiMethod(userData.token);
      const form = {
        client: environment.punchClientId,
        email: userData.email,
        fb_uid: userData.id,
        access_token: userData.authToken
      };
      const form1 = JSON.stringify(form);
      window.sessionStorage.removeItem('PunchhToken');
      window.sessionStorage.removeItem('PunchAuthToken');
      window.sessionStorage.removeItem('auth');
      window.localStorage.removeItem('PunchhToken');
      window.localStorage.removeItem('PunchAuthToken');
      window.localStorage.removeItem('auth');
      let PunchhToken;
      let PunchUserId;

      const storage = this.keepSigned
        ? window.localStorage
        : window.sessionStorage;
      this.Punch.facebookSignin(form1).subscribe(res => {
          PunchhToken = res.access_token;
          PunchUserId = res.user_id;

          if (this.keepSigned) {
            window.localStorage.setItem('keepSigned', 'true');

            Object.keys(window.sessionStorage).forEach(key => {
              window.localStorage.setItem(key, sessionStorage.getItem(key));
            });
            window.sessionStorage.clear();
          }

          storage.setItem('PunchAuthToken', res.authentication_token);
          storage.setItem('PunchhToken', PunchhToken);
          const oloBody = {
            provider: 'punchh',
            providertoken: PunchhToken,
            contactnumber: '',
            basketid: '',
            authtoken: '',
            provideruserid: PunchUserId
          };
          this.Punch.getOloToken(oloBody).subscribe(res => {
              if (res) {
                storage.setItem('auth', res.authtoken);
              }
              window.location.href = '/account';
              // this.router.navigate(['/account']);
            }
          );
        },
        error => {
          if (error.error) {
            this.toast.danger(error.error);
          } else {
            this.toast.danger(error.message);
          }
        }
      );
    });
  }


  updateMilesAway() {
    const center = new google.maps.LatLng(this.selectedLat, this.selectedLong);
    for (const restaurant of this.restaurants) {
      //   restaurant.milesAway = 5;
      restaurant.milesAway =
        MILES_PER_METER *
        google.maps.geometry.spherical.computeDistanceBetween(
          center,
          restaurant.latLng
        );
    }
  }

  termsLinkClicked() {
    this.router.navigateByUrl('/brochure/terms/service');
  }

  slug(name: string) {
    return name
      .toLowerCase()
      .trim()
      .replace(/[^a-z \-]/g, '')
      .replace(/ +/g, '-');
  }

  getDeliveryCopy() {
    this.cms.getBranding().subscribe((res) => {
      this.deliveryBodyCopy = res.data.delivery_popup_copy;
    });
  }

  isLoggedIn() {
    this.userLoggedIn = this.basketService.userLoggedIn();
    return this.userLoggedIn;
  }

}
