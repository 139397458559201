import { Component, Input, Output, EventEmitter, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-sign-in-modal',
  templateUrl: './sign-in-modal.component.html',
  styleUrls: ['./sign-in-modal.component.scss']
})
export class SignInModalComponent implements OnInit {
  @Input() error;
  @Input() restaurant;
  @Input() basket;
  @Input() signInHeader = 'SIGN IN & EARN REWARDS';
  @Input() isLoading = false;
  @Output() closeModal = new EventEmitter();
  @Output() signInEmit = new EventEmitter();
  @Output() createAccountEmit = new EventEmitter();
  @Output() facebookEmit = new EventEmitter();
  @Output() forgotPasswordEmit = new EventEmitter();

  signUpContent;
  keepSigned = true;

  @ViewChild('signInForm', { static: true })
  signInForm: TemplateRef<any>;

  @ViewChild('signUpForm', { static: true })
  signUpForm: TemplateRef<any>;

  loginForm: FormGroup = new FormGroup({
    email: new FormControl(''),
    password: new FormControl('')
  });

  signUpFormController: FormGroup = new FormGroup({
    first_name: new FormControl('', {
      validators: Validators.compose([Validators.pattern(/^(.*)$/)])
    }),
    last_name: new FormControl('', {
      validators: Validators.compose([Validators.pattern(/^(.*)$/)])
    }),
    email: new FormControl('', {
      validators: Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/)
    }),
    phonenumber: new FormControl('', {
      validators: Validators.pattern(/^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/)
    }),
    password: new FormControl('', {
      validators: Validators.compose([Validators.pattern(/^(.*)$/)])
    }),
    password2: new FormControl('', {
      validators: Validators.compose([Validators.pattern(/^(.*)$/)])
    }),
    terms: new FormControl('false', {validators: Validators.required}),
    privacy: new FormControl('true', {validators: Validators.required}),
    smsCheck: new FormControl('true'),
  });

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.signUpContent = this.signInForm;
  }

  closeModalClicked() {
    this.closeModal.emit();
  }

  inputScroll() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  openForgotPassword() {
    this.forgotPasswordEmit.emit()
  }

  onClick(event) {
    event.stopImmediatePropagation();
    const checked = (!event.currentTarget.checked);
    event.currentTarget.checked = (checked) ? false : checked.toString();
  }

  signInSelect(event) {
    event.stopImmediatePropagation();
    const checked = (!event.currentTarget.checked);
    event.currentTarget.checked = (checked) ? false : checked.toString();
    const value = event.currentTarget.value;
    //this.signUpContent = null;
    //this.storage.setItem('sign_in_url', '');
    if (value === 'signin') {
      this.signInHeader = 'SIGN IN & EARN REWARDS';
      this.signUpContent = this.signInForm;
    } else {
      this.signInHeader = 'Create an account & get rewards';
      this.signUpContent = this.signUpForm;
    }
  }

  onSubmit() {
    this.isLoading = true;
    const userInfo = this.loginForm.value;
    this.signInEmit.emit(userInfo);
  }

  signUpClicked() {
    this.isLoading = true;
    const userInfo = this.signUpFormController.value;
    this.createAccountEmit.emit(userInfo);
  }

  facebookLogin() {
    this.facebookEmit.emit();
  }

  termsLinkClicked() {
    
  }


}
