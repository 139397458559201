import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {RestaurantService} from '../../../providers';

@Component({
  selector: 'app-order-type-modal',
  templateUrl: './order-type-modal.component.html',
  styleUrls: ['./order-type-modal.component.scss']
})
export class OrderTypeModalComponent implements OnInit{
  showDriveThru = false;
  showCurbside = false;
  showPickup = false;
  showDelivery = false;
  checkDone = false;
  @Input() error;
  @Input() restaurant;
  @Output() closeModal = new EventEmitter();
  @Output() emitMethod = new EventEmitter();

  constructor(public activeModal: NgbActiveModal, private restaurants: RestaurantService) { }

  ngOnInit() {
    this.restaurants.getRestaurants(false).subscribe(restaurants => {
      this.showDriveThru = restaurants.some(rest => rest.supportsdrivethru && rest.isavailable)
      this.showCurbside = restaurants.some(rest => rest.supportscurbside && rest.isavailable)
      this.showPickup = restaurants.some(rest => rest.canpickup && rest.isavailable)
      this.showDelivery = restaurants.some(rest => rest.supportsdispatch && rest.isavailable)
      this.checkDone = true
    })
  }

  closeModalClicked() {
    this.closeModal.emit();
  }

  methodClicked(method: string) {
    this.emitMethod.emit(method);
  }

}
