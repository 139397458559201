<div [ngClass]="{'disabled-item' : unavailableWarnings && unavailableWarnings?.status === 'disabled'}">
<div class="option-image-checkbox " *ngIf="!isTiny" (click)="toggle()">
  <input #check [type]="type" [name]="name" [attr.name]="name" [value]="value" [attr.data-value]="value"
    [checked]="checked" [(ngModel)]="checked" (change)="inputChanged()" class="option-input" />
  <a *ngIf="!groupName?.includes('Burrito Size')" class="option-states-container">
    <div class="option-states">
      <div class="premium" *ngIf="premium">Premium</div>
      <button class="btn btn-primary" [class.food-button]="!showFullImage" [class.size-button]="showFullImage">
        <div *ngIf="(choice && (choice.primary == title || choice.secondary == title)); else showImage;">
          <img lazyLoad="/assets/img/{{choice.image}}.png" defaultImage="/assets/img/Default Image.png"
            class="round-circle d-flex justify-content-center align-items-center m-auto"
            *ngIf="choice && (choice.primary == title || choice.secondary == title)" [alt]="title"
            [class.selected]="checked">
            <div *ngIf="menuEmoji && menuEmoji.data" [class.select-check]="!checked">
              <img class="check-mark" [src]="menuEmoji.data.full_url" alt="Option Selected">
            </div>
        </div>
        <ng-template #showImage>
          <img [lazyLoad]="image"
            defaultImage="/assets/img/Default Image.png"
            class="round-circle d-flex justify-content-center align-items-center m-auto fade-in" *ngIf="image != ''"
            [alt]="title" [class.selected]="checked">
            <div *ngIf="menuEmoji && menuEmoji.data" [class.select-check]="!checked">
              <img [class.check-mark]="!showFullImage" [class.full-check-mark]="showFullImage"
                   [src]="menuEmoji.data.full_url" alt="Option Selected">
            </div>
            <img src="/assets/img/Default Image.png" onerror="this.src='/assets/img/Default Image.png';"
            class="round-circle d-flex justify-content-center align-items-center m-auto" *ngIf="image == ''"
            alt="Freebirds Logo" [class.selected]="checked">
        </ng-template>
      </button>
    </div>
  </a>
  <div *ngIf="!groupName?.includes('Burrito Size')" class="option-label d-flex flex-column align-items-center">
    <h5 class="cat-title text-uppercase d-flex justify-content-center" *ngIf="title">{{ title | capitalize }}</h5>
    <ng-container *ngIf="!unavailableHandoffModes.includes(this.basket?.deliverymode); else notCurrentlyAvailable">
      <h6 class="cat-price text-uppercase" *ngIf="subtitle">{{ subtitle | capitalize }}</h6>
    </ng-container>
    <ng-template #notCurrentlyAvailable>
      <h5 class="cat-unavailable text-uppercase">Unavailable for {{ this.basket.deliverymode | handoffMode }}</h5>
    </ng-template>
    <div *ngIf="heatLevel > 0" class="d-flex justify-content-center heat-index">
      <img src="assets/img/heat-index-pepper.svg" *ngIf="heatLevel >= 1" class="mx-1" alt="Heat Level Indicator" />
      <img src="assets/img/heat-index-pepper.svg" *ngIf="heatLevel >= 2" class="mx-1" alt="Heat Level Indicator" />
      <img src="assets/img/heat-index-pepper.svg" *ngIf="heatLevel >= 3" class="mx-1" alt="Heat Level Indicator" />
    </div>
  </div>

  <a *ngIf="groupName?.includes('Burrito Size')" class="burrito-option-states-container">
    <div class="burrito-option-states">
      <button class="btn btn-primary burrito-button">
        <div *ngIf="(choice && (choice.primary == title || choice.secondary == title)); else showImage;">
          <img lazyLoad="/assets/img/{{choice.image}}.png" defaultImage="/assets/img/Default Image.png"
            class="round-circle d-flex justify-content-center align-items-center m-auto"
            *ngIf="choice && (choice.primary == title || choice.secondary == title)" [alt]="title"
            [class.selected]="checked">
        </div>
        <ng-template #showImage>
          <img [lazyLoad]="image"
            defaultImage="/assets/img/Default Image.png"
            class="round-circle d-flex justify-content-center align-items-center m-auto fade-in" *ngIf="image != ''"
            [alt]="title" [class.selected]="checked">
          <img src="/assets/img/Default Image.png" onerror="this.src='/assets/img/Default Image.png';"
            class="round-circle d-flex justify-content-center align-items-center m-auto" *ngIf="image == ''"
            alt="Freebirds Logo" [class.selected]="checked">
        </ng-template>
      </button>
    </div>
  </a>

  <div *ngIf="groupName?.includes('Burrito Size')" class="burrito-option-label d-flex flex-column align-items-center">

    <h5 class="burrito-cat-title text-uppercase d-flex justify-content-center" *ngIf="title">{{ title | capitalize }}
    </h5>
    <h6 class="burrito-cat-price text-uppercase" *ngIf="subtitle">{{ subtitle | capitalize }}</h6>
    <div *ngIf="description">{{description}}</div>
    <div *ngIf="heatLevel > 0" class="d-flex justify-content-center heat-index">
      <img src="assets/img/heat-index-pepper.svg" *ngIf="heatLevel >= 1" class="mx-1" alt="Heat Level Indicator" />
      <img src="assets/img/heat-index-pepper.svg" *ngIf="heatLevel >= 2" class="mx-1" alt="Heat Level Indicator" />
      <img src="assets/img/heat-index-pepper.svg" *ngIf="heatLevel >= 3" class="mx-1" alt="Heat Level Indicator" />
    </div>
  </div>
</div>

<div class="option-image-checkbox p-0" *ngIf="isTiny" (click)="toggle()">
  <input #check [type]="type" [name]="name" [attr.name]="name" [value]="value" [attr.data-value]="value"
    [checked]="checked" [(ngModel)]="checked" (change)="inputChanged()" class="option-input" />
  <a class="option-states-container">
    <div class="option-states">
      <div class="premium" *ngIf="premium">Premium</div>
      <button class="btn btn-primary food-button">
        <img [lazyLoad]="image"
          defaultImage="/assets/img/Default Image.png"
          class="round-circle d-flex justify-content-center align-items-center m-auto fade-in" *ngIf="image != ''"
          [alt]="title" [class.selected]="checked">
        <img src="/assets/img/Default Image.png" onerror="this.src='/assets/img/Default Image.png';"
          class="round-circle d-flex justify-content-center align-items-center m-auto" *ngIf="image == ''"
          alt="Freebirds Logo" [class.selected]="checked">
      </button>
    </div>
  </a>
  <div class="option-label">
    <h5 class="cat-title text-uppercase d-flex justify-content-center" *ngIf="title">{{ title | capitalize }}</h5>
    <h6 class="cat-price text-uppercase" *ngIf="subtitle">{{ subtitle | capitalize }}</h6>
  </div>
</div>

<div class="row" *ngIf="doubleOption && checked">
  <div class="col text-center" (click)="toggleDouble()">
    <input type="checkmark" class="double-input" #double/>
  <div class="double-button text-uppercase" [ngClass]="{'double-clicked': this.doubleMeat?.nativeElement.checked}">
  <div *ngIf="!this.doubleMeat?.nativeElement.checked">Extra Meat?</div>
  <div *ngIf="this.doubleMeat?.nativeElement.checked">Extra Meat<i class="fa fa-check"></i></div>
  </div>
    <div class="double-cost">+{{doubleOption.cost | currency}}</div>
  </div>
</div>
</div>
