<app-location-details></app-location-details>
<div class="hands-background order-review sanFran">
  <div class="main-wrapper px-3">
    <div class="row title">
      <div class="col">
        <h1 class="text-uppercase text-center" *ngIf="this.dispatchSelected == 'true'">Checkout CONT.</h1>
        <h1 class="text-uppercase text-center" *ngIf="this.dispatchSelected == 'false' && !this.isGroupOrder">Checkout
          CONT.</h1>
        <h1 class="text-uppercase text-center" *ngIf="this.dispatchSelected == 'false' && this.isGroupOrder">Group
          Checkout CONT.</h1>
      </div>
    </div>
    <div>
      <ng-container *ngIf="this.saved_account">
        <div>
          <div>
            <p class="section-head text-uppercase">
              Select Payment Method</p>
          </div>
        </div>
        <div>
          <select class="form-control" (focusout)="inputScroll()" (change)="removeAccount(); selectAccount($event)">
            <option value="">Select a saved card</option>
            <option *ngFor="let card of saved_account[0]" [value]="card.accountid">{{card.cardtype}} ending in
              {{card.cardsuffix}}</option>
          </select>
        </div>
        <div *ngIf="this.saved_card_desc" class="mx-0 my-4">
          <div class="text-center">
            <p class="section-head" *ngIf="!this.saved_accountid">OR</p>
          </div>
        </div>
      </ng-container>
      <div *ngIf="!this.saved_accountid" class="payment">
        <form class="checkoutForm" name="checkoutForm" [formGroup]="checkoutForm" autocomplete="on">
          <div>
            <p class="section-head text-uppercase">Enter Payment Information</p>
          </div>

          <div class="payment-form-container row">
            <div class="col-12 p-0">
              <input (focusout)="inputScroll()" class="form-control" type="text" inputmode="numeric" name="ccnumber" formControlName="ccnumber"
                placeholder="Card Number" max="16" aria-label="Credit card number" ccNumber />
            </div>
            <div class="col-4 pl-0 pr-2">
              <input (focusout)="inputScroll()" class="form-control" type="text" inputmode="numeric" name="exp" formControlName="exp" placeholder="Exp Date (MM/YYYY)"
                autocomplete="cc-exp" aria-label="Expiration, Month, Year" ccExp />
            </div>
            <div class="col-4 p-0">
              <input (focusout)="inputScroll()" class=" form-control" type="text" inputmode="numeric" name="cvv" formControlName="cvv" placeholder="CVV" maxLength="4"
                aria-label="CCV" ccCvc />
            </div>
            <div class="col-4 pr-0 pl-2">
              <input (focusout)="inputScroll()" class="form-control" type="text" inputmode="numeric" name="zip" formControlName="zip" placeholder="Billing Zip Code"
                maxlength="5" aria-label="Zip Code" />
            </div>
          </div>
          <div *ngIf="this.userLoggedIn">
            <div class="d-flex justify-content-start align-items-center">
              <label class="checkbox-container d-flex justify-content-start align-items-center">
                <label class="ml-1" for="keepMeCheck">Save Card on File</label>
                <input (focusout)="inputScroll()" class="form-check-input" type="checkbox" checked="" id="keepMeCheck"
                  formControlName="keepMeCheck">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="cart-details-wrapper">
      <div *ngIf="!this.isGroupOrder && basket && basket['products'].length > 0" class="mb-48px">
        <div>
          <div>
            <p class="section-head">REVIEW YOUR BAG</p>
          </div>
        </div>
        <div class="cart-container" *ngFor="let product of basket.products">
          <div>
            <span class="product-name">{{product['name']}}</span><br />
            <p class="choices">{{ product['choices'] | choices }}</p>
            <span class="edit-section">
              <a href="">EDIT</a> |
              <a href="">REMOVE</a>
            </span>
          </div>
          <div class="quantity px-2">
            <div>{{product.quantity}}</div>
          </div>
          <div class="text-right justify-content-end">
            <span class="justify-content-end d-inline-block prod-cost">
              ${{product['totalcost'] | number:'.2-2' }}
            </span>
          </div>
        </div>
      </div>
      <div class="notice">
        (*) Indicates a customized Item
      </div>
      <div class="cart-items row m-auto" *ngIf="basket && basket['products'].length == 0">
        <p class="col-12 plus-minustxt empty-message pt-4 text-center">Doesn't look like there's anything here.</p>
        <p class="col-12 plus-minustxt empty-message pb-4 text-center">Click on a menu item to start ordering!</p>
      </div>
    </div>
    <div class="main-wrapper">
      <div class="totals-section">
        <div *ngIf="basket && basket['tip']">
          <div class="row my-1 mx-0">
            <div class="col pl-0">
              <div class="">Tip</div>
            </div>
            <div class="col float-right text-right pr-0">
              <div class="">${{ basket['tip'] | number:'.2-2' }}</div>
            </div>
          </div>
        </div>
        <div *ngIf="basket && basket['discount']">
          <div class="row my-1 mx-0">
            <div class="col pl-0">
              <div class="">Discount</div>
            </div>
            <div class="col float-right text-right pr-0">
              <div class="">- ${{ basket['discount'] | number:'.2-2' }}</div>
            </div>
          </div>
        </div>
        <div *ngIf="basket && basket.fees">
          <ng-container *ngFor="let fee of basket.fees">
            <div class="row my-1 mx-0">
              <div class="col pl-0">
                <div class="">Service Charge</div>
              </div>
              <div class="col float-right text-right pr-0">
                <div>${{ fee.amount | number:'.2-2' }}</div>
              </div>
            </div>
            <div *ngIf="fee.note" class="row my-1 mx-0">
              <div class="col pl-0 note-text">
                * {{ fee.note }}
              </div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="basket && this.dispatchSelected == 'true'">
          <div class="row my-1 mx-0">
            <div class="col pl-0">
              <div class="">Delivery Fee</div>
            </div>
            <div class="col float-right text-right pr-0">
              <div class="">${{ basket['customerhandoffcharge'] | number:'.2-2' }}</div>
            </div>
          </div>
        </div>
        <div *ngIf="basket && basket['salestax']">
          <div class="row my-1 mx-0">
            <div class="col pl-0">
              <div class="">Tax</div>
            </div>
            <div class="col float-right text-right pr-0">
              <div class="">${{ basket['salestax'] | number:'.2-2' }}</div>
            </div>
          </div>
        </div>
        <div *ngIf="basket">
          <div class="row my-1 mx-0">
            <div class="col pl-0">
              <div class="subtotal-text">Total</div>
            </div>
            <div class="col float-right text-right pr-0">
              <div class="subtotal-text">${{ basket['total'] | number:'.2-2' }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-wrapper mb-100px">
        <button class="freebirds-btn full-width continue-font-size" [ngClass]="{'is-loading' : checkoutClicked}" (click)="checkout()">
          <div *ngIf="!checkoutClicked">PLACE ORDER</div>
          <div *ngIf="checkoutClicked" class="spin-loader"></div>
        </button>
      </div>
    </div>
  </div>
</div>
