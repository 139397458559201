import {
  Component,
  OnInit,
  AfterViewInit,
  AfterContentInit,
  Renderer2, HostListener,
} from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { BasketService } from './providers/basket.service';
import { DineEngineCMSService } from './providers/dine-engine-cms.service';
// import { MetaService } from './providers/meta.service';
import { Basket } from './models/basket';
import {MetaDataService} from './providers/meta-data.service';
import {AnalyticsService} from './providers/analytics.service';
import {$e} from 'codelyzer/angular/styles/chars';
// import { PunchService } from './providers/punch.service';
declare const fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentInit, AfterViewInit {
  basketCount: any;
  ready = false;
  on = false;
  storage = window.localStorage.getItem('keepSigned')
    ? window.localStorage
    : window.sessionStorage;
  public isCollapsed = true;
  title = 'Olo Test...';
  restaurants;
  fullFooterList = [
    'brochure',
    'new-news',
    'order-online',
    'careers',
    'loyalty'
  ];

  constructor(
    private router: Router,
    private BasketService: BasketService,
    // private meta: MetaService,
    private renderer: Renderer2,
    private cms: DineEngineCMSService,
    private meta: MetaDataService,
    private analytics: AnalyticsService
  ) {
    this.basketCount = this.BasketService.basketCount;
  }

  @HostListener('window:beforeunload', ['$event'])
  onWindowClose($event) {
    this.analytics.logSiteAbandoned()
  }


  get fullFooter() {
    const matched = this.router.url.replace(/^\/([^\/]*).*$/, '$1');
    return this.fullFooterList.includes(matched);
  }

  logMessage() {
    const base22MessageStyle =
      'border-width: 4px; padding-left: 50px; padding-right: 50px; border-style: solid; border-color rgb(45,46,46); border-radius: 0px; padding-top: 8px; padding-bottom: 8px; background-color: #f5831f; font-size:45px; font-weight:200; letter-spacing:0.2em; line-height:1.4em; font-family:helvetica,arial; color:white;';

    if (environment.production) {
      window.console.log = () => {};
    }
  }

  ngOnInit() {
    this.logMessage();
    this.BasketService.getBasket().subscribe(basket => {}, err => {}, () => {});
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.meta.loadSpecificPage('/');
      this.ready = true;
    }, 750);

  }

  ngAfterViewInit() {

  }

  goToLocations() {
    this.router.navigate(['./locations']);
  }

  locationName() {
    const restaurantJSON = this.storage.getItem('restaurant');
    if (restaurantJSON) {
      const restaurant = JSON.parse(this.storage.getItem('restaurant'));
      return restaurant.name;
    }
    return 'Select a Location';
  }

  toggle() {
    this.on = !this.on;
  }
}
