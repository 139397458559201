import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CapitalizePipe} from './capitalize.pipe';
import {CategorySlugPipe} from './category-slug.pipe';
import {ChoicesPipe} from './choices.pipe';
import {KeysPipe} from './keys.pipe';
import {MainPipe} from './main.pipe';
import {Safe} from './safe.pipe';
import {TruncatePipe} from './truncate.pipe';
import { HandoffModePipe } from './handoff-mode.pipe';



@NgModule({
  declarations: [
    CapitalizePipe,
    CategorySlugPipe,
    ChoicesPipe,
    KeysPipe,
    Safe,
    TruncatePipe,
    HandoffModePipe
  ],
  imports: [
    CommonModule,
  ],
    exports: [
        CapitalizePipe,
        CategorySlugPipe,
        ChoicesPipe,
        KeysPipe,
        Safe,
        TruncatePipe,
        HandoffModePipe
    ],
  providers: [
    CapitalizePipe,
    CategorySlugPipe,
    ChoicesPipe,
    KeysPipe,
    Safe,
    TruncatePipe
  ]
})
export class PipeModule { }
