import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-time-selection-modal',
  templateUrl: './time-selection-modal.component.html',
  styleUrls: ['./time-selection-modal.component.scss']
})
export class TimeSelectionModalComponent {
  @Input() error;
  @Input() restaurant;
  @Input() basket;
  @Input() whenType_date = '';
  @Input() whenType_time = '';
  @Input() timeMode = 'asap';
  @Input() isLoading = false;
  @Input() whenType_timeArray
  @Input() whenType_dateArray = null;
  @Output() closeModal = new EventEmitter();
  @Output() timeSelectEmit = new EventEmitter();
  @Output() switchMethodEmit = new EventEmitter();

  whenType;

  timeSelectionForm: FormGroup = new FormGroup({
    date: new FormControl(''),
    time: new FormControl('')
  });

  constructor(public activeModal: NgbActiveModal) { }

  closeModalClicked() {
    this.closeModal.emit();
  }

  formatDate(date, format: string = 'MM/DD - dddd') {
    return moment(date).format(format);
  }

  dateChanged(event) {
    const date = event.target.value
    this.whenType_time = '';
    this.timeSelectionForm.patchValue({time: ''});
    this.whenType_date = date;
  }

  timeChanged(event) {
    const time = event.target.value
    this.timeSelectionForm.value.date = this.formatDate(this.whenType_date);
    this.whenType_time = time;
  }

  switchMethod() {
    this.switchMethodEmit.emit();
  }

  submitTime() {
    this.isLoading = true;
    let selectedTime;
    if (this.timeMode === 'asap') {
      selectedTime = 'asap';
    } else {
      selectedTime = {
        whenType_date: this.whenType_date,
        whenType_time: this.whenType_time
      }
    }
    this.timeSelectEmit.emit(selectedTime);
  }


}
