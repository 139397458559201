import { Injectable, OnInit } from '@angular/core';

export interface NotifyData {
  message: string;
  messageSecondary?: string;
  button1: any;
  button2?: any;
  quantity?: any;
}

@Injectable()
export class RatingService {
  private modal: HTMLElement;
  private notifyData: NotifyData;

  get payload(): NotifyData {
    return this.notifyData;
  }

  constructor() {
    this.default();
    this.modal = document.getElementById('ratingComponent');
  }

  default() {
    this.notifyData = {
      message: 'Oops, something went wrong',
      button1: { text: 'OK' }
    };
  }

  set(message?: string, button1?, button2?, quantity?) {
    if (message) {
      if (message.includes('<br/>')) {
        message = message.replace('<br/>', '<br>');
      }
      if (message.includes('<br>')) {
        const split = message.split('<br>');
        this.notifyData = {
          message: split[0],
          messageSecondary: split[1],
          button1: button1 ? button1 : { text: 'OK' },
          button2: button2 ? button2 : null,
          quantity: quantity ? quantity : null
        };
      } else {
        this.notifyData = {
          message: message,
          button1: button1 ? button1 : { text: 'OK' },
          button2: button2 ? button2 : null,
          quantity: quantity ? quantity : null
        };
      }
    } else {
      this.default();
    }
    // this.modal.set(quantity);
    this.modal.click();
  }

  clicked(button: number) {
    if (button === 1 && this.notifyData.button1.cb) {
      this.notifyData.button1.cb();
    } else if (button === 2 && this.notifyData.button2.cb) {
      this.notifyData.button2.cb();
    }
    this.modal.click();
  }
}
