import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RestaurantService } from '../../providers/restaurant.service';
import { MenuService } from '../../providers/menu.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainPipe } from '../../pipe/main.pipe';
// import {
//   TabsModule,
//   BsDropdownModule,
//   AlertModule,
//   CollapseModule,
//   PopoverModule,
//   ModalModule
// } from 'ng2-bootstrap';
import { CreditCardDirectivesModule } from '../../components/angular-cc-library';
import { OrderComponent } from './order.component';
import { ProductsComponent } from './products/products.component';
import { SubcategoriesComponent } from './subcategories/subcategories.component';
import { OrderRoutingMoudle } from './order.routes';
import { DetailsComponent } from './details/details.component';
import { ReviewComponent } from './review/review.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { OptionImageCheckboxComponent } from '../../components/option-image-checkbox/option-image-checkbox.component';
import { CapitalizePipe } from '../../pipe/capitalize.pipe';
import { LikeToAddComponent } from './like-to-add/like-to-add.component';
import { BoxLunchComponent } from './products/box-lunch/box-lunch.component';
import { RevItComponent } from './rev-it/rev-it.component';
import { LocationDetailsComponent } from './location-details/location-details.component';
import { UpsellComponent } from './products/upsell/upsell.component';
import { UpsellItemComponent } from './products/upsell/upsell-item/upsell-item.component';
import { ImageMappingDirective } from './image-mapping/image-mapping.directive';
import { ScrollSpyDirective } from './scroll-spy.directive';
import { GooglePayComponent } from './details/google-pay/google-pay.component';
import { NgxMaskModule } from 'ngx-mask';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {AlertModule} from 'ngx-bootstrap/alert';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TruncatePipe} from '../../pipe/truncate.pipe';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CategorySlugPipe} from '../../pipe/category-slug.pipe';
import {PipeModule} from '../../pipe/pipe.module';

@NgModule({
  declarations: [
    OptionImageCheckboxComponent,
    OrderComponent,
    ProductsComponent,
    SubcategoriesComponent,
    DetailsComponent,
    ReviewComponent,
    ConfirmComponent,
    LikeToAddComponent,
    BoxLunchComponent,
    RevItComponent,
    LocationDetailsComponent,
    UpsellComponent,
    UpsellItemComponent,
    ImageMappingDirective,
    GooglePayComponent,
    ScrollSpyDirective,
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TabsModule.forRoot(),
        CreditCardDirectivesModule,
        BsDropdownModule.forRoot(),
        AlertModule.forRoot(),
        CollapseModule,
        PopoverModule.forRoot(),
        NgxMaskModule.forRoot(),
        OrderRoutingMoudle,
        LazyLoadImageModule,
      PipeModule
    ],
    exports: [
        LocationDetailsComponent
    ],
  providers: [MenuService, RestaurantService]
})
export class OrderModule {}
