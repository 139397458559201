import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'categorySlug'
})
export class CategorySlugPipe implements PipeTransform {

  transform(value: string): string {
    return value.toLowerCase()
      .trim()
      .replace(/[^a-z \-]/g, '')
      .replace(/ +/g, '-');
  }

}
