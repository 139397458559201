import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'choices'
})
export class ChoicesPipe implements PipeTransform {

  transform(values: any, args?: any): any {
    let choices = [];
    for (let key in values) {
      choices.push(values[key]['name'])
    }
    return choices.join(', ')
  }
}