export const environment = {
  production: true,
  oloKey: '', // environments nginx will handle this
  punchClientId:
    '753d870975b915b4f1d69ed2ba7f1f1998db0faac810b6b5e0b3e8dad840c9d1',
  punchSecret: false,
  punchBaseUrl: 'https://punchh.com',
  cmsUrl: '',
  imageUrl: 'https://freebirdsdev.dineengine.com',
  CMSToken: false
};
