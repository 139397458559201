import { Component, Input, Output, EventEmitter, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.scss']
})
export class ForgotPasswordModalComponent implements OnInit {
  @Input() error;
  @Input() restaurant;
  @Input() basket;
  @Input() isLoading = false;
  @Output() closeModal = new EventEmitter();
  @Output() resetPasswordEmit = new EventEmitter();

  resetPasswordEmail;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }

  closeModalClicked() {
    this.closeModal.emit();
  }

  inputScroll() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  onClick(event) {
    event.stopImmediatePropagation();
    const checked = (!event.currentTarget.checked);
    event.currentTarget.checked = (checked) ? false : checked.toString();
  }

  submitForgotPassword() {
    if (this.resetPasswordEmail) {
      this.resetPasswordEmit.emit(this.resetPasswordEmail)
    }
  }


}
