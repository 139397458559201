<footer class="solid-border-top w-100 sanFran overflow-x-auto footer">
  <div class="d-flex bg-light">
    <a [routerLink]="['/careers']" target="_blank" class="my-2 ml-2 mr-3 lower-case">careers</a>
    <a [routerLink]="['/brochure/contact/feedback']" class="my-2 mx-3 lower-case">contact</a>
    <a [routerLink]="['/brochure/faq']" class="my-2 mx-3 lower-case">faq</a>
    <a [routerLink]="['/blog']" class="my-2 mx-3 lower-case">blog</a>
    <a *ngIf="nutrition" (click)="openNutrition()" class="my-2 mx-3 lower-case clickable">nutrition</a>
    <div class="ml-auto m-2">
      <a [routerLink]="['/brochure/terms/service']" class="clickable lower-case no-break no-decoration mr-4">terms & privacy</a>
    </div>
  </div>
</footer>
