import { Component, OnInit, ViewChild } from '@angular/core';
import { UpsellService } from '../../../providers/upsell.service';
import { BasketService } from '../../../providers/basket.service';
import { Router } from '@angular/router';
import { Observable ,  Subscription } from 'rxjs';
import { DineEngineCMSService } from 'app/providers/dine-engine-cms.service';

const BASE_URL: string = 'https://freebirds.dineengine.com';

@Component({
  selector: 'app-like-to-add',
  templateUrl: './like-to-add.component.html',
  styleUrls: ['./like-to-add.component.scss']
})
export class LikeToAddComponent implements OnInit {
  upsellItems$: Subscription;
  upsellItems = [];
  selected = {};

  globalImages: any = {};
  menu_images = {};

  unknown_img: string;

  constructor(
    private upsell: UpsellService,
    private basket: BasketService,
    private router: Router,
    private cms: DineEngineCMSService
  ) {
    this.upsell.completedUpsell = true;
  }

  ngOnInit() {
    this.getGlobalImages();
    this.getMenuImages();

    this.upsellItems$ = this.basket
      .getUpsellsApiData()
      .subscribe((res: any) => {
        res.groups.forEach(group => {
          group.items.forEach(item => {
            this.upsellItems.push(item);
          });
        });
        if (this.upsellItems.length === 0) {
          this.goToCheckout();
        }
      });
  }

  addToBasket(item) {
    this.basket.addUpsell(item.id).subscribe(basket => {
      document.getElementById('basketButton').click();
      basket.products.forEach(product => {
        if (product.name === item.name) {
          item.successId = product.id;
          return;
        }
      });
    });
  }

  removeFromBasket(item) {
    this.basket.removeProductFromBasket(item.successId).subscribe(res => {
      document.getElementById('basketButton').click();
    });
  }

  goToCheckout() {
    this.router.navigate(['/order/details']);
  }

  productSlug(option) {
    if (option !== null) {
      option = option
        .toLowerCase()
        .trim()
        .replace(/[^0-9a-z \-]/g, '')
        .replace(/ +/g, '-');
      return option;
    } else {
      return (option = 'unknown');
    }
  }

  select(item) {
    this.selected[item.name] = this.selected[item.name] ? false : true;
    if (this.selected[item.name]) {
      this.addToBasket(item);
    } else {
      this.removeFromBasket(item);
    }
  }

  // CMS GLOBAL IMAGES
  getGlobalImages() {
    this.cms.getTableSingleRow('global_images').subscribe(
      res => {
        this.globalImages = {
          drinks_img: BASE_URL + res.drinks_image.data.url,
          chips_n_dip_img: BASE_URL + res.chips_n_dip_image.data.url
        };
      }
    );
  }

  // CMS MENU IMAGES
  getMenuImages() {
    this.cms.getTable('ingredients').subscribe(
      res => {
        res.forEach(img => {
          let image = img.image ? BASE_URL + img.image.data.url : '';
          this.menu_images[img.name] = {
            img: image
          };
        });
      }
    );
  }
}
