import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BasketService} from '../../../../providers';

class UpSellItemClass {
  quantity;
  id;
  item;
  constructor(props) {
    this.quantity = props.qty;
    this.id = props.id;
    this.item = props.item;
  }
}

@Component({
  selector: 'app-upsell',
  templateUrl: './upsell.component.html',
  styleUrls: ['./upsell.component.scss']
})
export class UpsellComponent implements OnInit {
  _upSellItems: Array<UpSellItemClass> = [];

  @Output('onSuccess') _onSuccess = new EventEmitter();

  @Output('onFailure') _onFailure = new EventEmitter();

  _total = 0;

  constructor(protected basketService: BasketService) { }

  ngOnInit() {}

  updateUpSellList(item, qty) {
    if(this._upSellItems.length) {
      if(qty === 0) {
        this._upSellItems = this._upSellItems.filter(i => i.id !== item.id);
      } else {
        const updateItem = this._upSellItems.filter(i => i.id === item.id)[0];
        if(!updateItem) {
          this._upSellItems.push({id: item.id, quantity: qty , item});
        } else {
          updateItem.quantity = qty;
        }
      }
    } else {
      if(qty !== 0) {
        this._upSellItems.push({id: item.id, quantity: qty , item});
      }
    }
    this._updateTotal();
  }

  protected _updateTotal() {
    this._total = 0;
    this._upSellItems.forEach(product => {
      this._total += product.quantity * parseFloat(product.item.cost);
    });
  }

  get total() {
    return this._total;
  }

  submitUpsell() {
    this.basketService.addArrayUpsell(this._upSellItems).subscribe(
      res => {
        this._onSuccess.emit(res);
      },
      err => {
        const error = JSON.parse(err);
        this._onFailure.emit(error.message);
      }
    );
  }
}
