<div>
  <div class="modal-exit">
    <img (click)="closeModalClicked()" class="modal-exit-img" src="assets/icon/exit-button.svg" alt="Close Button" />
  </div>
  <div class="bs-modal-content-shell" *ngIf="whenType_timeArray && whenType_dateArray">
    <div class="bs-modal-content">

  <div class="delivery-pickup-wrapper">
  <h1 class="bs-modal-header">{{basket.deliverymode === 'dispatch' ? 'Delivery Time' : 'Pickup Time'}}</h1>

  <div class="delivery-method">
    <div class="row">
      <div class="col">
        <label class="radio-container mr-3 text-left" [ngClass]="{'asap-unavailiable': !restaurant.iscurrentlyopen}">As Soon as Possible
          <input type="radio" value="asap" [(ngModel)]="timeMode" [disabled]="!restaurant.iscurrentlyopen">
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="col">
        <label class="radio-container text-left">Select A Time
          <input type="radio" value="selecttime" [(ngModel)]="timeMode">
          <span class="checkmark"></span>
        </label>
      </div>
    </div>

  </div>
  <hr/>
  <div *ngIf="timeMode === 'selecttime'">
    <form [formGroup]="timeSelectionForm">
    <div class="col-12 select-datetime">
      <div class="row mb-2">
        <div class="col-6 p-2">
        <select formControlName="date" name="date" aria-label="date" (change)="dateChanged($event)">
          <option value="" disabled selected hidden>{{formatDate(whenType_date)}}</option>
          <option *ngFor="let date of whenType_dateArray; index as i" [value]="date">{{formatDate(date)}}</option>
        </select>
      </div>
        <div class="col-6 p-2">
          <select formControlName="time" name="time" aria-label="time" (change)="timeChanged($event)">
            <option value="" disabled selected hidden>Select Time</option>
            <option *ngFor="let time of whenType_timeArray[whenType_date]; index as i" [value]="time.value">{{ time.label }}</option>
          </select>
        </div>
      </div>
    </div>
    </form>
  </div>


  </div>
  <div class="delivery-options row m-0 mt-3">
    <div class="col p-2 m-0">
      <button class="freebirds-btn w-100"
      [disabled]="timeMode === 'selecttime' && (!timeSelectionForm.value.date || !timeSelectionForm.value.time)"
      (click)="submitTime()">
      <div *ngIf="!isLoading">Continue</div>
      <div *ngIf="isLoading" class="spin-loader"></div>
    </button>
    </div>
  </div>
    </div>
  </div>
</div>
