import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BasketService, DineEngineCMSService} from '../../providers';
import {LoaderService} from '../../providers/loader.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {ContrastService} from 'app/providers/contrast.service';
import {environment} from '../../../environments/environment';
import {DomSanitizer} from '@angular/platform-browser';
import {take} from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {NgbCarousel} from '@ng-bootstrap/ng-bootstrap';
import {OrderMethodService} from 'app/providers/order-method.service';
import {HomePageSlides} from '../../models/cms.collections';


const BASE_URL = 'https://freebirdsdev.dineengine.com';
declare var $: any;

@Component({
  selector: 'app-order-online',
  templateUrl: './order-online.component.html',
  styleUrls: ['./order-online.component.scss'],
})
export class OrderOnlineComponent implements OnInit, OnDestroy, AfterViewInit {
  BASE_URL = environment.imageUrl;
  subs: Subscription[] = [];

  @ViewChild('carousel', {static : false}) carousel: NgbCarousel;

  freebirds_logo: any;
  section1_text_1: string;
  doneLoad = false;
  done1 = false;
  done2 = false;
  num1 = false;
  slides: HomePageSlides[] = [];
  showingSlide = 0;
  basket;

  carouselInterval = 0
  storage = window.localStorage.getItem('keepSigned')
    ? window.localStorage
    : window.sessionStorage;

  slideShowInterval: number = null;

  constructor(
    private cms: DineEngineCMSService,
    private loader: LoaderService,
    private router: Router,
    private contrast: ContrastService,
    private sanitizer: DomSanitizer,
    private orderMethod: OrderMethodService,
    private basketService: BasketService
  ) {}

  ngOnInit() {
    this.populate_page_CMS();
    this.loader.show();
    this.basketService.getBasket().subscribe(res => {
      this.basket = res
    })
    this.basketService.onSetBasket.subscribe(res => {
      this.basket = res
    })
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
    clearInterval(this.slideShowInterval);
  }

  scrollSecond() {
    const element_to_scroll_to = document.getElementById('second-section');
    // Basically `element_to_scroll_to` just have to be a reference
    // to any DOM element present on the page
    // Then:

    element_to_scroll_to.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }

  // CMS GLOBAL IMAGES
  getGlobalImages() {
    this.subs.push(this.cms.getTableSingleRow('global_images').subscribe(
      res => {
        this.freebirds_logo = BASE_URL + res.freebirds_logo.data.url;
      },
      () => {
        this.done2 = true;
        if (this.done1 && this.done2 && this.num1) {
          this.doneLoad = true;
          this.loader.hide();
        }
      }
    ));
  }

  finish(num) {
    this.num1 = true;
    if (this.done1 && this.done2 && this.num1) {
      this.doneLoad = true;
      this.loader.hide();
    }
  }

  next() {
    $('.carousel').carousel('next');
  }

  prev() {
    $('.carousel').carousel('prev');
  }

  // CMS PAGE SPECIFIC
  populate_page_CMS() {
    this.subs.push(this.cms.getHomePageSlides().pipe(take(1)).subscribe(
      (res) => {
        this.slides = res.data.map(slide => {
          return {
            ...slide,
            slide_content: this.sanitizer.bypassSecurityTrustHtml(String(slide.slide_content))
          }
        })
      },
      err => {
      },
      () => {
          this.getAutoScroll();
        this.done1 = true;
        if (this.done1 && this.done2 && this.num1) {
          this.doneLoad = true;
          this.loader.hide();
        }
      }
    ));
  }

  getAutoScroll() {
    this.subs.push(this.cms.getBranding().pipe(take(1)).subscribe((res) => {
      if (res.data.auto_scroll) {
        this.carouselInterval = 8000
        // this.slideShowInterval = setInterval(() => {
        //   this.next();
        // }, 10000);
      }
    }));
  }

  changeFlow(url) {
    if (url) {
      if (url.substring(0, 4) === 'http') {
        window.location.href = url;
      } else {
        if (url.includes('?')) {
          window.location.href = window.location.origin + url;
        } else {
          if (url === '/locations') {
            if (this.basket) {
              this.router.navigate(['order'])
            } else {
              this.router.navigate(['menu'])
            }
          } else {
            this.router.navigate([url]);
          }
        }
      }
      return;
    }
  }

  cateringClicked() {
    this.storage.setItem('cateringClicked', 'true');
    this.router.navigate(['/order-line']);
  }

  allowHTML(content: string) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
}
