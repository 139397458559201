import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DineEngineCMSService } from 'app/providers/dine-engine-cms.service';
import { Subscription } from 'rxjs';

const BASE_URL = 'https://freebirds.dineengine.com';

@Component({
  selector: 'app-box-lunch',
  templateUrl: './box-lunch.component.html',
  styleUrls: ['./box-lunch.component.scss']
})
export class BoxLunchComponent implements OnInit {
  @Input('evenItem') evenItem;
  @Input('option') option;
  @Input('imageUrl') imageUrl;
  @Output() selectedEvent = new EventEmitter();

  storage = window.localStorage.getItem('keepSigned')
    ? window.localStorage
    : window.sessionStorage;
  selected = false;
  description: string;
  sub: Subscription;
  feedingagroup: any = false;

  constructor(
    private cms: DineEngineCMSService,
  ) {}

  ngOnInit() {
    this.populate_page_CMS();
    this.storage.setItem('feedingagroup', 'true');
  }

  selectItem() {
    this.selected = !this.selected;
    if (this.selected) {
      this.selectedEvent.emit(this.option);
    }
  }

  formatCost(cost) {
    if (cost <= 0) {
      return undefined;
    }
    if (typeof cost !== 'number') {
      return cost;
    }
    return '$' + cost.toFixed(2);
  }

  productSlug(option) {
    if (option !== null) {
      option = option
        .toLowerCase()
        .trim()
        .replace(/[^0-9a-z \-]/g, '')
        .replace(/ +/g, '-');
      return option;
    } else {
      return (option = 'unknown');
    }
  }

  populate_page_CMS() {
    this.sub = this.cms.getTable('feeding_a_group').subscribe(
      res => {
        for (let i = 0; i < res.length; i++) {
          if (this.option.name === res[i].name) {
            this.description = res[i].description;
          }
        }
      }
    );
  }
}
