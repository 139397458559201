import {Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {DineEngineCMSService} from '../../../providers';
import {MetaDataService} from '../../../providers/meta-data.service';
import {Blog} from '../../../models/cms.collections';

@Component({
  selector: 'app-new-news-post',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.scss']
})
export class PostDetailComponent implements OnInit {
  content: Blog = null;
  script: any;
  image1Load = false;
  CMSurl = 'https://freebirds.dineengine.com/';

  constructor(
    private cms: DineEngineCMSService,
    private _route: ActivatedRoute,
    private meta: MetaDataService
  ) {}

  ngOnInit() {
    // reload once for social sharing
    if (window.localStorage) {
      if (!localStorage.getItem('firstLoad')) {
        localStorage['firstLoad'] = true;
        window.location.reload();
      } else { localStorage.removeItem('firstLoad'); }
    }

    setTimeout(() => {
      this.loadscript();
    }, 1000);
    this._route.params.subscribe(params => {
      if (params['postID']) {
        this.getPostDetails(params['postID']);
      }
    });
  }

  loadscript() {
    // Add a script tag to the DOM
    this.script = document.createElement('script');
    document.body.appendChild(this.script);
    // set script source: will trigger the download
    this.script.src =
      'https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5b76d200e566e8a2';
  }

  getPostDetails(postID) {
    this.cms.getBlogPost(postID).subscribe(
      (res) => {
        this.content = res.data;
        this.meta.manualUpdate({
          title: 'Freebirds World Burrito | ' + this.content.title,
          keywords: this.content.keywords
        });
      }
    );
  }
}
