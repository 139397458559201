import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Restaurants } from '../models/restaurants';
import { environment } from 'environments/environment'
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class OloService {
  constructor (
    protected http: HttpClient,
  ) {}

  public getCmsUrl() {
    if(!environment.production && environment.cmsUrl) {
      return environment.cmsUrl;
    }
    return '';
  }

  // public getHeaders(){
  //   const headers = new Headers();
  //   headers.append('Accept', 'application/json');
  //
  //   // If we have an oloKey defined use it, if not assume nginx proxy will handle it.
  //   if (environment.oloKey) {
  //     headers.append('Authorization', `OloKey ${environment.oloKey}`)
  //   }
  //
  //   return headers;
  // }

  public getHeaders2(){
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');

    // If we have an oloKey defined use it, if not assume nginx proxy will handle it.
    if (environment.oloKey) {
      headers.append('Authorization', `OloKey ${environment.oloKey}`)
    }

    return headers;
  }
}
