import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError, finalize, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {OloService} from 'app/providers/olo.service';
import {Calendar, CalendarResponse, Restaurants} from '../models/restaurants';
import {LoaderService} from 'app/providers/loader.service';
import {Router} from '@angular/router';
import {BasketService} from './basket.service';
import {Basket} from '../models/basket';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ToastService} from './toast.service';
import {Cacheable} from 'ts-cacheable';

export class CoordsConfig {
  lat = null;
  long = null;
}

@Injectable()
export class RestaurantService extends OloService {
  staticData: any;

  basket: Basket;
  storage = window.localStorage.getItem('keepSigned')
    ? window.localStorage
    : window.sessionStorage;

  constructor(
    protected http: HttpClient,

    private loaderService: LoaderService,
    private router: Router,
    private basketService: BasketService,
    private toast: ToastService
  ) {
    super(http);
  }

  getRestaurants(near: boolean = false, config = new CoordsConfig()): Observable<Restaurants[]> {
    this.showLoader();
    let privates = 'false';
    if (window.location.hostname === 'localhost'
      || window.location.hostname === 'stage01.freebirds.chepri.com'
      || window.location.hostname === 'dev01.freebirds.chepri.com') {
      privates = 'true';
    }
    let restEndPoint = '/api/restaurants?includePrivate=' + privates;
    if (near) {
      restEndPoint += `&lat=${config.lat}&long=${config.long}`;
    }
    return this.http
      .get<{ restaurants: Restaurants[] }>(this.getCmsUrl() + restEndPoint, {
        headers: this.getHeaders2()
      }).pipe(
        map(res => <Restaurants[]>res.restaurants),
        catchError((error: any) => observableThrowError(error.error)),
        finalize(() => {
          // this.onEnd();
        }),);
  }

  getRestaurantByState(state: string): Observable<Restaurants[]> {
    return this.getRestaurants().pipe(map(restaurants =>
      restaurants.filter(res => res.state === state)
    ));
  }

  getRestaurantBySlug(slug: string): Observable<Restaurants> {
    return this.http.get<Restaurants>(`${this.getCmsUrl()}/api/restaurants/byslug/${slug}`, {headers: this.getHeaders2()})
  }

  @Cacheable({
    maxAge: 5 * 60 * 1000
  })
  getRestaurantById(id: string): Observable<Restaurants> {
    if (!id) {
      // return Observable.throw('no id')
      this.router.navigate(['/locations']);
      return observableThrowError('no id');
    }
    this.showLoader();
    return this.http
      .get<Restaurants>(this.getCmsUrl() + '/api/restaurants/' + id, {headers: this.getHeaders2()}).pipe(
        catchError((error: any) => observableThrowError(error.error)),
        finalize(() => {
          this.onEnd();
        }),);
  }

  getNearRestaurants(lat, long, rad, limit): Observable<Restaurants[]> {
    this.showLoader();
    return this.http
      .get<{ restaurants: Restaurants[] }>(this.getCmsUrl() +
        '/api/restaurants/near?lat=' +
        lat +
        '&long=' +
        long +
        '&radius=' +
        rad +
        '&limit=' +
        limit,
        {headers: this.getHeaders2()}
      ).pipe(
        map(res => <Restaurants[]>res.restaurants),
        catchError((error: any) => observableThrowError(error.error)),
        finalize(() => {
          // this.onEnd();
        }),);
  }

  getRestaurantHoursForToday(restaurant_id: string) {
    const today = moment().format('YYYYMMDD');
    return this.getRestaurantHours(restaurant_id, today, today);
  }

  getRestaurantHours(id: string, from: string = null, to: string = null): Observable<Calendar> {
    if (from == null) {
      from = moment().format('YYYYMMDD');
    }
    if (to == null) {
      to = moment()
        .add(30, 'days')
        .format('YYYYMMDD');
    }
    this.showLoader();
    const result = this.http
      .get<CalendarResponse>(this.getCmsUrl() + '/api/restaurants/' + id + '/calendars?from=' + from + '&to=' + to, {
        headers: this.getHeaders2()
      }).pipe(
      map(res => res.calendar),
      catchError((error: any) => observableThrowError(error.error)),
      finalize(() => {
        this.onEnd();
      }),);
    return result;
  }

  addFavoriteLocation(restuarant_id: number, authtoken: string, isdefault: boolean = false) {
    const params = new HttpParams()
      .set('restuarant_id', restuarant_id.toString())
      .set('isdefault', isdefault.toString());
    return this.http.post(this.getCmsUrl() + '/api/users/' + authtoken + '/favelocations/' + restuarant_id.toString(), params);
  }

  getFavoriteLocations(authtoken: string) {
    const resouse = this.getCmsUrl() + '/api/users/' + authtoken + '/favelocations/';
    return this.http.get(resouse);
  }

  deleteFavoriteLocation(restuarant_id: number, authtoken: string) {
    const resouse = this.getCmsUrl() + '/api/users/' + authtoken + '/favelocations/' + restuarant_id.toString();
    return this.http.delete(resouse)
  }

  // getStaticDataForRestaurant(name): Observable<any> {
  //   if (this.staticData) {
  //     return this.staticData[name];
  //   } else {
  //     this.showLoader();
  //     const result = this.http
  //       .get('/assets/static-data.json').pipe(
  //       map(res => {
  //         this.staticData = res.json();
  //         return res.json().locations[name];
  //       }),
  //       catchError(error => observableThrowError(error)),
  //       finalize(() => {
  //         this.onEnd();
  //       }),);
  //     return result;
  //   }
  // }

  onEnd(): void {
    // this.hideLoader();
  }

  showLoader(): void {
    // this.loaderService.show();
  }

  changeFlow(data, addTip: boolean = true) {
    if (!sessionStorage.getItem('basket_id')) {
      return false;
    }
    if (data === 'delivery') {
      this.storage.setItem('changeMode', 'delivery');
      this.router.navigate(['/locations/delivery']);
    } else  {
      this.basketService.setDeliveryMode(data).subscribe(
        basket => {
          this.storage.setItem('dispatchSelected', 'false');
          this.basket = basket;
          if (addTip) {
            setTimeout(() => {
              this.basketService.addTip(0).subscribe(basket => {
                this.basket = basket;
                location.reload();
              });
            }, 500);
          }
        },
        err => {
          if (err) {
            this.toast.danger(err)
          }
        }
      );
    }
  }
}
