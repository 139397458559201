<app-location-details></app-location-details>

<div class="customize-container hands-background">
  <div class="subnav-scrollbar d-lg-none" id="subnavScroll" *ngIf="!(hasPreOptionPage && showFirstOption)">
    <div class="subnav-items" *ngFor="let group of scrollSections; let i = index" (click)="scrollToItem(group)">
      <div class="subnav-text-item" id="subScroll_{{group}}" [ngClass]="{'selected-mod': 'scroll_' + group === selectedModGroup}">{{group}}</div></div>
  </div>
  <!-- INGREDIENTS -->

  <div #ingredients class="ingredients">
    <div class="tab-content" id="tab-content-scroll" scrollSpy [spiedTags]="['DIV']" (sectionChange)="onSectionChange($event)" aria-hidden="true">

      <!-- first option group -->

      <div class="mod-section" id="scroll_{{cat?.name}}" *ngIf="showFirstOption" aria-label="size-tab">
        <div class="w-100 mx-auto container-main-background">
          <h1 class="text-center h1-title garageGothic" *ngIf="cat?.name !== 'Sides, Drinks and Desserts'" [ngClass]="{'no-margin-bottom': itemFormatSquare}">
            {{ cat?.name === 'Weebirds (Kids)' ? 'WEEBIRDS' : cat?.name }} </h1>
          <div *ngIf="cat?.description">
            <p class="title-subtitle" [innerHTML]="nl2br(cat.description)"></p>
          </div>
          <div *ngIf="cat?.name == 'Taco Meal Kits'" class="special-item">
            <div *ngFor="let prod of cat?.products" (click)="selectProduct(prod, true)">
              <div
                class="row no-gutters d-block d-flex justify-content-center align-items-center align-content-center align-self-center m-auto mh-100px">
                <div class="col m-auto clickable">
                  <img
                    class="img-fluid d-flex justify-content-center align-items-center align-content-center align-self-center m-auto ml-0"
                    [src]="productImages[slug(prod.name)]" id="special-item-img"
                    [ngClass]="{'selected-special-item': prod == selectedProduct}" [hidden]="!specialItemLoaded"
                    (load)="specialItemLoaded = true" [alt]="prod.name">
                </div>
              </div>
              <div
                class="special-item-text row no-gutters d-block d-flex justify-content-center align-items-center align-content-center align-self-center m-auto pb-4">
                <div class="col">
                  <h5 class="text-uppercase" [ngClass]="{'text-center': !prod.cost || prod.cost <= 0 }">
                    {{ prod.name ? prod.name : 'Special Item'}}</h5>
                </div>
                <div class="col-2" *ngIf="prod.cost > 0">
                  <h5>{{ prod.cost | currency:'USD':true:'1.2-2' }}</h5>
                </div>
                <div class="col-2" *ngIf="prod.plusCost">
                  <h5>{{ prod.plusCost | currency:'USD':true:'1.2-2' }}</h5>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="itemFormatSquare && cat?.name !== 'Burritos' && cat?.name !== 'Taco Meal Kits'">
            <div *ngIf="categories" class="fb-menu-container top-spacing default-width">
              <div [imageName]="cat.name" [images]="menuImages" [backgroundSize]="'411px'"
                class="fb-menu-item-product" appImageMapping *ngFor="let prod of cat?.products">
                <div class="overlay"></div>
                <div class="flex-end">
                  <div class="row cat-name">
                    <h1 class="title">{{prod.name}}</h1>
                  </div>
                  <div>
                    <button (click)="selectProduct(prod, true)" class="order-now-btn clickable" role="button">
                      <span>ORDER NOW</span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngIf="!itemFormatSquare && cat?.name !== 'Taco Meal Kits'" [ngSwitch]="cat?.name">
            <div class="mb-4 row" [ngClass]="{'ingredients-list-container' : cat?.name !== 'Sides, Drinks and Desserts'}">
              <ng-container *ngSwitchCase="'Family Meal Kits'">
                <ng-container [ngTemplateOutlet]="familyMealKit" [ngTemplateOutletContext]="{cat: cat}">
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'Sides, Drinks and Desserts'">
                  <ng-container [ngTemplateOutlet]="sidesDrinksDessertsTemplate" [ngTemplateOutletContext]="{cat: cat}">
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'Low-Carb Bowls'">
                  <ng-container [ngTemplateOutlet]="powerBowlsTemplate" [ngTemplateOutletContext]="{cat: cat}">
                  </ng-container>
                </ng-container>
              <ng-container *ngSwitchDefault>
                <ng-container [ngTemplateOutlet]="defaultOptionTemplate" [ngTemplateOutletContext]="{cat: cat}">
                </ng-container>
              </ng-container>
            </div>

          </ng-container>
          <div *ngIf="cat?.name == 'Feeding a Group'" class="catering-message">
            For Orders over $300, please contact our Catering Sales Team at 888.392.2287 to arrange delivery
          </div>
          <div class="bottom-right-message bird-art" *ngIf="cat?.name == 'Weebirds (Kids)'">
            <h4>
              WITH KID'S<br>
              CHIPS & DRINK
            </h4>
          </div>
        </div>
      </div>

      <!-- end first option group -->

      <!-- second option group -->

      <ng-container *ngIf="showSecondOption">
      <div class="mod-section"
        *ngFor="let group of optionGroups; trackBy: customTrackBy" id="scroll_{{group.description}}">
        <div [id]="group.description" *ngIf="group.description !== 'Extra Meat?'">
          <div class="row title">
            <div class="col-12 text-center" [ngSwitch]="group.description.toString().toUpperCase()">
              <h1 class="text-uppercase text-center h1-title garageGothic" *ngSwitchDefault>{{ groupName(group.description) }}</h1>
            </div>
          </div>
          <div class="d-flex flex-column align-items-center">
            <div
              *ngIf="group.description === 'Party Platters' || group.description === 'Box Lunches' || group.description === 'Catering' || group.description === 'Beverage Service' || group.description === 'Tea Service' || group.description === 'Chips and Dips'">
              <p class="text-light mt-neg40">Online Orders including party platters or box lunches can
                take a few hours</p>
              <app-box-lunch *ngFor="let option of group.options; let i = index" [evenItem]="i % 2 == 0"
                [option]="option" (selectedEvent)="boxLunchSelected($event)"
                [imageUrl]="cmsIngredients[option.name] ? cmsIngredients[option.name].image : ''"></app-box-lunch>
              <div class="text-uppercase text-light py-2 px-1">
                Ordering for a larger group or want more customizable options like build your own burrito/taco bars/
                full service or box
                lunches?
              </div>

              <div class="catering-message py-2 px-1">
                For Orders over $300, please contact our Catering Sales Team at 888.392.2287 to arrange delivery
              </div>

              <a class="btn btn-outline-primary btn-block btn-freebirds freebirds-btn contact-platter py-2 px-1" role="button" [routerLink]="['/brochure/contact/catering']">CONTACT US</a>
            </div>
            <div class="ingredients-list-container row"
              *ngIf="group.description !== 'Party Platters' &&
              group.description !== 'Box Lunches' &&
              group.description !== 'Catering' &&
              group.description !== 'Beverage Service' &&
              group.description !== 'Tea Service' &&
              group.description !== 'Chips and Dips' &&
              group.description !== 'Sauces'">
              <div *ngFor="let option of group.options; trackBy: customTrackBy"
                class="col-4 col-sm-3 col-lg-4 col-xl-3  my-2">
                <option-image-checkbox class="cat-item" type="{{ group.mandatory? 'radio': 'checkbox' }}"
                  name="group_{{ group.id }}" [value]="option?.id" [title]="option.name"
                  [subtitle]="option && option.cost ? formatCost(option?.cost) : ''"
                  [image]="ingredientImages[slug(option.name)]" [group]="group.id"
                  [groupName]="group.description"
                  [description]="getItemDesc(option.name)"
                  [premium]="MenuService.getMetadataByKey('isPremium', option.metadata)"
                  (itemChanged)="optGroupProductSelected(option, option.name, group.id, option.id, $event, group)"
                                       [unavailableWarnings]="getUnavailiableHandoffs(option)"
                  [doubleOption]="extraMeat(selectedProductOptGroup?.modifiers)"
                  (doubleMeatClicked)="addDouble($event, false)"
                  [basket]="basket"
                  [option]="option">
                </option-image-checkbox>
              </div>
            </div>
            <div class="ingredients-list-container row" *ngIf="group.description === 'Sauces'">
              <div *ngFor="let option of group.options; trackBy: customTrackBy"
                class="col-4 col-sm-3 col-lg-4 col-xl-3 my-2 ">
                <option-image-checkbox class="cat-item" type="{{ group.mandatory? 'radio': 'checkbox' }}"
                  name="group_{{ group.id }}"
                  [value]="option.id" [checked]="option.isdefault" [title]="option.name"
                  [subtitle]="formatCost(option.cost)"
                  [heatLevel]="ingredientHeatLevels[slug(option.name)]" [image]="ingredientImages[slug(option.name)]"
                  [group]="group.id"
                  [premium]="MenuService.getMetadataByKey('isPremium', option.metadata)"
                                       [unavailableWarnings]="getUnavailiableHandoffs(option)"
                  (itemChanged)="optGroupProductSelected(option, option.name, group.id, option.id, $event, group)"
                  [basket]="basket"
                  [option]="option">
                </option-image-checkbox>
              </div>
              <div class="heat-index col-12 my-4 d-flex flex-column align-items-center p-2">
                <h3 class="m-2 garageGothic">HEAT INDEX</h3>
                <div class="index-container m-2">
                  <div class="heat-cell d-flex justify-content-end">
                    <img src="assets/img/heat-index-pepper.svg" class="mx-1" alt="Heat Level Indicator">
                    <img src="assets/img/heat-index-pepper.svg" class="mx-1" alt="Heat Level Indicator">
                    <img src="assets/img/heat-index-pepper.svg" class="mx-1" alt="Heat Level Indicator">
                  </div>
                  <div class="index-description">
                    Dang, that’s hot!
                  </div>
                  <div class="heat-cell d-flex justify-content-end">
                    <img src="assets/img/heat-index-pepper.svg" class="mx-1" alt="Heat Level Indicator">
                    <img src="assets/img/heat-index-pepper.svg" class="mx-1" alt="Heat Level Indicator">
                  </div>
                  <div class="index-description">
                    Making your normal medium look mild.
                  </div>
                  <div class="heat-cell d-flex justify-content-end">
                    <img src="assets/img/heat-index-pepper.svg" class="mx-1" alt="Heat Level Indicator">
                  </div>
                  <div class="index-description">
                    Just enough heat.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </ng-container>

      <!-- second option group -->

      <!-- Third option group -->

      <div class="mod-section"
        *ngFor="let mod of selectedProductOptGroup?.modifiers; let i = index; trackBy: customTrackBy"
        id="scroll_{{mod.description}}">
        <div>

          <div *ngIf="mod.description.toString().toUpperCase() !== 'INGREDIENTS' && mod.description.toString().toUpperCase() !== 'EXTRA MEAT?'" class="row title">
            <div class="col text-center" [ngSwitch]="mod.description.toString().toUpperCase()">
              <h1 class="text-uppercase text-center h1-title garageGothic" *ngSwitchDefault>{{ mod.description }}</h1>
              <p class="title-subtitle text-uppercase">{{ mod.explanationtext }}</p>
            </div>
          </div>

          <div [ngSwitch]="mod.description.toString().toUpperCase()">
            <div class="ingredients-list-container row" *ngSwitchCase="'SAUCES'">
              <div *ngFor="let option of mod.options; trackBy: customTrackBy"
                class="col-4 col-sm-3 col-lg-4 col-xl-3 my-2 ">
                <option-image-checkbox class="cat-item" type="{{ mod.mandatory? 'radio': 'checkbox' }}"
                  name="group_{{ selectedProductOptGroup?.group_id }}_option_{{ selectedProductOptGroup?.option_id }}_mod_{{ mod.id }}"
                  [value]="option.id" [checked]="option.isdefault" [title]="option.name"
                  [subtitle]="formatCost(option.cost)"
                  [heatLevel]="ingredientHeatLevels[slug(option.name)]" [image]="ingredientImages[slug(option.name)]"
                  [group]="selectedProductOptGroup?.group_id + ' ' + mod.id"
                  [premium]="MenuService.getMetadataByKey('isPremium', option.metadata)"
                                       [unavailableWarnings]="getUnavailiableHandoffs(option)"
                  (itemChanged)="optGroupSelected(option, $event, mod)"
                  [basket]="basket"
                  [option]="option">
                </option-image-checkbox>
              </div>
              <div class="heat-index col-12 my-4 d-flex flex-column align-items-center p-2">
                <h3 class="m-2 garageGothic">HEAT INDEX</h3>
                <div class="index-container m-2">
                  <div class="heat-cell d-flex justify-content-end">
                    <img src="assets/img/heat-index-pepper.svg" class="mx-1" alt="Heat Level Indicator">
                    <img src="assets/img/heat-index-pepper.svg" class="mx-1" alt="Heat Level Indicator">
                    <img src="assets/img/heat-index-pepper.svg" class="mx-1" alt="Heat Level Indicator">
                  </div>
                  <div class="index-description">
                    Dang, that’s hot!
                  </div>
                  <div class="heat-cell d-flex justify-content-end">
                    <img src="assets/img/heat-index-pepper.svg" class="mx-1" alt="Heat Level Indicator">
                    <img src="assets/img/heat-index-pepper.svg" class="mx-1" alt="Heat Level Indicator">
                  </div>
                  <div class="index-description">
                    Making your normal medium look mild.
                  </div>
                  <div class="heat-cell d-flex justify-content-end">
                    <img src="assets/img/heat-index-pepper.svg" class="mx-1" alt="Heat Level Indicator">
                  </div>
                  <div class="index-description">
                    Just enough heat.
                  </div>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="'EXTRA MEAT?'"></div>

            <div class="ingredients-list-container row" *ngSwitchDefault>
              <div *ngFor="let option of mod.options; trackBy: customTrackBy"
                class="col-4 col-sm-3 col-lg-4 col-xl-3 my-2">
                <option-image-checkbox class="cat-item" type="{{ mod.mandatory? 'radio': 'checkbox' }}"
                  name="group_{{ selectedProductOptGroup.group_id }}_option_{{ selectedProductOptGroup.option_id }}_mod_{{ mod.id }}"
                  [value]="option.id" [checked]="option.isdefault" [title]="option.name"
                  [subtitle]="formatCost(option.cost)" [image]="ingredientImages[slug(option.name)]"
                  [group]="selectedProductOptGroup.group_id + ' ' + mod.id"
                  [groupName]="mod.description" [description]="getItemDesc(option.name)"
                  [premium]="MenuService.getMetadataByKey('isPremium', option.metadata)"
                  (itemChanged)="optGroupSelected(option, $event, mod)"
                  [unavailableWarnings]="getUnavailiableHandoffs(option)"
                  [basket]="basket"
                  [option]="option">
                </option-image-checkbox>
              </div>
            </div>
          </div>


        </div>
      </div>

      <!-- End Third option group -->

      <!-- Footer -->

      <div id="special_instr_box" class="instructions special-instructions">
        <h1 class="text-uppercase text-center h1-title garageGothic">Special Instructions</h1>
        <textarea placeholder="Special Instructions" [(ngModel)]="product_instructions"
        class="special-instructions-textfield"
        (focusout)="inputScroll()"></textarea>
      </div>

      <div class="product-footer">
        <div class="row chips-container">
          <div class="col-12 col-md-4 p-0" *ngFor="let chip of sortedOptionPills">
          <div class="chip-group">{{chip.description}}:</div>
          <div class="chip-item" *ngFor="let mod of chip.items">{{mod.name}}<i class="fa fa-times-circle" (click)="removeOptionPill(mod.value)"></i></div>
          </div>
        </div>
        <div class="product-total"><span class="font-weight-bold">Subtotal: </span>
          <span *ngIf="cat?.name !== 'Sides, Drinks and Desserts'" class="price-float">{{this.selectedProduct?.cost + modifierPrices() | currency}}</span>
          <span *ngIf="cat?.name === 'Sides, Drinks and Desserts'" class="price-float">{{sidesDrinksDessertsPrices() | currency}}</span>
        </div>
        <div class="add-to-bag-btn col-12">
          <button (click)="addToOrder(); " class="freebirds-btn add-to-bag add-to-bag-float">{{ isEdit ? 'UPDATE ITEM' : 'ADD TO BAG'}}
          </button>
        </div>
      </div>

      <!-- End Footer -->

    </div>
  </div>

  <!-- END INGREDIENTS RIGHT -->

</div>

<ng-template #messageModal>
  <div class="addon-items-modal">
    <div class="header-section text-center">
      <h1 class="garageGothic">Oops there was a problem</h1>
    </div>
    {{errorMessage}}
    <div class="footer-section">
      <div class="footer-content">
        <div class="buttons">
          <button (click)="switchViews(upsell); showButton()" class="freebirds-btn">Try Again</button>
          <button (click)="skipUpsell(); showButton();" class="freebirds-btn-reg">SKIP</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #upsell>
  <div class="addon-items-modal">
    <div class="header-section garageGothic text-center">
      <h1 class="garageGothic">YOU KNOW YOU WANT ONE</h1>
    </div>
    <app-upsell #upsellComponent (onFailure)="onUpSellFail($event)">
      <div class="addon-items-list">
        <app-upsell-item [upsellParent]="upsellComponent" #upsellItem *ngFor="let item of getUpsellItems()"
          [item]="item">
          <div class="addon-item">
            <img src="assets/img/addon-item.png" [alt]="item.name" />
            <div class="addon-controls">
              <button (click)="upsellItem.minusQty()" class="fb-qty-btn">-</button>
              <input type="text" [(ngModel)]="upsellItem.qty" class="qty-box">
              <button (click)="upsellItem.addQty()" class="fb-qty-btn">+</button>
            </div>
            <div class="product-name">
              {{item.name}}
            </div>
            <div class="price">
              ${{item.cost}}
            </div>
          </div>
        </app-upsell-item>
      </div>
      <div class="footer-section">
        <div class="footer-content">
          <div class="subtotal">
            <span>SUBTOTAL:</span>
            <span>${{upsellComponent.total}}</span>
          </div>
          <div class="buttons">
            <button (click)="upsellComponent.submitUpsell(); showButton()" class="freebirds-btn">Add to Bag</button>
            <button (click)="skipUpsell(); showButton()" class="freebirds-btn-reg">SKIP</button>
          </div>
        </div>
      </div>
    </app-upsell>
  </div>
</ng-template>

<ng-template let-cat="cat" #sidesDrinksDessertsTemplate>
  <div class="mx-4">
    <div class="row mb-2 subcat-container" *ngFor="let subCat of subCategoryGroups">
      <h1 class="text-center h1-title garageGothic">
        {{ subCat?.category }}
      </h1>
      <div *ngFor="let prod of subCat.products" class="cat-item col-4 col-sm-3 col-lg-4 col-xl-3 my-2" [ngClass]="{'cat-item-disabled': prod.unavailablehandoffmodes.includes(this.basket?.deliverymode)}">
        <div class="cat-image" (click)="selectSideDrinkDessert(prod)">
          <button [disabled]="prod.unavailablehandoffmodes.includes(this.basket?.deliverymode)" class="btn btn-primary food-button">
            <img [src]="productImages[slug(prod.name)]" onerror="this.src='/assets/img/Default Image.png';"
              class="round-circle d-flex justify-content-center align-items-center m-auto"
              [alt]="prod.name" [class.selected]="selectedSideDrinkDesserts.includes(prod)">
            <div [class.select-check]="!selectedSideDrinkDesserts.includes(prod)">
              <img class="check-mark" [src]="menuEmoji.data.full_url" alt="Option Selected">
            </div>
          </button>
        </div>
        <h5 class="cat-title text-uppercase d-flex justify-content-center m-auto">
          {{ prod.name.includes('-') ? replaceName(prod.name) : prod.name }}
        </h5>
        <ng-container *ngIf="!prod.unavailablehandoffmodes.includes(this.basket?.deliverymode); else notCurrentlyAvailable">
          <h5 class="cat-price text-uppercase" *ngIf="prod.cost > 0">{{ prod.cost | currency:'USD':true:'1.2-2' }}</h5>
          <h5 class="cat-price text-uppercase" *ngIf="prod.plusCost">{{ prod.plusCost | currency:'USD':true:'1.2-2' }}</h5>
        </ng-container>
        <ng-template #notCurrentlyAvailable>
          <h5 class="cat-unavailable text-uppercase">Unavailable for {{ this.basket.deliverymode | handoffMode }}</h5>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-cat="cat" #powerBowlsTemplate>
  <ng-container *ngFor="let prod of cat?.products">
    <div class="cat-item col-5 m-2"
    *ngIf="checkProdName(prod.name)">
      <div class="cat-image" (click)="selectProduct(prod, true)">
        <button class="btn btn-primary food-button bowl-button">
          <img [src]="productImages[slug(prod.name)]" onerror="this.src='/assets/img/Default Image.png';"
            class="round-circle d-flex justify-content-center align-items-center m-auto"
            [alt]="prod.name" [class.selected]="prod === selectedProduct">
            <div [class.select-check]="prod !== selectedProduct">
              <img class="check-mark" [src]="menuEmoji.data.full_url">
            </div>
        </button>
      </div>
      <h5 class="cat-title text-uppercase d-flex justify-content-center m-auto">
        {{ prod.name.includes('-') ? replaceName(prod.name) : prod.name }}</h5>
        <h5 class="cat-price text-uppercase mt-10px" *ngIf="prod.description">{{prod.description}}</h5>
      <h5 class="cat-price text-uppercase" *ngIf="prod.cost > 0">{{ prod.cost | currency:'USD':true:'1.2-2' }}</h5>
      <h5 class="cat-price text-uppercase" *ngIf="prod.plusCost">{{ prod.plusCost | currency:'USD':true:'1.2-2' }}</h5>
    </div>
  </ng-container>
</ng-template>

<ng-template let-cat="cat" #defaultOptionTemplate>
  <div *ngFor="let prod of cat?.products; trackBy: customTrackBy"
    class="col-4 col-sm-3 col-lg-4 col-xl-3  my-2">
    <option-image-checkbox class="cat-item" type="radio"
      name="group_{{ cat.id }}" [value]="prod?.id" [title]="prod.name"
      [subtitle]="prod && prod.cost ? formatCost(prod?.cost) : ''"
      [image]="productImages[slug(prod.name)]" [group]="cat.id"
      [groupName]="cat.description"
      [description]="getItemDesc(prod.name)"
      [premium]="MenuService.getMetadataByKey('isPremium', prod.metadata)"
      [doubleOption]="extraMeat(optionGroups)"
      (itemChanged)="selectProduct(prod, false)"
      (doubleMeatClicked)="addDouble($event, true)"
      [unavailableWarnings]="getUnavailiableHandoffs(prod)"
      >
    </option-image-checkbox>
  </div>
</ng-template>

<ng-template let-cat="cat" #familyMealKit>
  <ng-container *ngFor="let prod of cat?.products">
    <div class="family-item col-12 my-2"
      *ngIf="this.dispatchSelected == 'false' || MenuService.getMetadataByKey('hidedispatch', prod.metadata) != 'true'">
      <button class="btn btn-primary food-button p-0" (click)="selectProduct(prod, true)">
        <img [src]="productImages[slug(prod.name)]" onerror="this.src='/assets/img/Default Image.png';"
            class="round-circle d-flex justify-content-center align-items-center m-auto"
            [alt]="prod.name" [class.selected]="prod === selectedProduct">
      </button>
      <h5 class="cat-title text-uppercase d-flex justify-content-center mb-4 pt-3">
        {{ prod.name.includes('-') ? replaceName(prod.name) : prod.name }}</h5>
      <h5 class="cat-price text-uppercase" *ngIf="prod.cost > 0">{{ prod.cost | currency:'USD':true:'1.2-2' }}</h5>
      <h5 class="cat-price text-uppercase" *ngIf="prod.plusCost">{{ prod.plusCost | currency:'USD':true:'1.2-2' }}</h5>
    </div>
  </ng-container>
</ng-template>
