import {
  AfterContentInit,
  Component,
  OnInit, TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  Validators,
  FormGroup,
  FormArray,
  FormBuilder,
  FormControl,
  ReactiveFormsModule
} from '@angular/forms';
import { Location } from '@angular/common';

// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OptionImageCheckboxComponent } from 'app/components/option-image-checkbox/option-image-checkbox.component';

import { MenuService } from 'app/providers/menu.service';
import { BasketService } from 'app/providers/basket.service';
import { UpsellService } from 'app/providers/upsell.service';

import { Subscription } from 'rxjs';
import {take} from 'rxjs/operators';
import { DineEngineCMSService } from 'app/providers/dine-engine-cms.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {environment} from '../../../../environments/environment';
import has = Reflect.has;
import 'jquery';
import {MetaDataService} from '../../../providers/meta-data.service';
import {ToastService} from '../../../providers/toast.service';
import { Option } from 'app/models/olo.menu.interface';
import { UnavailableItem } from 'app/models/unavailable-item.interface';
import {AnalyticsService} from '../../../providers/analytics.service';

const BASE_URL = environment.imageUrl;

declare const jQuery: any;
declare const fbq: Function;
declare const $: any;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({opacity: 0}),
        animate(800 )
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(5, style({opacity: 0})))
    ])
  ]
})
export class ProductsComponent implements OnInit, AfterContentInit {
  subs: Subscription[] = [];

  restaurantID;
  categoryID;
  categories;
  loading = true;
  // isTiny = window.innerWidth <= 340;

  storage = window.localStorage.getItem('keepSigned')
    ? window.localStorage
    : window.sessionStorage;
  mandatory = {};
  modalRef: any;
  addToCartEnabled = false;
  isFirstTab = false;
  selectedProductID;
  selectedProductName = 'Choose';
  subCategory;
  selectedProduct: any;
  cat: any;
  editQuantity;
  itemFormatSquare: boolean;
  itemFormatFull: boolean;
  orderForm: FormGroup;
  showArrows = false;
  backArrow = true;
  nextArrow = true;
  menuImages = [];
  sortedOptionPills = [];
  showFirstOption = true;
  showSecondOption = true;
  hasPreOptionPage = false;
  preOptionCats = [];
  scrollSections = [];
  selectedModGroup;
  selectedEditProduct;
  subCategoryGroups = [];
  selectedSideDrinkDesserts = [];
  menuEmoji;

  @ViewChildren(OptionImageCheckboxComponent)
  formOptions;
  @ViewChild('beans')
  divBeans;
  @ViewChild('cheese')
  divCheese;
  @ViewChild('rice')
  divRice;
  @ViewChild('fresh')
  divFresh;
  @ViewChild('modalContent')
  modalContent;


  @ViewChild('messageModal', { static: true })
  messageModal: TemplateRef<any>;

  @ViewChild('upsell', { static: true })
  upsell: TemplateRef<any>;

  @ViewChild('ingredients', { static: true })
  ingredients;

  @ViewChild("doubleProduct") doubleMeat;


  optionGroups: any = null; // All option data, used for top level options
  subOptions: any = null; // Extracted modifier option groups so we can show/hide the tree more effectively
  selectedOptGroups = {}; // Selected option group Ids and their value, to handle hide/show of tree of options
  selectedProductOptGroup; // Selected Product Option Group. It is defined after selected the option of product.
  selectedProductOptGroupMod = []; // Selected Product Option Group Modifiers. It is defined after selected the option of product.
  optionHash: any = {}; // Flat object hash of option IDs so we can easily lookup data for optionPills
  optionPills: any = []; // Active option items to display as pills
  grouparray: any = [];
  isEdit = false;
  basket: any;
  basketEditID;
  dispatchSelected: any = false;
  product_instructions: any;

  default_group_id = 0;
  default_option_id = 0;

  productsTypes = null;

  selectedIng = {};
  cmsIngredients = [];
  quan2: any;

  productImages = [];
  ingredientImages = [];
  ingredientHeatLevels = [];

  errorMessage: string;

  sauceList = [
    'Death',
    'Mild Tomatillo',
    'Hot Tomatillo',
    'Lime Juice',
    'BBQ',
    'Habenero',
    'Salsa',
    'Creamy Jalapeno',
    'No Sauce'
  ];
  products;

  feedingagroup: any = false;

  isGroupOrder = false;

  upSellItemsToAdd = [];

  specialItemLoaded = false;

  defaultImageUrl = 'https://cms.freebirds.chepri.com/fb/assets/2uxn4rw56g00888g';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public MenuService: MenuService,
    private BasketService: BasketService,
    private UpsellService: UpsellService,
    private cms: DineEngineCMSService,
    private meta: MetaDataService,
    private toast: ToastService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    this.getEmoji();

    this.cms.getProducts().subscribe((res) => {
      this.products = res;
      this.populateProductImages(res.data);
    });
    this.cms.getIngredientList().subscribe((res) => {
      this.populateIngredientImages(res.data);
    });

    window.scrollTo(0, 0);
    this.getIngredientData();
    this.restaurantID = this.storage.getItem('restaurant_id');
    if (this.restaurantID == null) {
      this.router.navigate(['./locations']);
    }
    this.BasketService.checkCreateBasket(this.restaurantID);

    this.dispatchSelected = this.storage.getItem('dispatchSelected');

    this.route.data.subscribe(data => {
      // this.isEdit = data['edit'];
    });

    this.route.params.subscribe(params => {
      this.categoryID = params['categoryID'];

      if (typeof this.categoryID !== 'undefined' && this.categoryID !== null) {
        this.categoryID = this.categoryID.toUpperCase();
        // window['ChepriPixelTag'].push({
        //   event: 'order_viewed_category',
        //   category: this.categoryID
        // });
        //
        // fbq('trackCustom', 'OrderViewedCategory', {
        //   category: this.categoryID
        // });

      } else {
        this.categoryID = '';
      }
      const squareList = [
        'KIDS',
        'WEEBIRDS-KIDS',
        'NOT-BURRITO',
        'PARTY-WITH-THE-BRD',
        'FEEDING-A-GROUP'
      ];
      this.itemFormatSquare = squareList.includes(this.categoryID);
      if (params['productID']) {
        this.isEdit = true;
        this.selectedProductID = params['productID'];
        this.selectedEditProduct = params['productID'];
      }
      if (params['productName'] && params['productName'] !== '') {
        this.selectedProductName = params['productName'];
      }
      if (this.selectedProductName) {
        const data = { event: 'order_viewed_product', name: this.selectedProductName, id: this.selectedProductID };
        // window['ChepriPixelTag'].push({
        //   event: 'order_viewed_product',
        //   ...params
        // });
      //   window['ChepriPixelTag'].push({
      //     ecommerce: {
      //       currencyCode: 'USD', // Local currency is optional.
      //       impressions: [
      //         {
      //           name: this.selectedProductName, // Name or ID is required.
      //           id: this.selectedProductID,
      //           category: this.categoryID
      //         }
      //       ]
      //     }
      //   });
      //   fbq('trackCustom', 'OrderViewedProduct', {
      //     content_ids: this.selectedProductID,
      //     content_name: this.selectedProductName,
      //     category_id: this.categoryID,
      //     currency: 'USD'
      //   });
      }
      this.basketEditID = params['basketProductID'];
      this.subCategory = params['subCategory'];
    });

    this.route.queryParams.subscribe(params => {
      if ('group_id' in params && 'option_id' in params) {
        this.default_group_id = +params['group_id'];
        this.default_option_id = +params['option_id'];
      }
      if (this.restaurantID != null) {
        this.getProduct();
      }
      if (this.selectedProductID) {
        this.getProductModifiers(true, true);
      }
    });

    this.BasketService.getBasket().subscribe(
      basket => (this.basket = basket),
      error => (this.basket = null)
    );
    this.BasketService.onSetBasket.subscribe(result => {
      this.basket = result;
    });
    this.setupForm();

    if (this.storage.getItem('group_id')) {
      this.isGroupOrder = true;
    }
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.updateOptionPills();
    }, 500); // Make sure we find any default selected options
  }

  replaceName(str) {
    return str.replace('-', '‑');
  }

  closeModal() {
    this.modalRef.close();
  }

  getItemDesc(item) {
    let desc = null;
    if (this.products && this.products.data.length) {
      this.products.data.forEach(prod => {
        if (prod.name === item) {
          desc = prod.ingredients;
        }
      });
    }
    return desc;
  }

  sidesDrinksDessertsPrices() {
    let subtotal = 0;
    if (this.selectedSideDrinkDesserts && this.selectedSideDrinkDesserts.length) {
      this.selectedSideDrinkDesserts.forEach(prod => {
        subtotal = subtotal + prod.cost;
      });
    }
    return subtotal;
  }

  getPosition(element) {
    let xPosition = 0;
    let yPosition = 0;

    while (element) {
      xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
      yPosition += element.offsetTop - element.scrollTop + element.clientTop;
      element = element.offsetParent;
    }

    return { x: xPosition, y: yPosition };
  }

  addOptionPill(option_id) {
    const opt = this.optionHash[option_id];
    if (opt && !opt.invisible && this.optionPills.indexOf(opt) === -1) {
      // Don't add the same option multiple times
      this.optionPills.push(opt);
    }
  }

  inputScroll() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  sortOptionPills() {
    const sort = [];
    this.optionPills.forEach(opt => {
      if (sort.find(s => s.description === opt.description)) {
        sort.find(s => s.description === opt.description).items.push(opt);
      } else {
        const op = {
          description: opt.description,
          items: [opt]
        };
        sort.push(op);
      }
    });
    this.sortedOptionPills = sort;
  }

  isAlreadyInCart(optionsArr?): any {
    // Checks if the same item is already in the cart and should add quantity to it
    let foundItem = false;
    this.basket.products.some(item => {
      if (item.productId === this.selectedProductID) {
        let matching = true;
        if (item.choices.length > 0 && optionsArr) {
          // Makes sure the items have the same options
          item.choices.forEach(choice => {
            if (!optionsArr.includes(choice.optionid)) {
              matching = false;
            }
          });
        }
        if (matching) {
          foundItem = item;
          return true;
        }
      }
    });
    return foundItem;
  }

  modifierPrices() {
    let cost = 0;
    if (this.selectedProduct && this.optionPills && this.optionPills.length) {
      this.optionPills.forEach(opt => {
        cost = cost + opt.cost;
      });
    }
    return cost;
  }

  addToOrder() {
    if (this.cat && this.cat.name === 'Sides, Drinks and Desserts') {
      this.addSidesDrinksDessertsToCart();
      return;
    }

    if (!this.selectedProduct) {
      this.toast.danger('Error editing item in cart' + ': ' + 'Please make a selection for ' + this.cat.name);
      return;
    }
    /*
    if (this.orderForm.invalid && this.isGroupOrder) {
      this.notify.set('Input recipient!');
      return;
    }
    */
    const productID = this.selectedProductID;
    let quantity = 1;
    if (this.quan2) {
      quantity = this.quan2;
    }
    const specialInstructions = this.product_instructions;
    let recipient = '';
    if (this.orderForm && this.orderForm.value.recipient.toString()) {
      recipient = this.orderForm.value.recipient.toString();
    }
    const optionsArr = [];

    // this.formOptions.push(this.grouparray);
    // Gather up all our formOptions values into comma array
    this.formOptions.forEach((opt, index) => {
      if (opt.checked) {
        if (opt.value !== this.selectedProduct.id) {
          optionsArr.push(opt.value);
        }
        // check for nested double meat option
        if (opt.doubleMeat && opt.doubleMeat.nativeElement.checked) {
          optionsArr.push(opt.doubleOption.id);
        }
      }
    });
    if (this.grouparray.length > 0) {
      for (let i = 0; i < this.grouparray.length; i++) {
        optionsArr.push(this.grouparray[i].id);
      }
    }
    const options = optionsArr.join();

    const alreadyInBasket = this.isAlreadyInCart(options);
    if (this.isEdit) {
    if (productID !== this.selectedEditProduct) {
      this.BasketService.removeProductFromBasket(this.basketEditID).subscribe();
      this.isEdit = false;
    }
  }
    if (this.isEdit) {
      if (this.quan2) {
        this.editQuantity = quantity;
      }
      this.BasketService.editProductInBasket(
        productID,
        this.basketEditID,
        this.editQuantity ? this.editQuantity : quantity,
        options,
        specialInstructions,
        recipient
      ).subscribe(
        data => {
          if (this.BasketService.getIsInvitedUser()) {
            this.router.navigate([
              '/order/invited/' + sessionStorage.getItem('basket_id')
            ]);
          } else {
            document.getElementById('basketButton').click();
          }
          this.router.navigate(['/order']);
        },
        error => {
          let message = '.';
          if (error.message) {
            message = ': ' + error.message;
          }
          this.toast.danger('Error editing item in cart' + message);
          // setTimeout(() => {
          //
          // }, 750);
        }
      );
    } else {
      this.BasketService.addProductToBasket(
        productID,
        quantity,
        options,
        specialInstructions,
        recipient
      ).subscribe(
        data => {
          this.analytics.logAddToCart([data.products[data.products.length - 1]], this.cat)
          // window['ChepriPixelTag'].push({
          //   event: 'order_add_item_success',
          //   basket: JSON.stringify(data)
          // });
          // fbq('track', 'AddToCart', {
          //   content_ids: this.selectedProductID,
          //   content_name: this.selectedProductName,
          //   content_type: this.categoryID,
          //   currency: 'USD'
          // });
          // window['ChepriPixelTag'].push({
          //   event: 'addToCart',
          //   basket: JSON.stringify(data),
          //   ecommerce: {
          //     currencyCode: 'USD',
          //     add: {
          //       // 'add' actionFieldObject measures.
          //       products: [
          //         {
          //           //  adding a product to a shopping cart.
          //           name: this.selectedProductName, // Name or ID is required.
          //           id: this.selectedProductID,
          //           category: this.categoryID
          //         }
          //       ]
          //     }
          //   }
          // });
          // const upsells = this.UpsellService.getUpsells();
          // this.router.navigate(['/order/details']);
          if (this.BasketService.getIsInvitedUser()) {
            this.router.navigate([
              '/order/invited/' + sessionStorage.getItem('basket_id')
            ]);
            document.getElementById('basketButton').click();
            this.router.navigate(['/order']);
          } else {
            document.getElementById('basketButton').click();
            // this.openUpSell();
            this.router.navigate(['/order']);
          }
        },
        error => {
          // window['ChepriPixelTag'].push(
          //   {
          //     event:
          //       'order_add_item_fail',
          //     error: JSON.stringify(
          //       error
          //     )
          //   }
          // );
          // fbq('trackCustom', 'OrderAddItemFail', {
          //   error: JSON.stringify(error)
          // });
          let message = '.';
          if (error.message) {
            message = ': ' + error.message;
          }
          setTimeout(() => {
            this.toast.danger('Error adding item to cart' + message);
          }, 750);
        }
      );
    }
  }

  addSidesDrinksDessertsToCart() {
    const products = [];
    if (this.selectedSideDrinkDesserts) {
      this.selectedSideDrinkDesserts.forEach(prod => {
        const product = {
          productid: prod.id,
          quantity: 1,
          specialinstructions: '',
          recipient: '',
          customdata: '',
          choices: []
        };
        products.push(product);
      });
      this.BasketService.addMultipleProductsToBasket(products).subscribe(res => {
        this.analytics.logAddToCart(res.basket.products.slice(res.basket.products.length - (this.selectedSideDrinkDesserts.length)), this.cat)
        if (this.BasketService.getIsInvitedUser()) {
          this.router.navigate([
            '/order/invited/' + sessionStorage.getItem('basket_id')
          ]);
          document.getElementById('basketButton').click();
          this.router.navigate(['/order']);
        } else {
          document.getElementById('basketButton').click();
          this.router.navigate(['/order']);
        }
      });
    }
  }

  extraMeat(mods) {
    if (mods) {
      const extMeat = mods.filter(m => m.description.toString().toUpperCase() === 'EXTRA MEAT?');
      if (extMeat && extMeat.length) {
        return extMeat[0].options[0];
      }
    }
    return null;
  }

  addDouble(event, isProduct) {
    let groups = isProduct ? this.optionGroups : this.selectedProductOptGroup.modifiers;
    groups.forEach(mod => {
    if (mod.description.toString().toUpperCase() === 'EXTRA MEAT?') {
      const option = mod.options[0];
      this.optGroupSelected(option, event, mod);
    }
    })
  }

  slug(name) {
      return name
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9 \-]/g, '')
        .replace(/ +/g, '-');
  }

  skipUpsell() {
    document.getElementById('basketButton').click();
    this.router.navigate(['/order']);
  }

  customTrackBy(index: number, obj: any) {
    if (obj && obj.id) {
      return obj.id;
    }
    return index;
  }

  checkProdName(name) {
    if (this.dispatchSelected === 'true') {
      if (name === 'Fountain Drink') {
        return false;
      } else if (name === 'Fountain Drinks') {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }

  }

  formatCost(cost) {
    if (cost <= 0) {
      return undefined;
    }
    if (typeof cost !== 'number') {
      return cost;
    }

    if (
      this.selectedProduct &&
      this.selectedProduct.cost === 0 &&
      this.selectedProduct.plusCost &&
      cost > this.selectedProduct.plusCost
    ) {
      return '+$' + (cost - this.selectedProduct.plusCost).toFixed(2);
    } else if (
      this.selectedProduct &&
      this.selectedProduct.cost === 0 &&
      this.selectedProduct.plusCost &&
      cost === this.selectedProduct.plusCost
    ) {
      return undefined;
    } else {
      return '+$' + cost.toFixed(2);
    }
  }

  boxLunchSelected(event) {
    const optionsArr1 = [];
    this.grouparray = [];
    this.grouparray.push(event);
    this.updateOptionPills();
    if (this.grouparray.length > 0) {
      for (let i = 0; i < this.grouparray.length; i++) {
        optionsArr1.push(this.grouparray[i].id);
      }
    } else {
      this.removepill();
    }
    const options = optionsArr1.join();

    // this.notify.set(
    //   'Select Quantity',
    //   {
    //     text: 'Back',
    //     cb: () => {
    //       this.removepill();
    //       this.router.navigate(['/order/products/feeding-a-group']);
    //     }
    //   },
    //   {
    //     text: 'Add to Cart',
    //     cb: () => {
    //       // let quan = this.notify.getQuantity();
    //       this.quan2 = this.storage.getItem('groupquantity');
    //       this.addOrder();
    //     }
    //   },
    //   {
    //     quantity: this.quan2
    //   }
    // );
  }

  addOrder() {
    this.addToOrder();
  }

  getProduct() {
    this.MenuService.getMenuForRestaurant(this.restaurantID).subscribe(
      data => {
        this.categories = data;
        let editProduct;
        if (!this.categoryID && this.selectedProductID) {
          this.categories.forEach(cat => {
            cat.products.forEach(prod => {
              if (prod.id.toString() === this.selectedProductID.toString()) {
                editProduct = prod;
                this.categoryID = this.categorySlug(cat.name);
              }
            });
          });
        }
        this.categories.forEach(cat => {
          if (this.MenuService.toParam(cat.name) === this.MenuService.toParam(this.categoryID)) {
            this.cat = cat;
            this.meta.manualUpdate({
              title: `Freebirds World Burrito | ${this.cat.name}`,
              description: this.cat.description ? this.cat.description : this.cat.name
            });
            if (this.preOptionCats.includes(cat.name.toLowerCase())) {
              this.hasPreOptionPage = true;
            } else {
              this.scrollSections.push(cat.name);
            }
            if (cat.name === 'Sides, Drinks and Desserts') {
              this.setSubCategoryGroups(this.cat);
            }
          }
        });
        if (this.isEdit) {
          this.selectProduct(editProduct, false);
        } else {
          if (this.cat && this.cat.products && this.cat.products.length) {
            let index = 0;
            this.cat.products.forEach((prod, i) => {
              if (prod.metadata && prod.metadata.filter(m => m.key === 'default-product')) {
                index = i;
              }
            });
            this.openAllMods(this.cat.products[index]);
          }
        }
      },
      error => {
        let message = '.';
        if (error.message) {
          message = ': ' + error.message;
        }
        this.toast.danger('Error adding item to cart' + message);
      }
    );
  }

  // API call to get option prices for items with no base price
  getZeroCostItems(prod) {
    this.MenuService.getProductModifiers(prod.id).subscribe(data => {
      let lowest = 10000;
      if (data[0].options) {
        data[0].options.forEach(opt => {
          if (opt.cost < lowest) {
            lowest = opt.cost;
          }
        });
      }
      if (lowest !== 10000) {
        prod.plusCost = lowest;
        // prod.cost = lowest;
        prod.lowest = lowest;
      }
    });
  }

  setSubCategoryGroups(cat) {
    cat.products.forEach(prod => {
      if (prod && prod.metadata) {
      if (this.subCategoryGroups && this.subCategoryGroups.find(c => c.category === prod.metadata[0].value)) {
        this.subCategoryGroups.find(s => s.category === prod.metadata[0].value).products.push(prod);
      } else {
        this.subCategoryGroups.push({
          category: prod.metadata[0].value,
          products: [prod]
        });
      }
    }
    });
  }

  getProductModifiers(toScroll: boolean, isInit: boolean) {
    let hasScrolled = false;
    this.MenuService.getProductModifiers(this.selectedProductID).subscribe(
      data => {
        this.setOptionGroups(data);
        this.subOptions = [];
        for (let i = 0; i < data.length; i++) {
          const group = data[i];
          for (let j = 0; j < group.options.length; j++) {
            const option = group.options[j];
            this.optionHash[option.id] = {
              name: option.name,
              key: 'group_' + group.id,
              value: option.id,
              iconid: option.chainoptionid,
              cost: option.cost,
              addition: false, // If option is an addition to an existing item, usually no for top level items
              invisible: false, // Whether we don't show in pills
              description: group.description // For the name of pill image
            };

            if (option.children) {
              this.populateSubOptions(option, group.id);
            }
            if (option.isdefault) {
              const event = {
                target: {
                  checked: true
                },
                isDefault: true
              };
              hasScrolled = true;
              this.optGroupProductSelected(option, option.name, group.id, option.id, event , group);
            }
          }
        }
        if (this.isEdit && isInit) {
          this.setupEdit();
        } else {
          if (!hasScrolled && toScroll) {
            this.scrollToItem(this.scrollSections[1]);
          }
        }
        this.updateOptionPills();
      }
    );


  }

  groupName(name: string) {
    switch (name) {
      case 'Choose Party Platter':
        name = 'Party Platter';
        break;
      case 'Choose Box Lunches':
        name = 'Box Lunches';
        break;
    }
    return name;
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  optGroupProductSelected(option, option_name, group_id, option_id, event, group) {
    for (const group of this.subOptions) {
      if (group.group_id === group_id && group.option_id === option_id) {
        this.selectedProductOptGroup = group;
        group.modifiers.forEach(mod => {
          if (!this.scrollSections.includes(mod.description)) {
          this.scrollSections.push(mod.description);
          }
        });
        this.scrollSections.concat(group.modifiers);
        this.setSelectedProductOptGroupMod(group.modifiers);
      }
    }
    this.optGroupSelected(option, event, group);
  }

  optGroupSelected(option, event, group) {
    this.saveOptionMetadata(option);
    const group_id = this.selectedProductOptGroup.group_id + '_' + group.id;
    if (event.target.checked) {
      // Remove all other items if 'No ___' is checked
      // Remove 'No ___' if other items are checked
      if (group.mandatory === false) {
        this.setNoOptionForMultiSelect(option.name, option.id, group_id)
      }
    if (option && option.description) {
      this.selectedIng[option.description.toUpperCase()] = true;
    }
    this.selectedOptGroups[group_id] = option.id;
    } else {
      if (option && option.description) {
        this.selectedIng[option.description.toUpperCase()] = false;
      }
      delete this.selectedOptGroups[group_id];
    }
    if (option && (option.mandatory || group.mandatory) && group.description !== 'Proteins') {
      let index;
      if (option.description) {
        index = this.scrollSections.indexOf(option.description);
      } else {
        index = this.scrollSections.indexOf(group.description);
      }
      if (event && event.isDefault) {
        this.scrollToItem(this.scrollSections[index]);
      } else {
        this.scrollToItem(this.scrollSections[index + 1]);
    }
    }
    this.updateOptionPills();
  }

  setNoOptionForMultiSelect(option_name, option_id, group_id) {
    if (option_name.includes('No ')) {
      this.formOptions.forEach(opt => {
        const groupidchange = group_id.replace('_', ' ');
        if (opt.group === groupidchange) {
          opt.checked = false;
        }
        if (opt.value === option_id) {
          opt.checked = true;
        }
      });
    } else {
      this.formOptions.forEach(opt => {
        const groupidchange = group_id.replace('_', ' ');
        if (opt.title.includes('No ') && opt.group === groupidchange) {
          opt.checked = false;
        }
      });
    }
  }

  saveOptionMetadata(option: Option) {
    if (option.metadata && option.metadata.find(op => op.key === 'unavailable')) {
      let items = JSON.parse(this.storage.getItem('unavailableItems'));
      if (items && items.length) {
        if (!items.find(item => item.id === option.id)) {
          items.push(option);
          this.storage.setItem('unavailableItems', JSON.stringify(items));
        }
      } else {
        items = [];
        items.push(option);
        this.storage.setItem('unavailableItems', JSON.stringify(items));
      }
    }
  }

  openUpSell() {
    // const config = new ModalConfig();
    // config.isHtml = true;
    // config.disableOkBtn = true;
    // config.modal_classes.push('upsell_modal_wrapper');
    // this.notify.set(this.upsell, null, null, null, null, config);
  }

  populateSubOptions(option, group_id) {
    const opt = {
      name: option.name,
      group_id: group_id,
      option_id: option.id,
      modifiers: option.modifiers
    };

    this.subOptions.push(opt);
    for (let k = 0; k < option.modifiers.length; k++) {
      const mod = option.modifiers[k];
      for (let l = 0; l < mod.options.length; l++) {
        const modOption = mod.options[l];
        this.optionHash[modOption.id] = {
          name: modOption.name,
          key: 'group_' + group_id + '_option_' + option.id + '_mod_' + mod.id,
          value: modOption.id,
          iconid: modOption.chainoptionid,
          cost: modOption.cost,
          addition: true,
          invisible: false,
          description: mod.description
        };

        if (modOption.modifiers) {
          this.optionHash[modOption.id].invisible = true; // We don't want to show a pill for "Show additional options"
          // Yay recursion, some menu options can be like "Some additional options"....   Go figure
          this.populateSubOptions(modOption, group_id + '_' + mod.id);
        }
      }
    }
  }

  onUpSellFail($event) {
    this.errorMessage = $event;
    this.toast.danger(this.messageModal)
  }

  switchViews(view) {
    //
    // this.notify.switchMessage(view);
  }

  removeOptionPill(option_id, uncheck = true) {
    // Uncheck is true when clicked from UI, false when called from update loop
    const opt = this.optionHash[option_id];
    if (this.optionPills.indexOf(opt) > -1) {
      this.optionPills.splice(this.optionPills.indexOf(opt), 1); // Remove from pills array with splice
      const checkBox = jQuery('input[data-value=' + option_id + ']:checked');
      if (uncheck && checkBox.length >= 1) {
        checkBox
          .parent()
          .find('a')[0]
          .click(); // Trigger click on the native element to uncheck it.
      }
    }
    this.sortOptionPills();
  }

  selectProduct(prod, toScroll: boolean) {
    if (prod === this.selectedProduct) {
      this.selectedProductID = null;
      this.selectedProductName = null;
      this.selectedProduct = null;
      this.selectedOptGroups = {};
      this.selectedProductOptGroup = {};
      this.selectedProductOptGroupMod = [];
      this.optionGroups = null;
      this.subOptions = null;
      this.optionHash = {};
      this.optionPills = [];
      this.sortedOptionPills = [];
    } else {
      this.selectedProductID = prod.id;
      this.selectedProductName = prod.name;
      this.selectedProduct = prod;
      this.selectedOptGroups = {};
      this.selectedProductOptGroup = {};
      this.selectedProductOptGroupMod = [];
      this.sortedOptionPills = [];
      this.addToCartEnabled = true;
      if (this.hasPreOptionPage) {
        this.showFirstOption = false;
      }
      this.updateOptionPills();
      this.getProductModifiers(toScroll, false);
      this.analytics.logProductDetailView(prod, this.cat)
    }
  }

  openAllMods(prod) {
    this.MenuService.getProductModifiers(prod.id).subscribe(
      data => {
        this.setOptionGroups(data);
        this.subOptions = [];
        for (let i = 0; i < data.length; i++) {
          const group = data[i];
          for (let j = 0; j < group.options.length; j++) {
            const option = group.options[j];
            this.optionHash[option.id] = {
              name: option.name,
              key: 'group_' + group.id,
              value: option.id,
              iconid: option.chainoptionid,
              cost: option.cost,
              addition: false, // If option is an addition to an existing item, usually no for top level items
              invisible: false, // Whether we don't show in pills
              description: group.description // For the name of pill image
            };

            if (option.children) {
              this.populateSubOptions(option, group.id);
            }
            if (option.isdefault) {
              // this.selectedOptGroups[group.id] = option.id;
              const event = {
                target: {
                  checked: true
                }
              };
              this.optGroupOpenMods(option, option.name, group.id, option.id, event , group);
            }
          }
        }
      }
    );
  }

  optGroupOpenMods(option, option_name, group_id, option_id, event, group) {
    for (const group of this.subOptions) {
      if (group.group_id === group_id && group.option_id === option_id) {
        this.selectedProductOptGroup = group;
        group.modifiers.forEach(mod => {
          if (!this.scrollSections.includes(mod.description)) {
          this.scrollSections.push(mod.description);
          }
        });
      }
    }
  }

  selectSideDrinkDessert(prod) {
    if (!prod.unavailablehandoffmodes.includes(this.basket.deliverymode)) {
      if (this.selectedSideDrinkDesserts.includes(prod)) {
        const prodIndex = this.selectedSideDrinkDesserts.indexOf(prod);
        this.selectedSideDrinkDesserts.splice(prodIndex, 1);
      } else {
        this.selectedSideDrinkDesserts.push(prod);
      }
    }
  }

  setOptionGroups(groups) {
    this.optionGroups = groups;
    groups.forEach(g => {
      if (!this.scrollSections.includes(g.description)) {
        this.scrollSections.push(g.description);
        }
    });
  }

  setSelectedProductOptGroupMod(modifiers) {
    this.selectedProductOptGroupMod = [];
    let ingredients = -1;
    for (let i = 0; i < modifiers.length; i++) {
      if (
        String(modifiers[i].description).toUpperCase() === 'RICE' ||
        String(modifiers[i].description).toUpperCase() === 'CHEESE' ||
        String(modifiers[i].description).toUpperCase() === 'BEANS' ||
        String(modifiers[i].description).toUpperCase() === 'FRESH & HEALTHY'
      ) {
        if (ingredients === -1) {
          const ingredientsMod = {
            description: 'INGREDIENTS',
            options: [modifiers[i]]
          };
          ingredients =
            this.selectedProductOptGroupMod.push(ingredientsMod) - 1;
        } else {
          this.selectedProductOptGroupMod[ingredients].options.push(
            modifiers[i]
          );
        }
      } else {
        this.selectedProductOptGroupMod.push(modifiers[i]);
      }
    }
    this.selectedProductOptGroupMod.forEach(mod => {
      if (typeof mod.mandatory === 'boolean' && mod.mandatory === true) {
        this.mandatory[mod.description.toUpperCase()] = [];
      } else if (typeof mod.mandatory !== 'boolean' && mod.options) {
        mod.options.forEach(subMod => {
          if (subMod.mandatory) {
            if (this.mandatory[mod.description.toUpperCase()]) {
              this.mandatory[mod.description.toUpperCase()].push(
                subMod.description.toUpperCase()
              );
            } else {
              this.mandatory[mod.description.toUpperCase()] = [
                subMod.description.toUpperCase()
              ];
            }
          }
        });
      }
    });
  }

  nl2br(str) {
    return str.replace('\n', '<br />');
  }

  setupEdit() {
    // We have to wait until the form is loaded so we can pre-select the values
    this.BasketService.getBasket().subscribe(basket => {
      this.basket = basket;
      for (let pi = 0; pi < basket.products.length; pi++) {
        const prod = basket.products[pi];
        if (prod.id === parseInt(this.basketEditID, 10)) {
          const choices = prod.choices;
          const chosen: any = [];
          for (let i = 0; i < choices.length; i++) {
            // Select all the options that we wanted
            const opt = choices[i].optionid;
            chosen.push(opt.toString());
            // Delay each option by 100ms so the form has time to update
            setTimeout(() => {
              this.formOptions.forEach((opt, index) => {
                opt.checked = chosen.includes(opt.value.toString()) || (opt.value.toString() === prod.productId.toString());
                if (opt.checked) {
                  if (opt.doubleOption && opt.doubleOption.id && opt.doubleMeat) {
                    opt.doubleMeat.nativeElement.checked = chosen.includes(opt.doubleOption.id.toString());
                  }
                  // Show any group related to it.
                  this.selectedOptGroups[opt.group] = opt.value;
                  if (index === 1 || this.isEdit) {
                    for (const group of this.subOptions) {
                      if (
                        group.group_id === opt.group &&
                        group.option_id === opt.value
                      ) {
                        this.selectedProductOptGroup = group;
                        this.selectedProductOptGroupMod = group.modifiers;
                        this.setSelectedProductOptGroupMod(group.modifiers);
                        break;
                      }
                    }
                  }
                }
              });
            }, 500 * i);
          }
          setTimeout(() => {
            this.updateOptionPills();
          }, 100 * choices.length);
          break;
        }
      }
    });
  }

  setupForm() {
    this.orderForm = this.formBuilder.group({
      specialInstructions: [''],
      recipient: ['', Validators.required]
    });
  }

  shouldHideGroupOptions(group_id, option_id) {
    if (
      this.selectedOptGroups[group_id] &&
      this.selectedOptGroups[group_id] === option_id
    ) {
      return false;
    }
    return true;
  }

  changedItem = false;

  startOver() {
    const scroll = document.getElementById('tab-content-scroll');
    scroll.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  }

  subTotal() {
    let subTotal = this.selectedProduct ? this.selectedProduct.cost : 0;
    if (this.optionPills.length > 0) {
      for (let i = 0; i < this.optionPills.length; i++) {
        subTotal += this.optionPills[i].cost;
      }
    }
    return subTotal;
  }

  updateOptionPills() {
    const self = this;
    self.optionPills = [];
    this.sortedOptionPills = [];
    if (this.formOptions && this.formOptions.length) {
      this.formOptions.forEach((opt, index) => {
        if (opt.checked) {
          self.addOptionPill(opt.value);
          if (opt.doubleMeat) {
            if (opt.doubleMeat.nativeElement.checked) {
              self.addOptionPill(opt.doubleOption.id);
            } else {
              self.removeOptionPill(opt.doubleOption.id, true);
            }
          }
        } else {
          self.removeOptionPill(opt.value, true);
        }
      });
    }
    if (this.grouparray && this.grouparray.length) {
      this.grouparray.forEach((opt, index) => {
        self.addOptionPill(opt.id);
      });
    }
    this.sortOptionPills();
  }

  removepill() {
    const self = this;
    this.grouparray.forEach((opt, index) => {
      self.removeOptionPill(opt.id, true);
    });
    this.grouparray = [];
    this.updateOptionPills();
  }

  // CMS MENU IMAGES
  getIngredientData() {
    this.cms.getTable('ingredients').subscribe(
      res => {
        res.forEach(ingredient => {
          const image = ingredient.image ? BASE_URL + ingredient.image.data.url : '';
          const hasHeat = ingredient.has_heat;
          const heatLevel = ingredient.heat_level;
          this.cmsIngredients[ingredient.name] = {
            image: image,
            hasHeat: hasHeat,
            heatLevel: heatLevel
          };
        });
      }
    );
  }

  clickback() {
    location.reload();
  }

  hideButton() {
    $('button.add-to-bag-float').fadeOut();
  }

  showButton() {
    $('button.add-to-bag-float').fadeIn();

  }

  populateProductImages(data) {
    data.forEach(product => {
      if (product.image) {
        this.productImages[product.name_slug] = product.image.data.full_url;
      } else {
        this.productImages[product.name_slug] = this.defaultImageUrl;
      }
    });
  }

  populateIngredientImages(data) {
    data.forEach(ingredient => {
      if (ingredient.image) {
        this.ingredientImages[ingredient.name_slug] = ingredient.image.data.thumbnails[7].url;
      } else {
        this.ingredientImages[ingredient.name_slug] = this.defaultImageUrl;
      }
      this.ingredientHeatLevels[ingredient.name_slug] = ingredient.heat_level;
    });
    console.log(this.ingredientImages)
  }

  categorySlug(categoryName) {
    return categoryName
      .toLowerCase()
      .trim()
      .replace(/[^a-z \-]/g, '')
      .replace(/ +/g, '-');
  }

  catName() {
    let name = '';
    if (this.cat) {
      name = this.cat.name;
    }
    return name;
  }

  scrollToItem(group) {
    const scrollItem = document.getElementById('scroll_' + group);
    const scrollSection = document.getElementById('tab-content-scroll');
    if (scrollItem && scrollSection) {
      scrollSection.scrollTo({top: scrollItem.offsetTop - 248, // minus 248 to account for header items
        left: 0,
        behavior: 'smooth'});
    }
  }

  onSectionChange(section: any) {
    if (section && section.section) {
      this.selectedModGroup = section.section;
      section = section.section.replace('scroll_', 'subScroll_');
      const scrollItem = document.getElementById(section);
      const subnavScroll = document.getElementById('subnavScroll');
      const left = scrollItem.offsetLeft;
      const itemWidth = scrollItem.offsetWidth;
      const screenwidth = document.documentElement.clientWidth;
      const scrollTo = left - (screenwidth / 2) + (itemWidth / 2);
      subnavScroll.scrollTo({
        top: 0,
        left: scrollTo,
        behavior: 'smooth'
      });
    }
  }

  getUpsellItems(): any[] {
    return []
  }

  getEmoji() {
    this.subs.push(this.cms.getBranding().pipe(take(1)).subscribe((res) => {
      if (res.data.menu_emoji) {
        this.menuEmoji = res.data.menu_emoji;
      }
    }));
  }

  getUnavailiableHandoffs(option: Option) {
    let warningItem: UnavailableItem = {
      status: null,
      message: null
    };
    if (option.metadata && option.metadata.find(op => op.key === 'unavailable')) {
      const disabledHandoffs = option.metadata.find(op => op.key === 'unavailable').value;
      if (disabledHandoffs.includes(this.basket.deliverymode)) {
        warningItem.status = 'disabled';
        warningItem.message = 'Unavailable for ' + this.basket.deliverymode;
      } else if (disabledHandoffs.includes('curbside') && (this.basket.deliverymode === 'pickup' || this.basket.deliverymode === 'drivethru')) {
        warningItem.status = 'warning';
        warningItem.message = 'Unavailable for Curbside';
      }
    }
    return warningItem;
  }
}
