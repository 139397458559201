import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrderComponent } from './order.component';
import { SubcategoriesComponent } from './subcategories/subcategories.component';
import { ProductsComponent } from './products/products.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { DetailsComponent } from './details/details.component';
import { ReviewComponent } from './review/review.component';
import { LikeToAddComponent } from './like-to-add/like-to-add.component';
import { RevItComponent } from './rev-it/rev-it.component';

const routes: Routes = [
  {
    path: '',
    component: OrderComponent,
  },

  {
    path: 'confirm',
    component: ConfirmComponent,
  },
  {
    path: 'details',
    component: DetailsComponent,
    pathMatch: 'full',
  },
  {
    path: 'invited/:basketID',
    component: OrderComponent,
  },
  {
    path: 'joingroup/:groupID',
    component: OrderComponent,
  },
  {
    path: 'products/:categoryID',
    component: ProductsComponent,
  },
  {
    path: 'products/edit/:productID/:basketProductID/:quantity',
    component: ProductsComponent,
    data: { edit: true },
  },
  {
    path: 'review',
    component: ReviewComponent,
  },
  {
    path: 'sub-categories/:categoryID',
    component: SubcategoriesComponent,
  },
  {
    path: 'like-to-add',
    component: LikeToAddComponent,
  },
  {
    path: 'rev-it',
    component: RevItComponent,
  },
  {
    path: ':locationSlug',
    component: OrderComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class OrderRoutingMoudle {}
