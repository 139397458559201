import {FacebookLoginProvider, SocialAuthServiceConfig} from 'angularx-social-login';

export function getAuthServiceConfigs() {
  return {
    autoLogin: false,
    providers: [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider('730758447059477')
      }
    ]
  } as SocialAuthServiceConfig;
}
