import {Component, OnInit, TemplateRef} from '@angular/core';
import {ToastService} from '../../providers/toast.service';

@Component({
  selector: 'app-toast',
  template: `
    <ngb-toast
        *ngFor="let toast of toastService.toasts"
        [class]="toast.classname"
        [autohide]="true"
        [delay]="toast.delay || 4000"
        (hidden)="toastService.remove(toast)"
    >
      <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      </ng-template>

      <ng-template #text>{{ toast.textOrTpl }}</ng-template>
    </ngb-toast>
  `,
  styleUrls: ['./toast.component.scss'],
  host: {
    'class': 'sanFran'
  }
})
export class ToastComponent {

  constructor(public toastService: ToastService) {
  }

  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }


}
