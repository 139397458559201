import {Component, OnDestroy, OnInit} from '@angular/core';
import {DineEngineCMSService} from '../../providers';
import {Subscription} from 'rxjs';
import {Location} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';


@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit, OnDestroy {
  global_images: Subscription;
  sub: Subscription;
  header_image: any;
page;
  selectedValue;
  emptyJob;
  BASE_URL = environment.imageUrl;

  states: any = [];
  currentState: any;
  cities: any = [];
  currentCity: any;
  // chosenBusinesses: Array<BusinessLocation>;
  // currentBusiness: BusinessLocation;
  // openJobs: Array<OpenJob>;
  // currentJob: OpenJob;
  // businesses: Array<BusinessLocation>;

  constructor(
    private cms: DineEngineCMSService,
    // private jobService: JobsService,
    private location: Location,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    // CMS SUBSCRIPTIONS
    // window.location.href = 'https://nowhiring.com/freebirdscareers/';
    this.populate_page_CMS();
  }

  ngOnDestroy() {
    // CMS UNSUBSCRIPTIONS
    this.sub.unsubscribe();
  }

  goBack() {
    this.location.back();
  }



  // CMS PAGE SPECIFIC
  populate_page_CMS() {
    // this.sub = this.cms.getCollection('careers').subscribe(
    //   (res: CmsGeneral) => {
    //     this.page = res.data[0];
    //   }
    // );
  }



  allowHTML(content: string) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
}
