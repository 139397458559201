import {map, take} from 'rxjs/operators';
import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {Basket} from '../../../models/basket';
import {BasketService} from 'app/providers/basket.service';
import {PunchService} from 'app/providers/punch.service';
import {RestaurantService} from 'app/providers/restaurant.service';
import {Restaurants} from '../../../models/restaurants';
import {environment} from '../../../../environments/environment';
import {Subscription} from 'rxjs';
import {DineEngineCMSService, MenuService} from '../../../providers';
import {DecimalPipe} from '@angular/common';
import {ToastService} from '../../../providers/toast.service';
import {OloError} from '../../../models/olo.error.interface';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {AnalyticsService} from '../../../providers/analytics.service';

declare const fbq: Function;

@Component({
  selector: 'app-order-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  providers: [DecimalPipe]
})
export class DetailsComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  basket: Basket;
  basketProducts = {};
  restaurant: Restaurants;
  dates = [];
  hours = {};
  dispatchSelected: any = false;
  contactInfoForm: FormGroup;
  schemes: any;
  auth: any;
  userLoggedIn = false;
  rewardsPresent = false;
  QualifyingRewards: any;
  rewardsButton = 'Apply';
  AppliedReward: any;
  appliedRewardId: any;
  whenType = 'asap';
  whenType_date = '';
  whenType_dateArray = [];
  whenType_time: any = '';
  whenType_timeArray = {};
  carInfoForm;
  tipForm: FormGroup;
  customFields: any;
  amount: any;
  // @ViewChild('date') dateField;
  // @ViewChild('time') timeField;
  @ViewChild('coupon') couponField;

  isCurbside = false;

  whentype = 'asap';
  selectedDate: string = null;
  selectedHour: string = null;
  isActive = true;

  refreshing = false;
  isGroupOrder = false;
  groupOrder_deadline = '';
  hasAuthInfo = true;
  authUserInfo: any  = {
    first_name: '',
    last_name: '',
    email: '',
    phonenumber: '',
    // use_info: [false, Validators.required],
    terms: true
  };
  aheadOnly = false;
  signUpCopy = 'Use this information to make an account.';

  windowWidth = window.innerWidth;

  storage = window.localStorage.getItem('keepSigned')
    ? window.localStorage
    : window.sessionStorage;
  constructor(
    private basketService: BasketService,
    private punchService: PunchService,
    private restaurantService: RestaurantService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private changer: ChangeDetectorRef,
    private cms: DineEngineCMSService,
    private decimal: DecimalPipe,
    private toast: ToastService,
    private analytics: AnalyticsService,
    private menu: MenuService
  ) {}

  @HostListener('window:resize')
  watchResize() {
    this.windowWidth = window.innerWidth
  }
  ngOnInit() {

    // this.appliedRewards();
    // setInterval(()=>{
    //   // console.log(this.whenType_timeArray)
    // }, 1000)
    this.getSignUpCopy();

    this.carInfoForm = this.formBuilder.group({
      make: ['', Validators.required],
      model: ['', Validators.required],
      color: ['', Validators.required]
    });

    this.tipForm = this.formBuilder.group({
      tip: [0]
    });

    this.tipForm.valueChanges.subscribe((changes) => {
      if (changes.tip && changes.tip !== Number(this.decimal.transform(changes.tip, '1.2-2'))) {
        this.tip().patchValue(Number(this.decimal.transform(changes.tip, '1.2-2')))
      }
    })
    this.AppliedReward = this.storage.getItem('AppliedReward');
    this.appliedRewardId = this.storage.getItem('appliedRewardId');
    this.contactInfoForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
          )
        ])
      ],
      phonenumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/
          )
        ])
      ],
      signup: [false],
      password: ['', Validators.compose([Validators.pattern(/^(.*)$/)])],
      confirm: ['', Validators.compose([Validators.pattern(/^(.*)$/)])],
      terms: [false, Validators.required]
    });

    if (sessionStorage.getItem('group_id')) {
      this.isGroupOrder = true;

      this.basketService
        .getGroupOrder(
          sessionStorage.getItem('group_id'),
          this.storage.getItem('auth')
        )
        .subscribe(
          res => {
            this.groupOrder_deadline = this.formatOrderDeadlineDateTime(
              res.deadline
            );
          },
          () => {}
        );
    }

    this.basketService.getBasket().subscribe(basket => {
      this.basket = basket;
      this.menu.getMenuForRestaurant(basket.vendorid).subscribe(menu => {
        this.restaurantService.getRestaurantById(basket.vendorid).subscribe(restuarant => {
          this.analytics.logCheckoutDetail(basket.products, menu, restuarant)
        })
      })
      this.isCurbside = this.basket.deliverymode === 'curbside'
      this.tip().patchValue(this.basket.tip);
      if (this.basket.tip === 0 && this.basket.suggestedtipamount) {
        this.addTip(this.basket.subtotal * 0.1, true);
      }
      this.customFields = basket.customfields;
      if (this.isGroupOrder) {
        this.formatBasketProducts();
      }
      this.getHours();
      // this.storage.setItem('basket',this.basket);
      this.basketService.setContactInfo('', '', '', '');
      this.basketUpdated();
    });
    this.basketService.onSetBasket.subscribe(result => {
      this.basket = result;
      this.isCurbside = this.basket.deliverymode === 'curbside'
      if (this.isGroupOrder) {
        this.formatBasketProducts();
      }
    });
    this.restaurant = JSON.parse(this.storage.getItem('restaurant'));
    this.dispatchSelected = this.storage.getItem('dispatchSelected');
    this.aheadOnly = false;
    let hours: string[];
    if (this.restaurant.hours) {
      hours = this.restaurant.hours.split('-');
      this.buildTime(hours);
    } else {
      this.addHours(this.restaurant).subscribe(() => {
        hours = this.restaurant.hours.split('-');
        this.buildTime(hours);
      });
    }
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }

  buildTime(hours) {
    if (hours) {
      if (moment().format('YYYYMMDDHHmmss') < moment(hours[0],
        [moment.ISO_8601, 'hh:mm a']).format('YYYYMMDDHHmmss') || moment().format('YYYYMMDDHHmmss') > moment(hours[1],
        [moment.ISO_8601, 'hh:mm a']).format('YYYYMMDDHHmmss')) {
        this.aheadOnly = true;
        this.setWhenType('date');
      }
    } else {
      this.aheadOnly = true;
      this.setWhenType('date');
    }
    this.hasAuthInfo = !!this.storage.getItem('auth')
    if (this.storage.getItem('auth')) {
      this.userLoggedIn = true;
      this.auth = this.storage.getItem('auth');
      this.getMembershipId();

      this.punchService
        .getUserInfo()
        .subscribe(res => {
          this.authUserInfo = res
          this.hasAuthInfo = true;
        });
    }
  }

  inputScroll() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  formatOrderDeadlineDateTime(datetime: string) {
    let formatDate =
      datetime.substr(4, 2) +
      '/' +
      datetime.substr(6, 2) +
      '/' +
      datetime.substr(2, 2) +
      ' ';
    const hour = parseInt(datetime.substr(9, 2), 10);
    const minute = datetime.substr(12, 2);

    if (hour < 12) {
      formatDate += hour + ':' + minute + ' AM';
    } else if (hour === 12) {
      formatDate += hour + ':' + minute + ' PM';
    } else if (hour === 24) {
      formatDate += hour - 12 + ':' + minute + ' AM';
    } else {
      formatDate += hour - 12 + ':' + minute + ' PM';
    }
    return formatDate;
  }

  formatBasketProducts() {
    if (this.basket === null) {
      this.basketProducts = {};
    } else {
      this.basketProducts = {};
      const products = this.basket.products;
      for (let i = 0; i < products.length; i++) {
        const key = String(products[i].recipient).toUpperCase();
        if (this.basketProducts[key]) {
          this.basketProducts[key].push(products[i]);
        } else {
          this.basketProducts[key] = [products[i]];
        }
      }
    }
  }

  addTip(amount: number, init: boolean) {
    amount = Number(this.decimal.transform(amount, '1.2-2'))
    this.tip().patchValue(amount)
    this.basketService.addTip(amount).subscribe(() => {
      this.basketService.getBasket(this.basket.id).subscribe(basket => {
        this.tip().patchValue(basket.tip)
        this.basket = basket
        if (!init) {
          this.toast.success('Tip Added')
        }
      })
    }, err => {
      let message = '.';
      if (err.message) {
        message = ': ' + err.message;
      }
      this.toast.danger('Error adding tip' + message)
    });
  }

  applyCoupon() {
    const code = this.couponField.nativeElement.value;
    if (code && code.length > 1) {
      this.basketService.applyCoupon(code).subscribe(
        () => {},
       (error: OloError) => {
          this.toast.danger(error.message)
        }
      );
    }
  }

  basketUpdated() {
    if (this.basket.timewanted) {
      const split = this.basket.timewanted.split(' ');
      this.whenType_date = split[0];
      this.whenType_time = {
        label: this.formatTime(this.basket.timewanted),
        value: split[1]
      };
      this.whenType = 'date';
    } else {
      this.whenType_date = '';
      this.whenType_time = '';
    }
  }

  formatTime(datetime: string, format = 'h:mm a') {
    return moment(datetime, 'YYYYMMDD HH:mm').format(format);
  }

  changeLocation() {
    alert('Sorry this is not currently supported.');
  }

  checkout() {
    this.curbsideCheck();
    if (this.hasAuthInfo === false && this.contactInfoForm.invalid) {
      if (!this.contactInfoForm.controls.first_name.valid) {
        this.toast.danger(`Please enter a valid first name in the checkout form.`)
        // this.notify.set(
        //   `Please enter a valid first name in the checkout form.`
        // );
      } else if (!this.contactInfoForm.controls.last_name.valid) {
        this.toast.danger(`Please enter a valid last name in the checkout form.`
        )
        // this.notify.set(
        //   `Please enter a valid last name in the checkout form.`
        // );
      } else if (!this.contactInfoForm.controls.email.valid) {
        this.toast.danger(`Please enter a valid email address in the checkout form.`)
        // this.notify.set(
        //   `Please enter a valid email address in the checkout form.`
        // );
      } else if (!this.contactInfoForm.controls.phonenumber.valid) {
        this.toast.danger(`Please fill in a valid phone number in the checkout form.`)
        // this.notify.set(
        //   `Please fill in a valid phone number in the checkout form.`
        // );
      } else if (!this.contactInfoForm.controls.terms.valid) {
        this.toast.danger(`Please agree to the terms & conditions before continuing.`)
        // this.notify.set(
        //   `Please agree to the terms & conditions before continuing.`
        // );
      }
      return;
    }

    let values: any;
    if (this.hasAuthInfo) {
      values = {
        first_name: this.authUserInfo.first_name,
        last_name: this.authUserInfo.last_name,
        email: this.authUserInfo.email,
        phonenumber: this.authUserInfo.phone,
        // use_info: [false, Validators.required],
        terms: true
      };
    } else {
      values = this.contactInfoForm.value;
    }

    this.storage.setItem('first_name', values.first_name);
    this.storage.setItem('dispatch_phone', values.phonenumber);
    this.basketService.setContactInfo(
      values.first_name,
      values.last_name,
      values.email,
      values.phonenumber
    );

    if (this.contactInfoForm.get('signup').value === true) {
      const userInfo = this.contactInfoForm.value;
      if (userInfo.password !== '') {
        if (userInfo.password === userInfo.confirm) {
          this.submit(this.contactInfoForm.value);
        } else {
          this.toast.warning(`Passwords do not match.`)
          return;
        }
      } else {
        this.toast.warning(`Password cannot be empty.`
        )
        // this.notify.set(
        //   `Password cannot be empty.`
        // );
        return;
      }
    } else {
      this.router.navigate(['/order/review']);
    }
  }

  curbsideCheck() {
    if (this.basket.deliverymode !== 'curbside') {
      return;
    }
    if (this.carInfoForm.invalid) {
      if (!this.carInfoForm.controls.make.valid || !this.carInfoForm.controls.model.valid || !this.carInfoForm.controls.color.valid) {
        this.toast.danger('Vehicle Info Invalid')
        // this.notify.set(`Please enter a correct Car Information in the form.`);
      }
      return;
    }
    this.customFields[0].value = this.carInfoForm.value.make;
    this.customFields[1].value = this.carInfoForm.value.model;
    this.customFields[2].value = this.carInfoForm.value.color;
    const make = {
      id: this.customFields[0].id,
      value: this.carInfoForm.value.make
    };
    const model = {
      id: this.customFields[1].id,
      value: this.carInfoForm.value.model
    };
    const color = {
      id: this.customFields[2].id,
      value: this.carInfoForm.value.color
    };
    forkJoin({
      make: this.basketService.addCustomField(make),
      model: this.basketService.addCustomField(model),
      color: this.basketService.addCustomField(color)
    }).subscribe(() => {},
      error => this.toast.danger(error.message))
    // this.basketService.addCustomField(make).subscribe(() => {
    //   this.basketService.addCustomField(model).subscribe(() => {
    //     this.basketService.addCustomField(color).subscribe(() => {
    //     });
    //   });
    // }, () => {
    //   this.notify.set('Please Input Correct Make of Your Car');
    // });
  }

  submit(data) {
    let PunchhToken;
    let PunchUserId;
    window.sessionStorage.removeItem('PunchhToken');
    window.sessionStorage.removeItem('PunchAuthToken');
    window.sessionStorage.removeItem('auth');

    const form = {
      client: environment.punchClientId,
      user: {
        email: data.email,
        password: data.password,
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.phonenumber
      }
    };
    const form1 = JSON.stringify(form);
    this.punchService.signup(form1).subscribe(
      res => {
        PunchhToken = res.access_token;
        PunchUserId = res.user_id;
        window.sessionStorage.setItem(
          'PunchAuthToken',
          res.authentication_token
        );
        window.sessionStorage.setItem('PunchhToken', PunchhToken);
        this.deleteAllCookies();
        window.sessionStorage.setItem('email', data.email);
      },
      err => {
        if (err.email) {
          this.toast.danger('Email: ' + err.email[0])
          // this.notify.set(
          //   'Email: ' + err.email[0]
          // );
        } else if (err.password) {
          this.toast.danger('Password: ' + err.password[0])
          // this.notify.set(
          //   'Password: ' + err.password[0]
          // );
        } else if (err.phone) {
          this.toast.danger('Phone: ' + err.phone[0])
          // this.notify.set(
          //   'Phone: ' + err.phone[0]
          // );
        }
      },
      () => {
        const oloBody = {
          provider: 'punchh',
          providertoken: PunchhToken,
          // contactnumber: data.phonenumber,
          basketid: '',
          authtoken: '',
          provideruserid: PunchUserId
        };
        this.punchService.getOloToken(oloBody).subscribe(
          res => {
            if (res) {
              window.sessionStorage.setItem('auth', res.authtoken);
            }
            this.router.navigate(['/order/review']);
          },
          error => {
            this.toast.danger(error.message)
            // this.notify.set(
            //   error.message
            // );
          }
        );
      }
    );
    // this.getLoyalty();
  }

  formatDate(date, format: string = 'MM/DD - dddd') {
    return moment(date).format(format);
  }

  getHours() {
    this.restaurantService.getRestaurantHours(this.restaurant.id).subscribe(
      calendars => {
        this.whenType_dateArray = [];
        const ranges = calendars[0].ranges;
        const makeHours = {};
        ranges.forEach(obj => {
          let start = obj.start;
          const end = obj.end;
          const date = start.split(' ')[0];
          this.whenType_dateArray.push(date);
          const earlist = moment(
            this.basket.earliestreadytime,
            'YYYYMMDD HH:mm'
          );
          if (moment(start, 'YYYYMMDD hh:mm') < earlist) {
            // start = earlist.format('YYYYMMEE showest');
            start = earlist;
          }
          makeHours[date] = [];
          makeHours[date] = this.intervals(start, end);
        });
        this.whenType_timeArray = makeHours;
      }
    );
  }

  addHours(restaurant: Restaurants) {
    return this.restaurantService
      .getRestaurantHoursForToday(restaurant.id).pipe(
      map(calendars => {
        try {
          const range = calendars[0].ranges[0];
          const open = moment(range.start, 'YYYYMMDD hh:mm').format('h:mm a');
          const close = moment(range.end, 'YYYYMMDD hh:mm').format('h:mm a');
          this.restaurant.hours = `${open}-${close}`;
          return this.restaurant;
        } catch (error) {
          console.error(error);
        }
      }));
  }

  inputNewContact() {
    this.hasAuthInfo = false;
  }

  intervals(startString, endString) {
    const start = moment(startString, 'YYYYMMDD hh:mm');
    const end = moment(endString, 'YYYYMMDD hh:mm');

    // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
    // note that 59 will round up to 60, and moment.js handles that correctly
    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    const result = [];
    const current = moment(start);

    while (current <= end) {
      result.push({
        value: current.format('HH:mm'),
        label: current.format('h:mm a')
      });
      current.add(15, 'minutes');
    }
    return result;
  }

  removeCoupon() {
    this.basketService.removeCoupon().subscribe(
      (basket: Basket) => (this.basket = basket),
      error => {
        let message = '. Please try again!';
        if (error) {
          message = ': ' + error.message;
        }
        this.toast.danger(`There was a problem removing the coupon` + message)
        // this.notify.set(`There was a problem removing the coupon` + message);
      }
    );
  }

  removeProductFromBasket(basket_product_id, name) {
    if (this.basket.products.find(prod => prod.productId === basket_product_id)) {
      this.menu.getMenuForRestaurant(this.basket.vendorid).subscribe(menu => {
        this.analytics.logRemoveFromCart(this.basket.products.find(prod => prod.productId === basket_product_id), menu)
      })
    }
    this.basketService
      .removeProductFromBasket(basket_product_id)
      .subscribe((basket: Basket) => {
            this.basketService
              .removeProductFromBasket(basket_product_id)
              .subscribe(res => {
                // window['ChepriPixelTag'].push({
                //   event: 'removeFromCart',
                //   basket: JSON.stringify(res),
                //   ecommerce: {
                //     currencyCode: 'USD',
                //     remove: {
                //       // 'add' actionFieldObject measures.
                //       products: [
                //         {
                //           //  adding a product to a shopping cart.
                //           name: name
                //         }
                //       ]
                //     }
                //   }
                // });
                // fbq('trackCustom', 'RemoveFromCart', {
                //   basket: JSON.stringify(res),
                //   currencyCode: 'USD',
                //   name: name
                // });
              });
        this.basket = basket;
        location.reload();
      });
  }

  setBasketTime() {
    if (this.whenType === 'asap') {
      this.basketService.setTimeWanted(null).subscribe(
        (data: Basket) => {
          this.basket = data;
          this.basketUpdated();
        },
        error => {
          let message = '. Please try again!';
          if (error) {
            message = ': ' + error.message;
          }
          this.toast.danger(`There was a problem selecting time` + message)
          // this.notify.set(`There was a problem selecting time` + message);
        }
      );
    } else {
      const dateTime = this.whenType_date + ' ' + this.whenType_time['value'];
      this.basketService.setTimeWanted(dateTime).subscribe(
        (data: Basket) => {
          this.basket = data;
          this.basketUpdated();
        },
        error => {
          this.whenType_time = '';
          this.getHours();
          let message = '. Please try again!';
          if (error) {
            message = ': ' + error.message;
          }
          setTimeout(() => {
            this.toast.danger(`There was a problem selecting time` + message)
            // this.notify.set(`There was a problem selecting time` + message);
          }, 750);
        }
      );
    }
  }

  setWhenType(value) {
    this.whenType = value;
    this.whenType_date = '';
    this.whenType_time = '';
    if (this.whenType === 'asap') {
      this.setBasketTime();
    }
  }

  setWhenTypeDate(date) {
    this.whenType_date = date;
  }

  setWhenTypeTime(time) {
    this.whenType_time = time;
    this.setBasketTime();
  }

  updateProductQuantity(event, product) {
    this.updadeProductQuantityInBasket(product, event.target.value);
  }

  updadeProductQuantityInBasket(basket_product: any, quantity: number) {
    if (quantity < 1) {
      quantity = 1;
    }
    basket_product.quantity = quantity;
    this.basketService.updateProductInBasket(basket_product).subscribe(
      (basket: Basket) => {
        this.basket = basket;
      },
      error => {
        let message = '.';
        if (error.message) {
          message = ': ' + error.message;
        }
        setTimeout(() => {
          this.toast.danger('Error adding item to cart' + message)
          // this.notify.set('Error adding item to cart' + message);
        }, 750);
      }
    );
  }

  refresh() {
    this.refreshing = true;
    this.basketService.getBasket().subscribe(() => {
      setTimeout(() => {
        this.refreshing = false;
      }, 1000);
    });
  }

  getMembershipId() {
    this.basketService.getLoyaltyid().subscribe(
      res => {
        if (res.schemes.length > 0) {
          const membershipId = res.schemes[0].membership.id;
          this.storage.setItem('membershipId', JSON.stringify(membershipId));
          this.getQualifyingReward();
        }
      },
      () => {}
    );
  }
  getQualifyingReward() {
    this.basketService.getLoyaltyrewards().subscribe(
      res => {
        this.QualifyingRewards = res.rewards;
        this.analytics.logPromotionListView(res.rewards)
        this.rewardsPresent = res.rewards.length > 0;
      },
      () => {}
    );
  }
  applyRewards(reference) {
    const reward = this.QualifyingRewards.find(r => r.reference === reference)
    if (reward) {
      this.analytics.logPromotionClick(reward, this.QualifyingRewards.indexOf(reward))
    }
    this.basketService.applyRewards(reference).subscribe(
      res => {
        const rewardid = res.appliedrewards[0].rewardid;
        this.storage.setItem('rewardsId', rewardid);
        this.rewardsButton = 'Applied';
        this.validateBasket(true);
      },
      error => {
        let message = '. Please try again!';
        if (error) {
          message = ': ' + error.message;
        }
        this.toast.danger(`There was a problem adding the coupon` + message)
      }
    );
  }

  applyTip() {
    this.addTip(this.tip().value, false);
  }

  deleteReward(rewardid) {
    this.basketService.deleteRewards(rewardid).subscribe(
      () => {
        this.AppliedReward = '';
        this.appliedRewardId = '';
        this.storage.removeItem('AppliedReward');
        this.storage.removeItem('appliedRewardId');
        this.basketService.getBasket().subscribe(basket => {
          this.basket = basket;
        });
        // location.reload();
      },
      () => {}
    );
  }
  validateBasket(fromReward?: boolean) {
    this.basketService.validate().subscribe(
      () => {
        if (fromReward) {
          this.toast.success('Reward Applied!')
        }
        // const message = 'Congratulations! <br/>';
        // this.notify.set(message + `You added this coupon`);
        this.appliedRewards();
      },
      error => {
        const rewardsId = this.storage.getItem('rewardsId');
        this.deleteReward(rewardsId);
        let message = '. Please try again!';
        if (error) {
          message = ': ' + error.message;
        }
        this.toast.danger(`There was a problem adding the coupon` + message);
      }
    );
  }

  appliedRewards() {
    this.basketService.appliedRewards().subscribe(
      res => {
        this.appliedRewardId = res.rewards[0].rewardid;
        this.AppliedReward = res.rewards[0].label;
        this.storage.setItem('AppliedReward', this.AppliedReward);
        this.storage.setItem('appliedRewardId', this.appliedRewardId);
        this.basketService.getBasket().subscribe(basket => {
          this.basket = basket;
        });
      },
      () => {}
    );
  }

  changeFlow(data) {
    this.restaurantService.changeFlow(data);
  }

  handleGooglePayFail(error = null) {
    this.toast.danger(error.message);
  }

  handleApplePayFail(error = null) {
    this.toast.danger(error.message);
  }

  getSignUpCopy() {
    this.subs.push(this.cms.getBranding().pipe(take(1)).subscribe((res) => {
      if (res.data.sign_up_checkbox_copy) {
        this.signUpCopy = res.data.sign_up_checkbox_copy;
      }
    }));
  }

  deleteAllCookies() {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

  changeHandoff(handoff: string) {
    this.isCurbside = handoff === 'curbside';
    this.basketService.setDeliveryMode(handoff).subscribe(() => {
      },
      error => {
        if (Array.isArray(error)) {
          if (error.length > 1) {
            this.toast.danger(`The following items are not available for ${handoff}: ${error.join(', ')}`)
          } else {
            this.toast.danger(`${error[0]} is not available for ${handoff}`)
          }
        } else {
          if (error.message) {
            this.toast.danger(error.message)
          } else {
            this.toast.danger(error.toString())
          }
        }
        this.isCurbside = this.basket.deliverymode === 'curbside'
      })
  }

  tip() {
    return this.tipForm.get('tip');
  }
}
