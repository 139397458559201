import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';


export const highContrast = {
   'orange-to-black':'black',
   'main-background': 'none',
   'slate-to-darkgray':'#1e1e1e',
   'no-image-default':'#0c0b0b',
   'orange-to-white':'white',
   'gold-to-black':'#0c0b0b',
   'gray-to-white':'white',
   'std-to-bold':'900',
   'gray-to-black':'black',
   'slategray-to-black':'black',
   'darkslategray-to-black':'black',
   'dimgray-to-black':'black',
   'orange-to-gray':'#516169',
   'darkgray-to-black':'black',
   'black-to-white':'white'
};

export const standardContrast = {
    'white-to-black':'#ffffff',
    'main-background': "",
    'slate-to-darkgray':'#516169',
    'no-image-default':'#929292',
    'orange-to-white':'#f5831f',
    'gold-to-black':'#ddcf8a',
    'gray-to-white':'#516169',
    'std-to-bold':'400',
    'gray-to-black': 'gray',
    'slategray-to-black':'slategray',
    'darkslategray-to-black':'darkslategray',
    'dimgray-to-black':'dimgray',
    'orange-to-gray':'#f5831f',
    'darkgray-to-black':'#333333',
    'black-to-white':'black'
};


@Injectable()
export class ContrastService {
    highContrast$ = new BehaviorSubject(false);

  toggleHighContrast() {
    this.setContrast(highContrast);
    window.localStorage.setItem('highContrast', 'true')
    this.highContrast$.next(true);
  }

  toggleStandardContrast() {
    this.setContrast(standardContrast);
    window.localStorage.removeItem('highContrast')
    this.highContrast$.next(false);
  }


  private setContrast(contrast: {}) {
    Object.keys(contrast).forEach(k => {
      document.documentElement.style.setProperty(`--${k}`, contrast[k])
    });
  }
}
