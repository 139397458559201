<app-loader></app-loader>
<app-toast></app-toast>
<span defaultOverlayTarget></span>
  <nav>
    <app-main-header></app-main-header>
  </nav>
  <main>
    <div class="main-router-outlet">
      <router-outlet>
      </router-outlet>
    </div>
  </main>
  <app-main-footer class="mt-auto"></app-main-footer>


<link preload="/assets/fonts/RobotoCondensed-Regular.ttf" as="font">
