<ng-container *ngIf="checkDone">
  <div>
    <div class="modal-exit">
      <img (click)="closeModalClicked()" class="modal-exit-img" src="assets/icon/exit-button.svg" alt="Close Button">
    </div>
    <div class="bs-modal-content-shell">
      <div class="bs-modal-content">

        <div class="delivery-pickup-wrapper">
          <h1 class="bs-modal-header">Order Type:</h1>
          <div class="bs-modal-subheader">Delivery or Pickup</div>
          <div class="delivery-options row m-0">
            <div class="col p-2 m-0" *ngIf="showPickup">
              <button (click)="methodClicked('pickup')" class="freebirds-btn w-100">Pickup</button>
            </div>
            <div class="col p-2 m-0" *ngIf="showCurbside">
              <button (click)="methodClicked('curbside')" class="freebirds-btn w-100">Curbside Pickup</button>
            </div>
          </div>
          <div class="delivery-options pickup-delivery row m-0" *ngIf="showDelivery">
            <div class="col p-2 m-0">
              <button (click)="methodClicked('delivery')" class="freebirds-btn-secondary w-100">Delivery</button>
            </div>
            <div class="col p-2 m-0" *ngIf="showDriveThru">
              <button (click)="methodClicked('drivethru')" class="freebirds-btn-secondary w-100">Mobile Pickup Lane
              </button>
            </div>
          </div>
          <div class="bs-modal-divider"></div>
          <div class="bs-modal-subheader">Order Catering</div>
          <div class="delivery-options row m-0">
            <div class="col p-2 m-0">
              <button (click)="methodClicked('orderonline')" class="freebirds-btn w-100">ORDER ONLINE</button>
            </div>
          </div>
          <div class="delivery-options row m-0">
            <div class="col p-2 m-0">
              <button (click)="methodClicked('callus')" class="freebirds-btn-secondary w-100">CALL US</button>
            </div>
            <div class="col p-2 m-0">
              <button (click)="methodClicked('emailus')" class="freebirds-btn-secondary w-100">EMAIL US</button>
            </div>
          </div>
          <div class="delivery-options">

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
