import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-upsell-item',
  templateUrl: './upsell-item.component.html',
  styleUrls: ['./upsell-item.component.scss']
})
export class UpsellItemComponent implements OnInit {

  id: 0;
  _qty = 0;

  @Input('upsellParent')
  _upsellParent: any;

  @Input('item')
  _item: any;

  constructor() { }

  ngOnInit() {
  }

  minusQty() {
    if(this._qty >= 1) {
      this._qty -= 1;
    }
    this._upsellParent.updateUpSellList(this._item, this._qty);
  }

  addQty() {
    this._qty += 1;
    this._upsellParent.updateUpSellList(this._item, this._qty);
  }

  get qty() {
    return this._qty;
  }

  set qty(qty:any) {
    this._qty = parseInt(qty);
    this._upsellParent.updateUpSellList(this._item, this._qty);
  }

}
