<ng-container *ngIf="basket && restaurant">
  <app-location-details></app-location-details>
  <div class="order-details sanFran h-100">
    <div class="hands-background container-fluid container-main-background order-details">
      <div class="main-wrapper">
        <div>
          <h1 class="text-uppercase">Checkout</h1>
        </div>
      </div>
      <div class="main-wrapper">
        <div class="main-wrapper" *ngIf="!hasAuthInfo">
          <div class="row px-60">
            <div class="col">
              <p class="section-head">YOUR CONTACT INFO</p>
            </div>
          </div>
        </div>
        <div class="main-wrapper" *ngIf="!hasAuthInfo">
          <form class="contactInfoForm w-100 m-auto" name="contactInfoForm" [formGroup]="contactInfoForm">
            <div class="row">
              <div class="col">
                <input (focusout)="inputScroll()" class="form-control" type="text" name="first_name"
                       formControlName="first_name"
                       placeholder="First Name*" aria-label="First Name"/>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <input (focusout)="inputScroll()" class="form-control" type="text" name="last_name"
                       formControlName="last_name"
                       placeholder="Last Name*" aria-label="Last Name"/>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <input (focusout)="inputScroll()" class="form-control" type="text" name="email" formControlName="email"
                       placeholder="Email Address*"
                       aria-label="Email"/>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <input (focusout)="inputScroll()" class="form-control" type="text" name="phone"
                       formControlName="phonenumber"
                       placeholder="Phone Number*" inputmode="numeric" aria-label="Phone number" mask="000-000-0000"/>
              </div>
            </div>

            <div class="form-check mt-1 p-0 mb-0">
              <label class="checkbox-container checkbox-label">
                {{ signUpCopy }}
                <input (focusout)="inputScroll()" type="checkbox" checked="checked" name="signup"
                       formControlName="signup">
                <span class="checkmark"></span>
              </label>
            </div>

            <ng-container *ngIf="contactInfoForm.get('signup').value === true">
              <div class="row">
                <div class="col">
                  <input (focusout)="inputScroll()" class="form-control" type="password" name="password"
                         formControlName="password" placeholder="Password*"
                         aria-label="Password"/>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <input (focusout)="inputScroll()" class="form-control" type="password" name="confirm"
                         formControlName="confirm" placeholder="Confirm*"
                         aria-label="Confirm"/>
                </div>
              </div>
            </ng-container>

            <div class="form-check mt-1 p-0">
              <label class="checkbox-container checkbox-label">
                <div class="bold" routerLink="/brochure/terms/service">
                  I agree with the <span class="text-decoration-underline">Terms &amp; Conditions</span>
                </div>
                <input (focusout)="inputScroll()" type="checkbox" checked="checked" name="terms" formControlName="terms"
                       required>
                <span class="checkmark"></span>
              </label>
            </div>
          </form>
        </div>
        <div class="main-wrapper cart">

          <!-- AVAILABLE AND APPLIED REWARDS-->
          <div class="row mt-30px" *ngIf="rewardsPresent == true">
            <div class="col">
              <p class="section-head">YOUR AVAILABLE REWARDS</p>
            </div>
          </div>
          <div class=" mt-50px" *ngIf="rewardsPresent == true">
            <div class="rewards" *ngFor="let reward of QualifyingRewards">
              <label>{{reward.label}}</label>
              <button class="btn btn-outline-primary btn-block freebirds-btn btn-freebirds clickable"
                      (click)="applyRewards(reward.reference)">
                APPLY
              </button>
            </div>
          </div>

          <div class="row mt-40px" *ngIf="AppliedReward">
            <div class="col">
              <p class="section-head">YOUR APPLIED REWARDS</p>
            </div>
          </div>
          <div class=" mt-20px" *ngIf="AppliedReward">
            <div class="rewards">
              <label>{{AppliedReward}}</label>
              <button class="btn btn-outline-primary btn-block freebirds-btn btn-freebirds clickable"
                      (click)="deleteReward(appliedRewardId)">REMOVE
              </button>
            </div>
          </div>
          <ng-container
            *ngIf="basket?.deliverymode !== 'drivethru' && ((basket?.deliverymode === 'pickup' && restaurant?.supportscurbside) || (basket?.deliverymode === 'curbside' && restaurant?.canpickup))">
            <div class="row mt-40px">
              <div class="col">
                <p class="section-head">CHOOSE PICKUP METHOD</p>
              </div>
            </div>
            <div class="d-flex justify-content-center w-100">
              <div class="d-flex pickup-delivery">
                <div class="animation-slide" [class.animation-slide-curbside]="isCurbside"></div>
                <div class="d-flex justify-content-center handoff-text text-uppercase"
                     (click)="changeHandoff('pickup')">
                  <ng-container *ngIf="windowWidth > 350">In-Store </ng-container>PICKUP
                </div>
                <div class="d-flex justify-content-center handoff-text text-uppercase"
                     (click)="changeHandoff('curbside')">
                  Curbside<ng-container *ngIf="windowWidth > 350"> pickup</ng-container>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="main-wrapper" [ngClass]="basket?.deliverymode === 'curbside' ? 'open-curbside-info' : 'closed-curbside-info'">
            <div class="row">
              <div class="col">
                <p class="section-head add-tip text-uppercase">Enter Vehicle Information</p>
              </div>
            </div>
            <form class="carInfoForm m-auto w-100" name="carInfoForm" [formGroup]="carInfoForm">
              <div class="row">
                <div class="col">
                  <input (focusout)="inputScroll()" class="form-control" type="text" name="make" formControlName="make"
                         placeholder="Make*"
                         aria-label="Make"/>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <input (focusout)="inputScroll()" class="form-control" type="text" name="model"
                         formControlName="model" placeholder="Model*"
                         aria-label="Model"/>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <input (focusout)="inputScroll()" class="form-control" type="text" name="color"
                         formControlName="color" placeholder="Color*"
                         aria-label="Color"/>
                </div>
              </div>
            </form>
          </div>
          <div class="row mt-40px">
            <div class="col">
              <p class="section-head add-tip">ADD TIP</p>
            </div>
          </div>
          <form class="tipForm" name="tipForm" [formGroup]="tipForm">
            <div class="input-group input-group-lg">
              <div class="input-group-prepend" id="button-addon3">
                <button class="btn btn-outline-secondary fs-16"
                        [class.active-tip]="tip().value == (basket?.subtotal * 0.1).toFixed(2)" type="button"
                        (click)="addTip(basket?.subtotal * 0.1, false)">
                  <ruby>10%
                    <rp>(</rp>
                    <rt>{{basket?.subtotal * 0.1 | currency: 'USD'}}</rt>
                    <rp>)</rp>
                  </ruby>
                </button>
                <button class="btn btn-outline-secondary fs-16"
                        [class.active-tip]="tip().value == (basket?.subtotal * 0.15).toFixed(2)" type="button"
                        (click)="addTip(basket?.subtotal * 0.15, false)">
                  <ruby>15%
                    <rp>(</rp>
                    <rt>{{basket?.subtotal * 0.15 | currency: 'USD'}}</rt>
                    <rp>)</rp>
                  </ruby>
                </button>
                <button class="btn btn-outline-secondary fs-16"
                        [class.active-tip]="tip().value == (basket?.subtotal * 0.2).toFixed(2)" type="button"
                        (click)="addTip(basket?.subtotal * 0.2, false)">
                  <ruby>20%
                    <rp>(</rp>
                    <rt>{{basket?.subtotal * 0.2 | currency: 'USD'}}</rt>
                    <rp>)</rp>
                  </ruby>
                </button>
              </div>
              <input type="number" inputmode="decimal" class="form-control tip-input"
                     placeholder="" pattern="^\d*(\.\d{0,2})?$"
                     formControlName="tip">
              <div class="input-group-append">
                <button class="btn btn-outline-primary freebirds-btn btn-freebirds text-uppercase apply-btn"
                        type="button" id="button-addon2" (click)="applyTip()">Apply
                </button>
              </div>
            </div>
          </form>
          <div class="row mt-40px">
            <div class="col">
              <p class="section-head add-promo">PROMO CODE</p>
            </div>
          </div>

          <div [ngStyle]="{'margin-top': this.authUserInfo ? '10px' : '0px' }">
            <div *ngIf="basket && !basket?.coupon" class="promo-container">
              <input #coupon placeholder="Promo Code" (focusout)="inputScroll()" class="promo-input" type="text">
              <button (click)="applyCoupon()" class="btn btn-outline-primary freebirds-btn btn-freebirds">APPLY</button>
            </div>
            <div *ngIf="basket && basket?.coupon">
              <p class="section-head promo mb-5px">Promo Code:
                <span class="green">{{basket['coupon']['couponcode'] ? basket['coupon']['couponcode'] : '' }}</span>
                Applied
                ${{basket['coupondiscount'] ? basket['coupondiscount'] : ''}} Off</p>
              <a (click)="removeCoupon()" class="promo-remove-link">Remove</a>
            </div>
          </div>

          <div class="cart-details-wrapper mb-5" *ngIf="!this.isGroupOrder && basket && basket['products'].length > 0">
            <div class="row">
              <div class="col">
                <p class="section-head mb-0 mt-5">REVIEW YOUR BAG</p>
              </div>
            </div>
            <div class="cart-container" *ngFor="let product of basket.products">
              <div class="flex-8">
                <span class="product-name">{{product['name']}}</span><br/>
                <p class="choices">{{ product['choices'] | choices }}</p>
                <span class="edit-section">
              <a [routerLink]="['/order/products/edit/', product.productId, product.id, product.quantity]"
                 class="underline-text">EDIT</a> |
              <a (click)="removeProductFromBasket(product.id, product.name)" class="underline-text">REMOVE</a>
            </span>
              </div>
              <div class="quantity">
                <input (focusout)="inputScroll()" class="qty-input" (change)="updateProductQuantity($event, product)"
                       value="{{product['quantity']}}">
              </div>
              <div class="text-right justify-content-end">
            <span class="d-inline-block justify-content-end w-75px">
              ${{product['totalcost'] | number:'.2-2' }}
            </span>
              </div>
            </div>
          </div>
          <div class="notice">
            (*) Indicates a customized Item
          </div>
          <div class="cart-items row empty-cart-message m-auto"
               *ngIf="basket && basket['products'].length == 0">
            <p class="col-12 plus-minustxt empty-message pt-4 text-center">Doesn't look like there's anything here.</p>
            <p class="col-12 plus-minustxt empty-message pb-4 text-center">Click on a menu item to start ordering!</p>
          </div>
          <div *ngIf="this.isGroupOrder">
            <div class="mt-70px" *ngFor="let content of this.basketProducts | keys: []">
              <div class="row">
                <div class="col text-center">
                  <p class="section-head">{{ content.key }}</p>
                </div>
              </div>
              <div class="cart-items" *ngFor="let product of content.value">
                <div class="row no-gutters">
                  <div class="col plus-minus product-background">
                    <button class="plus-minus btn btn-primary float-left" type="button"
                            (click)="updadeProductQuantityInBasket(product,  1 + product['quantity'] )">+
                    </button>
                    <p class="float-left plus-minustxt item-count">{{product['quantity']}}</p>
                    <button class="plus-minus btn btn-primary float-left" type="button"
                            (click)="updadeProductQuantityInBasket(product, -1 + product['quantity'] )">-
                    </button>
                    <p class="float-left plus-minustxt item-name font-weight-bold">{{product['name']}}</p>

                    <p class="float-right plus-minustxt item-price item-price-number pl-2">
                      ${{product['totalcost'] | number:'.2-2' }}</p>
                    <a (click)="removeProductFromBasket(product['id'], product['name'])">
                      <i class="fa fa-trash-o"></i>
                    </a>
                    <p class="px-1 float-right plus-minustxt item-price">&nbsp;&nbsp;
                      <a [routerLink]="['/order/products/edit/', product.productId, product.id, product.quantity]">
                        <i class="fa fa-angle-right"></i>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-wrapper">
          <div class="totals-section">
            <div *ngIf="basket && basket['tip']">
              <div class="row my-1 mx-0">
                <div class="col pl-0">
                  <div class="">Tip</div>
                </div>
                <div class="col float-right text-right pr-0">
                  <div class="">${{ basket['tip'] | number:'.2-2' }}</div>
                </div>
              </div>
            </div>

            <div *ngIf="basket && basket['discount']">
              <div class="row my-1 mx-0">
                <div class="col pl-0">
                  <div class="">Discount</div>
                </div>
                <div class="col float-right text-right pr-0">
                  <div class="">- ${{ basket['discount'] | number:'.2-2' }}</div>
                </div>
              </div>
            </div>

            <div *ngIf="basket && basket.fees">
              <ng-container *ngFor="let fee of basket.fees">
                <div *ngIf="fee.amount" class="row my-1 mx-0">
                  <div class="col pl-0">
                    <div class="">Service Charge</div>
                  </div>
                  <div class="col float-right text-right pr-0">
                    <div>${{ fee.amount | number:'.2-2' }}</div>
                  </div>
                </div>
                <div *ngIf="fee.note" class="row my-1 mx-0">
                  <div class="col pl-0 note-text">
                    * {{ fee.note }}
                  </div>
                </div>
              </ng-container>
            </div>

            <div *ngIf="basket && this.dispatchSelected == 'true'">
              <div class="row my-1 mx-0">
                <div class="col pl-0">
                  <div class="">Delivery Fee</div>
                </div>
                <div class="col float-right text-right pr-0">
                  <div class="">${{ basket['customerhandoffcharge'] | number:'.2-2' }}</div>
                </div>
              </div>
            </div>
            <div *ngIf="basket">
              <div class="row my-1 mx-0">
                <div class="col pl-0">
                  <div class="subtotal-text">Subtotal</div>
                </div>
                <div class="col float-right text-right pr-0">
                  <div class="subtotal-text">${{ basket['total'] | number:'.2-2' }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="main-wrapper mb-100px">
            <button class="freebirds-btn full-width continue-font-size" (click)="checkout()">CONTINUE</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
