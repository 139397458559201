<div class="container-fluid container-main-background">
    <div class="row title">
        <div class="col">
            <h1 class="text-uppercase text-center">REV IT UP!
                <br>
                <i class="fa fa-plus">
                </i>SAVE!</h1>
            <h3 class="p-small">IT'S LIKE A COMBO DEAL BUT BETTER</h3>
        </div>
    </div>
    <div class="main-wrapper">
        <div class="d-flex flex-wrap m-auto cat-items flexWrap firstSelection" *ngIf="!pickedSize">
            <h2 class="white sanFran">FOUNTAIN DRINK
                <br>+ CHIPS N DIP</h2>
            <h3 class="p-small">CHOICE OF: FRESH GUAC, SALSA & QUESO</h3>
            <br>
            <div class="cat-item">
                <button class="btn btn-outline-primary btn-block freebirds-btn btn-freebirds px-4 sizingButton" type="button" (click)="pickSize('dip', 'regular', 2)">REGULAR
                    <p class="under-text">22 oz FOUNTAIN DRINK</p>
                </button>
            </div>
            <div class="cat-item">
                <button class="btn btn-outline-primary btn-block freebirds-btn btn-freebirds px-4 sizingButton" type="button" (click)="pickSize('dip', 'large', 0)">
                    LARGE
                    <p class="under-text">32 oz FOUNTAIN DRINK</p>
                </button>
            </div>
        </div>
        <div class="d-flex flex-wrap m-auto cat-items flexWrap firstSelection" *ngIf="!pickedSize">
            <h2 class="white sanFran">FOUNTAIN DRINK
                <br>+ DESSERT</h2>
            <h3 class="p-small">CHOICE OF: FRESH GUAC, SALSA & QUESO</h3>
            <br>
            <div class="cat-item">
                <button class="btn btn-outline-primary btn-block freebirds-btn btn-freebirds px-4 sizingButton" type="button" (click)="pickSize('dessert', 'regular', 3)">REGULAR
                    <p class="under-text">22 oz FOUNTAIN DRINK</p>
                </button>
            </div>
            <div class="cat-item">
                <button class="btn btn-outline-primary btn-block freebirds-btn btn-freebirds px-4 sizingButton" type="button" (click)="pickSize('dessert', 'large', 1)">
                    LARGE
                    <p class="under-text">32 oz FOUNTAIN DRINK</p>
                </button>
            </div>
        </div>
        <div class="d-flex flex-wrap m-auto cat-items flexWrap" *ngIf="pickedSize">
            <div class="arrow-row">
                <div class="arrow-in">
                    <p>PICK YOUR {{pType}}</p>
                    <div class="arrow-point"></div>
                </div>
            </div>
            <div class="spacer"></div>
            <div class="cat-item widthForCat" *ngFor="let item of choices">
                <div class="cat-image" (click)="select(item, true, 0)">
                    <div class="col d-flex justify-content-center">
                        <button class="btn btn-primary food-button">
                            <img [src]="menu_images[item.name]?.img" onerror="this.src='/assets/img/Default Image.png';" class="round-circle d-flex justify-content-center align-items-center m-auto"
                                width="75px" height="75px" *ngIf="!selected[0][item.name]" [alt]="item.name">
                            <img src="/assets/img/Selected Item.png" onerror="this.src='/assets/img/Default Image.png';" class="round-circle d-flex justify-content-center align-items-center m-auto"
                                width="75px" height="75px" *ngIf="selected[0][item.name]" [alt]="item.name">
                        </button>
                    </div>
                </div>
                <h5 class="cat-title text-uppercase d-flex justify-content-center">{{item.name}}</h5>
                <h5 class="cat-price text-uppercase">{{item.cost > 0 ? item.cost : ''}}</h5>
            </div>
        </div>
        <div class="d-flex flex-wrap m-auto cat-items flexWrap" *ngIf="pickedSize">
            <div class="arrow-row-left">
                <div class="arrow-in">
                    <p>PICK YOUR DRINK</p>
                    <div class="arrow-point"></div>
                </div>
            </div>
            <div class="spacer"></div>
            <div class="cat-item widthForCat" *ngFor="let item of drinksChoices">
                <div class="cat-image" (click)="select(item, false, 1)">
                    <div class="col d-flex justify-content-center">
                        <button class="btn btn-primary food-button">
                            <img [src]="menu_images[item.name]?.img" onerror="this.src='/assets/img/Default Image.png';" class="round-circle d-flex justify-content-center align-items-center m-auto"
                                width="75px" height="75px" *ngIf="!selected[1][item.name]" [alt]="item.name">
                            <img src="/assets/img/Selected Item.png" onerror="this.src='/assets/img/Default Image.png';" class="round-circle d-flex justify-content-center align-items-center m-auto"
                                width="75px" height="75px" *ngIf="selected[1][item.name]" [alt]="item.name">
                        </button>
                    </div>
                </div>
                <h5 class="cat-title text-uppercase d-flex justify-content-center">{{item.name}}</h5>
                <h5 class="cat-price text-uppercase">
                    {{item.cost > 0 ? item.cost : ''}}</h5>
            </div>

        </div>

        <div class="no-thanks" (click)="goToCheckout()" *ngIf="!pickedSize">
            <h4 tyle="text-align: left;">NO THANKS,
                <br>I'LL SKIP THE REV.</h4>
            <i class="fa fa-angle-right orange-arrow"></i>
        </div>
    </div>
</div>

<div class="container-fluid fixed-bottom cat-bottom" *ngIf="pickedSize">
    <div class="bottom-head row d-block float-none d-flex justify-content-center align-items-center align-content-center align-self-center m-auto">
        <div class="col float-right product-selected-name-container">
            <h5 class="product-selected-name">REV IT UP</h5>
        </div>
        <div class="col-auto">
            <h5 class="text-left float-left">{{ cost | currency:'USD':true:'1.2-2' }}</h5>

        </div>
    </div>
    <nav class="navbar navbar-dark navbar-expand d-block d-flex justify-content-center align-items-center align-content-center align-self-center m-auto">
        <div class="container">
            <button class="navbar-toggler" data-toggle="collapse" data-target="#nav-func">
                <span class="sr-only">Toggle Navigation</span>
                <span class="navbar-toggler-icon"></span>
            </button>
            <div id="nav-func" class="collapse navbar-collapse d-flex align-items-center align-content-center align-self-center m-auto">
                <ul class="nav navbar-nav d-flex justify-content-center align-items-center align-content-center m-auto">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link active" (click)="startOver()">start over</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link last" (click)="addRev()">add to cart</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>
<img [src]="globalImages?.drinks_img" class="line_art line_art1" alt="Drinks">
<img [src]="globalImages?.chips_n_dip_img" class="line_art line_art2" alt="Chips and Dips">
