import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { TwilioSMSResponse } from 'app/models/twilio-sms-response.interface';
import { TwilioAccountsResponse } from 'app/models/twilio-accounts-response.interface';
import { switchMap } from 'rxjs/operators';
import { TwilioPhoneNumbersResponse } from 'app/models/twilio-phone-numbers-response.interface';

@Injectable()
export class TwilioService {

    constructor(private http: HttpClient) { }

    sendSMS(toPhone: string, message: string): Observable<TwilioSMSResponse> {
        return this.getAccounts().pipe(switchMap((res: any) => {
            const account = res.accounts[0];
            return this.getPhoneNumbers(account.sid).pipe(switchMap((phones: any) => {
                const phone = phones.incoming_phone_numbers[0];
                const formData = new FormData();
                formData.append('To', toPhone);
                formData.append('Body', message);
                formData.append('From', phone.phone_number);

                const resource = `${environment.cmsUrl}/sms/Accounts/${account.sid}/Messages.json`;
                /* const resource = `https://api.twilio.com/2010-04-01/Accounts/${accountID}/Messages.json`; */
                return this.http.post<TwilioSMSResponse>(resource, formData);
            }));
        }));

    }

    getAccounts(): Observable<TwilioAccountsResponse> {
        const resource = `${environment.cmsUrl}/sms/Accounts.json`;
        return this.http.get<TwilioAccountsResponse>(resource);
        /* const resource = `https://api.twilio.com/2010-04-01/Accounts.json`;
        return this.http.get<TwilioAccountsResponse>(resource, { headers: this.getHeaders('AC42f19f61f176b1eb15c7fd4f2a3d74fd', '7d7e38b1a53058b30d7289dacb5db8ce') }); */
    }

    getPhoneNumbers(accountSid: string): Observable<TwilioPhoneNumbersResponse> {
        const resource = `${environment.cmsUrl}/sms/Accounts/${accountSid}/IncomingPhoneNumbers.json`;
        return this.http.get<TwilioPhoneNumbersResponse>(resource);
        /* const resource = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/IncomingPhoneNumbers.json`;
        return this.http.get<TwilioPhoneNumbersResponse>(resource, { headers: this.getHeaders('AC42f19f61f176b1eb15c7fd4f2a3d74fd', '7d7e38b1a53058b30d7289dacb5db8ce') }); */
    }

    /* getHeaders(username: string, password: string): HttpHeaders {
        const headers = new HttpHeaders();
        return headers.append("Authorization", "Basic " + btoa(`${username}:${password}`));
    } */
}
