import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  NgZone,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {DineEngineCMSService} from '../../providers';
import {Location} from '@angular/common';
import {environment} from '../../../environments/environment';
import 'jquery';
import * as moment from 'moment';
import {Blog} from '../../models/cms.collections';


const BASE_URL = environment.imageUrl;

declare const $: any;

@Component({
  selector: 'app-new-news',
  templateUrl: './new-news.component.html',
  styleUrls: ['./new-news.component.scss'],
})
export class NewNewsComponent implements OnDestroy, OnInit {
  @ViewChild('img1') img1;
  CMSurl = environment.imageUrl;

  blogContent = [];

  global_images: Subscription;
  header_image: any;
  hasClicked = false;

  sub: Subscription;
  fromTop = this.getFromTop();

  currentPage_BlogContent: Blog[] = [];
  currentPage_NumRange = [];
  currentPageNum = 1;
  totalPages = 1;

  image1Load = false;

  constructor(
    private cms: DineEngineCMSService,
    private _zone: NgZone,
    private location: Location
  ) {
  }

  ngOnInit() {
    // CMS SUBSCRIPTIONS
    const arrow = $('.scroll-arrow');
    arrow.hide();
    this.populate_page_CMS();

    arrow.click(function () {
      $('#blog').animate({
        scrollTop: 0
      }, 500);
    });

    setTimeout(function () {
      arrow.fadeIn('slow');
    }, 5000);

  }

  getFromTop() {
    if (!this.img1) {
      return '350px';
    } else {
      return this.img1.nativeElement.getBoundingClientRect().top + 'px';
    }
  }

  goBack() {
    this.location.back();
  }

  ngOnDestroy() {
    // CMS UNSUBSCRIPTIONS
    this.sub.unsubscribe();
  }


  getCurrentPageBlogContent() {
    const start = (this.currentPageNum - 1) * 5;
    const content = [];
    for (let i = start; i < start + 5; i++) {
      if (i >= this.blogContent.length) {
        break;
      }
      content.push(this.blogContent[i]);
    }
    this._zone.run(() => {
      this.currentPage_BlogContent = content;
    });
  }

  getCurrentPageNumRange() {
    const range = [];
    const pagesWillShow = 5;
    let start = 0,
      end = 0;
    if (
      this.currentPage_NumRange.length > 0 &&
      this.currentPageNum >= this.currentPage_NumRange[0] &&
      this.currentPageNum <=
      this.currentPage_NumRange[this.currentPage_NumRange.length - 1]
    ) {
      if (
        this.currentPageNum === this.currentPage_NumRange[0] ||
        this.currentPageNum ===
        this.currentPage_NumRange[this.currentPage_NumRange.length - 1]
      ) {
        start = this.currentPageNum - Math.floor(pagesWillShow / 2);
      } else {
        start = this.currentPage_NumRange[0];
      }
    } else {
      start = this.currentPageNum - Math.floor(pagesWillShow / 2);
    }
    end = start + pagesWillShow;

    if (start < 1) {
      start = 1;
      end = start + pagesWillShow;
    }
    if (end > this.totalPages) {
      end = this.totalPages + 1;
      start = end - pagesWillShow;
      if (start < 1) {
        start = 1;
      }
    }
    for (let i = start; i < end; i++) {
      range.push(i);
    }
    this._zone.run(() => {
      this.currentPage_NumRange = range;
    });
  }

  goToPage_Last() {
    this.goToPage_Selected(this.totalPages);
    this.scrollToTop('firstPost');  }

  goToPage_Next() {
    this.goToPage_Selected(this.currentPageNum + 1);
    this.scrollToTop('firstPost');  }

  goToPage_Previous() {
    this.goToPage_Selected(this.currentPageNum - 1);
    this.scrollToTop('firstPost');  }

  goToPage_Selected(pageNum) {
    if (pageNum < 1) {
      pageNum = 1;
    }
    if (pageNum > this.totalPages) {
      pageNum = this.totalPages;
    }
    this.currentPageNum = pageNum;
    this.getCurrentPageBlogContent();
    this.getCurrentPageNumRange();
    this.scrollToTop('firstPost');
  }

  // CMS PAGE SPECIFIC
  populate_page_CMS() {
    this.sub = this.cms.getBlog().subscribe(
      (res) => {
        this.blogContent = res.data.reverse();
        this.currentPageNum = 1;
        this.totalPages = Math.ceil(this.blogContent.length / 5);
        this.getCurrentPageBlogContent();
        this.getCurrentPageNumRange();
      }
    );
  }

  scrollToElement($element): void {
    $element.scrollIntoView({behavior: 'smooth'});
  }

  scrollToTop(elementID: string) {
    const topPos = document.getElementById(elementID).offsetTop;
    document.getElementById('blog').scrollTop = topPos - 75;
  }

  afterPublished(date: string): boolean {
    const today = moment();
    return moment(date).isSameOrBefore(today);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.fromTop = this.getFromTop();
  }
}
