<div [class.loader-hidden]="!show">
    <div class="in2" *ngIf="show"></div>
    <div id="loader-wrapper" class="load-container">
        <div id="loader-inner-text" class="load-style">
            <svg width="52px" height="78px" viewBox="0 0 78 117" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Loader</title>
                <defs>
                    <polygon id="path-1" points="183.880693 185.395295 0 185.395295 0 92.8965504 0 0.397805553 183.880693 0.397805553 183.880693 185.395295"></polygon>
                </defs>
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Group-2" transform="translate(-54.000000, -46.000000)">
                        <g id="Group-4">
                            <g id="Group-3">
                                <mask id="mask-2" fill="white">
                                    <use xlink:href="#path-1"></use>
                                </mask>
                                <g id="Clip-2"></g>
                            </g>
                            <polygon id="Fill-4" fill="#fd7e14" points="109.296705 69.1409085 109.296705 91.4006297 64.6951581 91.4006297 64.6951581 114.030763 109.296705 114.030763 109.296705 162.75 131.636667 162.75 131.636667 46.5 54.2033333 46.5 54.2033333 69.1409085"></polygon>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    </div>
</div>
