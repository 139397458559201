import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Directive({
  selector: '[appImageMapping]'
})
export class ImageMappingDirective implements OnInit {

  _images = [];

  @Input()
  imageName;

  @Input()
  backgroundSize = '540px';

  el;
  renderer;

  setImage;
  imageUrl;
  cmsBaseUrl = environment.imageUrl;
  initialImage = '/assets/img/freeplaceholder.png';
  _fullImageUrl = this.initialImage;
  _loadedImage;

  constructor( renderer: Renderer2,   el: ElementRef) {
    renderer.setStyle(el.nativeElement, 'background-image','url(/assets/img/freeplaceholder.png)');
    this.renderer = renderer;
    this.el = el;
    // renderer.setStyle(el.nativeElement, 'background-size',this.backgroundSize);
    // renderer.setStyle(el.nativeElement, 'background-repeat','no-repeat');
  }

  ngOnInit() {
    this.renderer.setStyle(this.el.nativeElement, 'background-size', 'cover');
    this.renderer.setStyle(this.el.nativeElement, 'background-position','center');
  }


  @Input()
  set images(images) {
    if(!this._loadedImage) {
      if(images.length) {
        const image = images.filter( item => item.menu_name.toLowerCase() === this.imageName.toLowerCase());
        if(image.length) {
          this.setImage = image[0];
          this.imageUrl = this.setImage.menu_image.data.url;
          this._loadedImage = this.cmsBaseUrl + this.imageUrl;
          this.renderer.setStyle(this.el.nativeElement, 'background-image','url(' + this._loadedImage + ')');
        }
      }
    }
  }

  set fullImageUrl(fullImageUrl) {
    this._fullImageUrl = fullImageUrl;
  }

  get fullImageUrl() {
    if(this._loadedImage) {
      return this._loadedImage;
    }
    return this._fullImageUrl ;
  }

  getFullImageUrl() {
    return this.fullImageUrl;
  }
}
