<app-location-details></app-location-details>
<div class="category-menu-wrapper hands-background">
  <h1 class="text-center">Menu Categories</h1>
  <div class="container">
    <div class="category-grid mx-auto">
      <ng-container *ngFor="let category of categories">
        <div class="h-100 w-100 category-card" (click)="routeToCategory(category)">
          <img [src]="menuImages[(category.name | categorySlug)]" [alt]="category.name">
          <h3 class="text-center m-0 text-uppercase">{{category.name}}</h3>
          <button class="freebirds-btn mx-3 mb-3 mt-0" >Order Now</button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
