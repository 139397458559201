<div>
  <div class="modal-exit">
    <img (click)="closeModalClicked()" class="modal-exit-img" src="assets/icon/exit-button.svg" alt="Close Button" />
  </div>
  <div class="bs-modal-content-shell">
    <div class="bs-modal-content">

  <div class="delivery-pickup-wrapper">
  <h1 class="bs-modal-header">Reset Password</h1>

  <div class="delivery-method">
    <p class="pb-20px">Enter your email address and submit for your password reset link</p>
  <div class="reset-form sign-in-form">
    <input (focusout)="inputScroll()" class="reset-password-input" placeholder="Email" [(ngModel)]="resetPasswordEmail"/>
  </div>
  <div class="sign-in-up-buttons mt-20px">
    <button (click)="submitForgotPassword()" class="freebirds-btn sign-in">Submit</button>
  </div>
  </div>


  </div>
    </div>
  </div>
</div>
