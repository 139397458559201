<div class="scrollable_view hands-background" id="blog">
  <div id="top">
      <div class="row">
          <div class="col mx-3 pt-3 back">
                <span class="text-uppercase">
                    <i class="fa fa-angle-left"></i>&nbsp;
            <a class="b-crumb back" (click)="goBack()">BACK</a>
                  </span>
          </div>
        </div>
    <div class="row" id="firstPost">
      <div class="col">
        <h1 class="garageGothic text-center blog-title">Blog</h1>
      </div>
    </div>
  </div>

  <div>
    <div>
      <div class="d-flex flex-column align-items-center justify-content-between" >
        <ng-container *ngFor="let news of currentPage_BlogContent | sortBy: 'desc': 'published_date'; let i = index;">
          <div class="main_wrapper_car" *ngIf="afterPublished(news.published_date)">
              <div class="top_car">
                <img [src]="news.image.data.full_url" (load)="image1Load = true" [hidden]="!image1Load"
                      alt="Slide Image"
                      class="" #img1>
              </div>
              <div class="bottom_car">
                <h2 class="tan-card-txt garageGothic">
                  {{ news.title }}
                </h2>
                <p class="">
                  {{ news.published_date | date:'M/d/yy' }}<span *ngIf="news.author">, {{news.author}}</span>
                </p>
                <br/>
                <p class=""
                  [innerHTML]="(news.body.length > 100)? ( news.body | slice:0:180) + '...' : (news.body)"></p>
                <button [routerLink]="['/blog/' + news.id]" class="freebirds-btn garageGothic">Read More</button>
              </div>
          </div>
          <div class="pagination d-flex flex-row justify-content-around align-content-center align-items-center"
               *ngIf="blogContent.length > 0">
          <span class="next-previous"><a class="page-previous p-2 page-change" *ngIf="currentPageNum > 1"
                                                  (click)="goToPage_Previous()">Previous&nbsp;|&nbsp;</a>
            <a class="page-next p-2 page-change" *ngIf="currentPageNum < totalPages"
              (click)="goToPage_Next()">Next&nbsp;|&nbsp;</a></span>
            <div class="page-nums p-2 d-flex">
                    <span *ngFor="let page of currentPage_NumRange; let i = index;">
                        <a *ngIf="page > 1 && i == 0" (click)="goToPage_Selected(page - 1)" class="page-change">...</a>
                        <a [ngClass]="{ 'active': page == currentPageNum }"
                          (click)="goToPage_Selected(page)" class="page-change">{{ page }}</a>
                        <span *ngIf="page < totalPages && i < currentPage_NumRange.length - 1">&nbsp;</span>
                        <a *ngIf="page < totalPages && i == currentPage_NumRange.length - 1"
                          (click)="goToPage_Selected(page + 1)" class="page-change">...</a>
                    </span>
            </div>
            <a class="page-last p-2 page-change" (click)="goToPage_Last()">&nbsp;|&nbsp;Last</a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

</div>


<img class="scroll-arrow" src="assets/img/scroll-to-top.svg" alt="Scroll to Top">
