<div class="basketModal modal-fullscreen-menu" #cartArea id="modalCart" role="dialog" tabindex="-1">
    <div class="cart-wrapper">
    <div class="tooltip-top">
        <span class="triangle">&#9650;</span>
    </div>
        <div class="cart-area modal-content">
            <div class="row mobile-top-section">
                <div class="mobile-x-button" (click)="closeModalClicked()">
                    <img src="assets/icon/exit-button.svg" alt="Close Button"/>
                </div>
                <div class="col text-center mt-10px">
                <img src="assets/img/Freebirds_Logos_Digital_PrimaryLogo_Carbon_RGB.svg" alt="Freebirds Logo" class="fb-cart-logo">
                </div>
            </div>
            <app-location-details [isModal]="true" (closeModal)="closeModalClicked()"></app-location-details>
            <div class="cart-base">
                <div class="row cart-title">
                    <div class="col align-items-center">
                        <h1 *ngIf="!groupID">YOUR BAG</h1>
                        <div *ngIf="isEmpty(this.basketProducts)" class="sanFran-text mt-20">You have no items in your bag</div>
                        <h1 *ngIf="groupID">GROUP ORDER</h1>
                    </div>
                </div>
                <div class="row group-order-deadline" *ngIf="groupID">
                    <div class="col" *ngIf="!isInvitedUser">
                        Order Deadline : {{ formatDateTime(this.group_orderDeadline) }}
                    </div>
                </div>


                <div class="cart-content">

                    <div class="cart-items" *ngFor="let content of this.basketProducts | keys: []">
                        <ng-container *ngIf="this.basketProducts[content.key].length > 0 && !isEmpty(this.basketProducts)">
                            <div class="item items-head d-flex flex-row">
                                <div class="name">Items</div>
                                <div class="quantity">Qty</div>
                                <div class="price">Price</div>
                            </div>
                            <h6 class="items-recipient font-italic">{{ content.key }}</h6>



                            <div class="items-content" *ngFor="let product of content.value">
                                <div class="item items-body d-flex flex-row">
                                    <div class="name">{{product['name']}}</div>
                                    <div class="quantity">
                                        <input type="number" class="item-qty-input" [(ngModel)]="product['quantity']" (change)="updateProductQuantityInBasket(product)"
                                        />
                                    </div>
                                    <div class="price">${{ product['totalcost'] | number: '.2-2' }}</div>
                                </div>
                                <div class="items-additional-head" *ngIf="product.choices.length">
                                    <p>Additional Items: </p>
                                </div>
                                <div class="item items-additional-body d-flex flex-row">
                                    <p class="name">{{ product['choices'] | choices }}</p>
                                </div>
                                <div *ngIf="product.specialinstructions" class="items-special-head">
                                        <p>Instructions: </p>
                                    </div>
                                    <div *ngIf="product.specialinstructions" class="item items-additional-body d-flex flex-row">
                                            <p class="name">{{product.specialinstructions}}</p>
                                        </div>
                                <div class="body-funcs quantity flex-row">
                                    <a class="update clickable" [routerLink]="['/order/products/edit/', product.productId, product.id, product.quantity]" (click)="closeModalClicked()" *ngIf="product.specialinstructions != 'Revd Combo'">
                                    <span *ngIf="product.category !== 'Sides, Drinks and Desserts'">Edit</span></a>
                                    <span *ngIf="product.category !== 'Sides, Drinks and Desserts'"> | </span>
                                    <a class="remove clickable" (click)="removeProductFromBasket(product.id, product.name)">Remove</a>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <ng-container *ngIf="isEmpty(this.basketProducts)">
                        <div class="w-100 p-4 d-flex flex-column align-items-center">
                            <div *ngIf="!basketService.isLoggedIn" class="sanFran-text text-center mw-300px">Sign in to repeat a past order, or start an order now</div>
                            <button *ngIf="!basketService.isLoggedIn" (click)="loginmodal()" class="garageGothic freebirds-btn-secondary text-uppercase mx-4 my-2 w-50 fb-mw-160">Sign In</button>
                            <button *ngIf="!basketService.isLoggedIn" (click)="loginmodal()" class="garageGothic freebirds-btn-secondary text-uppercase mx-4 my-2 w-50 fb-mw-160">Create Account</button>
                                <button class="freebirds-btn text-uppercase mx-4 my-2 w-50 fb-mw-160" (click)="orderNow()">Order Now</button>
                        </div>
                    </ng-container>

                    <div class="cart-invite-desc" *ngIf="isInvitedUser">
                        <div class="row">
                            <div class="col">
                                <h6>I'm Done Ordering, now what?</h6>
                                <p>Just close your browser - no need to do anything else. The organizer will see your order
                                    on their screen.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <h6>What if I want to add another item?</h6>
                                <p>As long as the Order Deadline hasn't passed, you can come back and add your order.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="cart-bottom">
                    <div class="row sub-total" *ngIf="basket">
                            <span class="total-title float-left">Subtotal</span>
                            <span class="total-amount float-right">${{ basket['subtotal'] | number: '.2-2' }}</span>
                    </div>
                    <a [routerLink]="['/order/details']" class="btn btn-primary cart-btm-btn" role="button"  (click)="tryCheckout($event)" *ngIf="!isEmpty(this.basketProducts)">CHECKOUT</a>
                    <a [routerLink]="['/order/details']" class="btn btn-primary cart-btm-btn disabled" role="button"  (click)="tryCheckout($event)" *ngIf="isEmpty(this.basketProducts)">CHECKOUT</a>
                    <a class="btn btn-primary cart-btm-btn mt-5px black" (click)="goToMenu()" role="button">ADD MORE</a>
                </div>
            </div>
        </div>
    </div>
</div>


