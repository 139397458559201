import { Component, OnInit, ViewChild } from '@angular/core';
import { UpsellService } from '../../../providers/upsell.service';
import { BasketService } from '../../../providers/basket.service';
import { Router } from '@angular/router';
import { Observable ,  Subscription } from 'rxjs';
import { DineEngineCMSService } from 'app/providers/dine-engine-cms.service';
import { MenuService } from 'app/providers/menu.service';
import {ToastService} from '../../../providers/toast.service';

const BASE_URL = 'https://freebirds.dineengine.com';
declare let fbq: Function;

@Component({
  selector: 'app-rev-it',
  templateUrl: './rev-it.component.html',
  styleUrls: ['./rev-it.component.scss']
})
export class RevItComponent implements OnInit {
  upsellItems$: Subscription;
  upsellItems = [];
  selected = [{}, {}];
  pickedSize = false;
  globalImages: any = {};
  menu_images = {};
  makeLarge = false;
  unknown_img: string;
  selectedChoices = {};
  pType = 'none';
  mods = [];
  selection = {
    id: null,
    drinkSize: '',
    drinkChoice: '',
    secondChoice: '',
    dipSize: ''
  };
  drinksChoices = [];
  choices = [];
  optionsArr = [];
  cost = 0;

  constructor(
    private upsell: UpsellService,
    private basket: BasketService,
    private router: Router,
    private cms: DineEngineCMSService,
    private menuService: MenuService,
    private toast: ToastService
  ) {
    this.upsell.completedUpsell = true;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getGlobalImages();
    this.getMenuImages();
    let cache;
    // window['ChepriPixelTag'].push({
    //   event: 'order_reached_revit_page',
    // });
    // fbq('trackCustom', 'OrderReachedRevitPage');
    const cats = this.menuService.getCachedCategories();
    try {
    cats.some(cat => {
      if (cat.name === 'Rev\'d Up') {
        cache = cat.products;
      }
    });
    } catch (e) {
      // this.goToCheckout();
      this.router.navigate(['/order']);
      document.getElementById('basketButton').click();
    }
    if (!cache) {
    } else {
      this.upsellItems = cache;
    }
  }

  pickSize(pType, size, arrayPos) {
    this.selection.id = this.upsellItems[arrayPos].id;
    this.menuService
      .getProductModifiers(this.upsellItems[arrayPos].id)
      .subscribe(data => {
        this.mods = data;
        this.drinksChoices = this.mods[1].options;
        this.optionsArr.push(this.mods[0].options[0].id);
        this.cost += this.mods[0].options[0].cost;
        if (pType === 'dip') {
          this.pType = 'DIP';
          this.choices = this.mods[2].options;
        } else {
          this.pType = 'DESSERT';
          this.choices = this.mods[2].options;
        }
        this.selection.drinkSize = size;
        this.selection.secondChoice = pType;
        this.pickedSize = size;
      });
  }

  startOver() {
    setInterval(() => {
    }, 500);
    this.pickedSize = false;
    this.makeLarge = false;
    this.mods = [];
    this.optionsArr = [];
    this.cost = 0;
    this.pType = 'none';
    this.selected = [{}, {}];
    this.selection = { id: null, drinkSize: '', drinkChoice: '', secondChoice: '', dipSize: '' };
  }

  addRev() {
    let productID ,
    quantity,
    options,
    specialinstructions,
    recipient;
    productID = this.selection.id;
    quantity = 1;

    Object.keys(this.selectedChoices).forEach(item => {
      this.optionsArr.push(parseInt(Object.keys(this.selectedChoices[item])[0]));
    });
    options = this.optionsArr.join();
    specialinstructions = 'Revd Combo';
    recipient = '';
      this.basket.addProductToBasket(productID, quantity, options, specialinstructions, recipient).subscribe((res) => {

        // window['ChepriPixelTag'].push({ event: 'order_revit_add_success', result: JSON.stringify(res) });
        // fbq('trackCustom', 'OrderRevitAddSuccess', {
        //   result: JSON.stringify(res)
        // });
    this.router.navigate(['/order']);
    document.getElementById('basketButton').click();
      }, err => {
        // window['ChepriPixelTag'].push({
        //   event: 'order_revit_add_fail',
        //   error: JSON.stringify(err)
        // });
        // fbq('trackCustom', 'OrderRevitAddFail', {
        //   error: JSON.stringify(err)
        // });
        this.toast.danger(err.message);
      });
  }

  addToBasket(item) {
    this.basket.addUpsell(item.id).subscribe(basket => {
      document.getElementById('basketButton').click();
      basket.products.forEach(product => {
        if (product.name === item.name) {
          item.successId = product.id;
          return;
        }
      });
    });
  }

  removeFromBasket(item) {
    this.basket.removeProductFromBasket(item.successId).subscribe(res => {
      document.getElementById('basketButton').click();
    });
  }

  goToCheckout() {
    // window['ChepriPixelTag'].push({ event: 'order_revit_nothanks' });
    // fbq('trackCustom', 'OrderRevitNoThanks');
    this.router.navigate(['/order']);
    document.getElementById('basketButton').click();
  }

  productSlug(option) {
    if (option !== null) {
      option = option
        .toLowerCase()
        .trim()
        .replace(/[^0-9a-z \-]/g, '')
        .replace(/ +/g, '-');
      return option;
    } else {
      return (option = 'unknown');
    }
  }

  select(item, isDip?, row?) {
    if (this.selected[row][item.name]) {
      this.selectedChoices[row][item.id] = this.selected[row][item.name] ? null : item.id;
      this.selected[row][item.name] = this.selected[row][item.name]
        ? false
        : true;
      this.cost -= item.cost;
    } else {

      this.cost += item.cost;
      this.selected[row] = {};
      this.selectedChoices[row] = {};
      this.selected[row][item.name] = true;
      this.selectedChoices[row][item.id] = item.id;
    }
    if (row === 0) {
    this.selection.secondChoice = item.name;
    } else {
    this.selection.drinkChoice = item.name;
    }
  }

  // CMS GLOBAL IMAGES
  getGlobalImages() {
    this.cms.getTableSingleRow('global_images').subscribe(
      res => {
        this.globalImages = {
          drinks_img: BASE_URL + res.drinks_image.data.url,
          chips_n_dip_img: BASE_URL + res.chips_n_dip_image.data.url
        };
      }
    );
  }

  // CMS MENU IMAGES
  getMenuImages() {
    this.cms.getTable('ingredients').subscribe(
      res => {
        res.forEach(img => {
          const image = img.image ? BASE_URL + img.image.data.url : '';
          this.menu_images[img.name] = {
            img: image
          };
        });
      }
    );
  }
}
